import { sendNotification } from "../components/Notification";
import axiosClient from "../axiosClient";
import { useState, useEffect }  from "react";

let dataCache = {

};
let cachingHandlers = {

};

const useData = (defaultState, path, logError=true, transformData = null, cacheData = true) =>{
    const [data, setData] = useState(defaultState);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    
    useEffect(()=>{
        if(error&&logError){
            sendNotification("danger", "Something went wrong");
            if(process.env.NODE_ENV==="development"){
                console.log(error);
            }
        }
    },[error])

    const handleResponse = response =>{
        setError(null);
        let rdata;
        if(response.data.$values){
            rdata=response.data.$values;

        } else{
        rdata=response.data;

        }
        if(transformData){
            rdata=transformData(rdata);
        }
        setData(rdata);
    }

    const updateData = () =>{
        if(!path.match(/undefined/)){
            setLoading(true);
        axiosClient({
            method:"GET",
            url:path
        }).then(response=>{
            
            dataCache[path]=response;
            handleResponse(response);
            cachingHandlers[path].callback?.forEach(cb=>cb(response));

        }).catch(setError)
        .finally(()=>{
            setLoading(false);
        });
        }
    }
    useEffect(()=>{
        if(cacheData){
            if(dataCache[path]){
                handleResponse(dataCache[path]);
                setLoading(false)
            } else if(cachingHandlers[path]){
                cachingHandlers[path].callback.push((r)=>{
                    handleResponse(r);
                    setLoading(false);
                });
            }
             else{
                cachingHandlers[path]={
                    callback:[]
                };
                updateData();
            }
        } else{
            updateData();
        }
    }, [])

    useEffect(()=>{

    }, [dataCache])
    

    return [data, updateData, isLoading];
    
}

export default useData;