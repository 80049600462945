import { useEffect, useState } from "react";
import { useParams, useNavigate  } from "react-router-dom"
import axiosClient from "../axiosClient";
import {Tab, TabLayout, GridLayout, EditBoolean, EditCard, EditColumn, EditFooter, EditGroup, EditLayout, EditList, EditSelect, EditTextField, EditCurrency} from "../components/Edit";
import { EditDataLayout } from "./DataLayout";
import { sendNotification } from "../components/Notification";
import MessageBox from "../components/MessageBox";
import { CheckCircle } from "../components/Icons";
import useLoading from "../components/useLoading";
import ConfirmationButton from "../components/ConfirmationButton";
import Label from "../components/form/Label";
import { LabelInputPairRegular } from "../components/form/LabelInputPair";
import Text from "../components/form/Text";
import { useEncryptor } from "../scripts/passwordHash";

const UserEdit = () => {
    let { id } = useParams();

    return <UserForm id={id} active="users" />

}
const UserCreate = () => {
    return <UserForm active="users" />

}
const UserForm  = ({id, active})=>{
  
    const name= "User";
    const [user, setUser] = useState({});
    const [error, setError] = useState(null);
    const [encryptMessage] = useEncryptor();

    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
        }
    }, [error])
    useEffect(() => {
        if(id){
            axiosClient({
                method: "get",
                url: `/admin/users/${id}`
            })
                .then((response) => {
                    setError(null);
                    let data = response.data;
                    setUser(data);
                    
                })
                .catch(setError);
            
        }
        
            
        
    }, []);

   

    let navigate = useNavigate(); 
    

    const handleSubmit =async (event) =>{

        var value = event.value;

        if(!event.value){
            return;
        }

        value.password=await encryptMessage(value.password);
        
        axiosClient({
            method: id?"PUT":"POST",
            url: `/admin/users`,
            data: value
        })
            .then((response) => {
                setError(null);
                sendNotification("success", "Saved");
                switch(event.action){
                    case "another":
                        
                        
                        setUser({});
                        window.location.reload(false);
                        return navigate(`../users/create`)
                        
                    default:
                        return navigate(`../users`)
                }
                
            })
            .catch(setError);

    }

    

    const handleDelete = (callback) =>{
        if(!id){
            return;
        }

        axiosClient({
            method: "DELETE",
            url: `/admin/users/${id}`
        })
            .then((response) => {
                setError(null);
                callback();
                return navigate(`../users`)
                
                
            })
            .catch(setError);
    }

    return  <>
    <EditDataLayout onDelete={handleDelete} name={name} title={"User"} active={active} create={!id}>
        <EditLayout create={!id} onSubmit={handleSubmit} method={id?"PUT":"POST"}>
        {id&&<input type="hidden" data-type="int" name="id" id="data.id" value={id}/>}
    <EditColumn span="2">
        <EditGroup key="group-1" label="User">
            <EditCard columns="2">
                <EditTextField label="Name" name="name" value={user?.name}/>
                <EditTextField ignoreValue={id} secret  disabled={id} label="Password" name="password" value={id?"":user?.password}/>
                <EditTextField type="email" disabled={id} label="Email" name="email" value={user?.email}/>
                <EditSelect disabled={id} label="Claims" name="claims" value={user?.claims}>
                    <option value="admin">Admin</option>
                    <option value="employee">Employee</option>
                    <option value="phoner">Phoner</option>
                </EditSelect>
            </EditCard>
        </EditGroup>
     
        
        
       
       
        
    </EditColumn>
    <EditColumn>
    <EditGroup label="Info">
        {
        <span className="text-gray-600">
            Brugeren vil få adgang til <span className="text-black">Mindmill</span> på <a className="underline" href="https://console.mindmill.io/" target="_blank">mindmill.io</a>
        </span>
    }
        </EditGroup>
    </EditColumn>
</EditLayout>
    </EditDataLayout>
    </>

}


export  {UserEdit, UserCreate};