import { useEffect, useState } from "react";
import axiosClient from "../axiosClient";
import ActiveCampaign from "./ServiceTypes/ActiveCampaign";
import DefaultService, { DefaultServiceLayout } from "./ServiceTypes/DefaultService";
import Layout from "./Layout";
import Site from "./ServiceTypes/Site";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { EditCard, EditDate, EditFooter, EditGroup, EditSelect, EditTextField, GridLayout } from "../components/Edit";
import { EditDataLayout } from "./DataLayout";
import Dripify from "./ServiceTypes/Dripify";
import MessageBox from "../components/MessageBox";


const ServiceCreateNew = ({})=>{

    return <ServiceFormNew />
}
const ServiceEditNew = ({})=>{
    let { id } = useParams();

    return <ServiceFormNew id={id}/>
}

const ServiceFormNew = ({id})=>{
    const [service, setService] = useState({start_date:new Date().toISOString().split('T')[0]});
    const [error, setError] = useState(null);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [customFieldMeta, setCustomFieldMeta] = useState([]);
    const [typeName, setTypeName] = useState();
    const [selectedType, setSelectedType] = useState();
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();

    const [query] = useSearchParams();

    
    
    useEffect(()=>{
        axiosClient({
            method: "get",
            url: `/admin/customers`
        })
            .then((response) => {
                setError(null);
                
                setCustomers(response.data.sort((a, b)=>a.name>b.name?1:-1).map((as)=>{
                    return {
                        name: as.name,
                        id: as.id
                    }
                }));
                
            })
            .catch(setError);
        axiosClient({
            method: "get",
            url: `/admin/services/types?include=custom_fields`
        })
            .then((response) => {
                
                setServiceTypes(response.data);
            }).catch(setError);
        if(id){
            if(id){
                axiosClient({
                    method: "get",
                    url: `/admin/services/${id}`
                })
                    .then((response) => {
                        setError(null);
                        let data = response.data;
                        setService(data);
                        setSelectedCustomer(data?.customer_id)
                    })
                    .catch(setError);
                
            }
        }
    },[]);
   
    const handleDelete = (callback) =>{
        if(!id){
            return;
        }

        axiosClient({
            method: "DELETE",
            url: `/admin/services/${id}`
        })
            .then((response) => {
                setError(null);
                setService(response.data);
                callback();
                
            })
            .catch(setError);
    }

    const Service = ({children})=>{
        switch(service.service_type_id??selectedType?.id){
            case 1:
                return <ActiveCampaign state={[service,setService]} children={children}/>
                
            case 2:
                return <Site state={[service,setService]} children={children}/>

                case 8:
                return <Dripify state={[service,setService]} children={children}/>
                
            default:
                return <DefaultServiceLayout state={[service,setService]} typeName={typeName} children={children}/>
        }
    }
    const selectServiceType = (value) =>{
        var serviceType = serviceTypes.find(s=>s.id==value);
        setCustomFieldMeta(serviceType?.custom_fields?.$values??[]);
        setTypeName(serviceType.name);
        setSelectedType(serviceType);

        let newService=service??{};

        newService.price=serviceType.default_price;
        newService.currency=serviceType.default_currency;
        newService.interval=serviceType.default_interval;
        newService.expenditure=serviceType.default_expenditure;

        setService(newService);

        
    }
    useEffect(()=>{
        if(serviceTypes.length>0&&customers.length>0){
            let serviceTypeId = query.get("serviceTypeId");
        
        if(serviceTypeId){
            selectServiceType(serviceTypeId);
        }
        
        let customerId = query.get("customer");
        if(customerId){
            setSelectedCustomer(customerId);
        }
        }
    }, [query,serviceTypes,customers])
    return <EditDataLayout name={service.name} onDelete={handleDelete} create={!id} title="Services" active="services">
        <div className="space-y-6">
        
        <Service>
        <EditGroup label="Service">
            <EditCard>
            <EditSelect placeholder="Select customer" search required label="Customer" name="customer_id" value={selectedCustomer} onChange={setSelectedCustomer}>
            {
                customers&&customers.map((c, index)=><option key={index} value={c.id}>{c.name}</option>)
            }
        </EditSelect>
            {
            (id)?<EditTextField  value={typeName??serviceTypes.find(st=>st.id==service.service_type_id)?.name} label="Type" disabled/>:<EditSelect placeholder="Select service" search required value={selectedType?.id} onChange={selectServiceType} label="Type" name="service_type_id">
                {serviceTypes.map((st, index)=><option key={index} value={st.id}>{st.name}</option>)}
            </EditSelect>
        }
            </EditCard>
        </EditGroup>
        </Service>
        </div>
    </EditDataLayout>
}
const ServiceStatusEdit = ({serviceStatus, serviceStartDate, serviceEndDate, messageBoxDisplay, disabled})=>{
    const [status, setStatus] = useState();
    const [endDate, setEndDate] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [action, setAction] = useState();
    const customerName = document.getElementById("display.customer_id");

   

    return <EditGroup key="group-6" label="Status">
    <EditCard>
        {
            disabled?<EditTextField ignoreValue disabled value={serviceStatus} label="Status"/>:
            <EditSelect  name="status" value={serviceStatus} onChange={setStatus} key="select-status" label="Status" defaultValue="Aktiv">
            <option value="Aktiv">Aktiv</option>
            <option value="Pause">Pause</option>
            <option value="Ophørt">Ophørt</option>
        </EditSelect>
        }
        
        <GridLayout key="editdates" columns="1" columnsLg="2">
             <EditDate disabled={disabled} value={serviceStartDate} name="start_date" label="Start date" key="editstartdate"/>
             <EditDate disabled={disabled} value={serviceEndDate} name="end_date" label="End date" key="editlastdate" onChange={setEndDate}/>
         </GridLayout>
    </EditCard>
    <div className="w-full items-stretch flex flex-col space-y-3 mt-6">
     <input type="hidden" name="edit.action" value={action}/>
     <button type={status=="Ophørt"||endDate?"button":"submit"} onClick={status=="Ophørt"||endDate?()=>setShowConfirmation(true):()=>setAction("single")} className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700">Save</button>
     <Link className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset  h-9 px-4 text-gray-800 bg-transparent border-gray-300 hover:bg-gray-50 focus:ring-primary-600 focus:text-primary-600 focus:bg-primary-50 focus:border-primary-600" relative="path" to="../../">
         <span>Cancel</span>
     </Link>
    </div>
    {
     showConfirmation&&<MessageBox buttonType="submit" onSubmit={()=>setAction('single')} onHide={()=>setShowConfirmation(false)} title="End service" content={<>{messageBoxDisplay({customerName:customerName.value,endDate:endDate})}</>}>
     </MessageBox>
    }
</EditGroup>;
}
export {ServiceCreateNew,ServiceEditNew, ServiceStatusEdit};
