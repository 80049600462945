import { useEffect, useState } from "react";
import OutsideAlerter from "../OutsideAlerter";


const Select = ({children, placeholder, onChange, name, value, excludeDefault, defaultValue}) => {
    const [showOptions, setShowOptions] = useState(false);
    const [search, setSearch] = useState(null);
    const [className, setClassName] = useState("opacity-0 hidden");
    const [childrenContentMap, setChildrenContentMap] = useState({});
    const [inputValue, setInputValue] = useState();

    

  
    useEffect(()=>{
        if((!value||value=="__default__")&&excludeDefault){
            if(defaultValue){
                onChange(defaultValue);
            } else{
                let firstChild=children[0];
                let value = firstChild.props.value;

                selectValue(value);
            }
            
        }
    }, [value])
    useEffect(()=>{
        let map = {

        };
        if(!excludeDefault){
            map[false]=placeholder??"Select value";
        }
        children.forEach(element => {
            let value = element.props.value;
            let content = element.props.children??value;
            value??=content;
            map[value]=content;
        });
        setChildrenContentMap(map)
        setInputValue(map[value]);
    }, [children]);
    
    const selectValue = (value) =>{
        if(value=="__default__"){
            value=false;
        }
        onChange(value);
        setInputValue(childrenContentMap[value]);
    }
    const handleSearch = (e)=>{
        let value = e.target.value;
        setSearch(value.toLowerCase())
        setInputValue(value);
    }
    
    
    
    useEffect(()=>{
        if(showOptions){
            setClassName("opacity-0")
            setTimeout(()=>{
                setClassName("opacity-100")
            }, 1);
        } else{
            setClassName("opacity-0")
                setTimeout(()=>{
                    setClassName("opacity-0 hidden")
                }, 100);
                    
        }
        
    }, [showOptions])

    const DefaultOption = () =>{
<option value={"__default__"}>{placeholder??"Select Value"}</option>

    let value = "__default__";
    let handleClick = () =>{
    
    selectValue(value);
    setShowOptions(false);
    setSearch(null);
    }

    


return <li onClick={handleClick} className="relative py-2 pl-3 flex items-center cursor-default select-none pr-9 text-gray-900 hover:text-white hover:bg-primary-500  group">
    <span className="block truncate font-normal">
        {placeholder??"Select Value"}
    </span>
    
</li>
    }
   
    return <div>
    <div className="block w-full transition duration-75 divide-y rounded-lg shadow-sm border focus-within:border-primary-600 focus-within:ring-1 focus-within:ring-primary-600 border-gray-300">

        <div className="relative">
            <OutsideAlerter onOutsideClick={()=>setShowOptions(false)}>
            <div className="relative rounded-lg overflow-hidden">
            <input id={name} autoComplete="off" onChange={handleSearch} name={name} placeholder={placeholder} className="block w-full border-0" type="text"  onClick={e=>{e.target.select(); setShowOptions(!showOptions)}} value={inputValue} >
                
            </input>
            <span className="absolute inset-y-0 right-0 rtl:right-auto rtl:left-0 flex items-center pr-2 rtl:pr-0 rtl:pl-2 pointer-events-none">
                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="#6B7280" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6 8l4 4 4-4"></path>
                        </svg>
                </span>
            
           
            </div>
            <div className={`absolute z-10 w-full my-1 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none transition duration-100 ease-in ${className}`} style={{bottom:"auto"}}>
                <ul className="py-1 overflow-auto text-base leading-6 max-h-60 focus:outline-none">
                {!excludeDefault&&<DefaultOption/>}
                    {children.filter(c=>!search||c.props.children.toLowerCase().includes(search)).map((c, index)=>{
                        let value = c.props.value;
                        let content = c.props.children??c.props.value;
                        let handleClick = () =>{
                            
                            selectValue(value);
                            setShowOptions(false);
                            setSearch(null);
                        }
                        

                        return <li key={index} onClick={handleClick} className="relative py-2 pl-3 flex items-center cursor-default select-none pr-9 text-gray-900 hover:text-white hover:bg-primary-500  group">
                            <span className="block truncate font-normal">
                                {content}
                            </span>
                            
                        </li>
                    })}
                </ul>
            </div>
            </OutsideAlerter>
            
        </div>
        

</div>
    </div>


}
export default Select;