import React from "react"
import {Navigation, NavGroup, NavItem} from "./Navigation";
import Flask from "./svg/Flask";
import Home from "./svg/House";
import People from "./svg/People";
import Suitcase from "./svg/Suitcase";
import Clone from "./svg/Clone";
import Globe from "./svg/Globe";
import ViewList from "./svg/ViewList";
import Truck from "./svg/Truck";
import Chart from "./svg/Chart";

class Sidebar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen:this.props.isOpen,
            backgroundTransition: "open",
            ignoreUpdate: false
        }
    }

    backgroundClass = (state)=>{
      
        switch(state.backgroundTransition){
            case "open":
                return "opacity-100";
            case "closing":
                return "opacity-0";
            case "closed":
                return "translate-x-full opacity-0";
            case "opening":
                return "opacity-100" 
        }
    }
    close = () =>{
      
        if(this.state.backgroundTransition=="open"){
         
            this.setState({
                isOpen:this.props.isOpen,
                backgroundTransition: "closing"
            })
            setTimeout(()=>{
             
                this.setState({
                    backgroundTransition: "closed"
                })
            }, 150)
        }
    }
    open = () =>{
     
        if(this.state.backgroundTransition==="closed"){
        
            this.setState({
                isOpen:this.props.isOpen,
                backgroundTransition: "opening"
            })
            setTimeout(()=>{
           
                this.setState({
                    backgroundTransition: "open"
                })
            }, 150)
        }
    }
    
    componentDidUpdate(){
        if(this.state.isOpen===this.props.isOpen){
            return;
        }
        
        if(!this.props.isOpen){
            this.close();
        }else{
            this.open();
        }

      
    }



    render() {
        var className=this.props.isOpen?"translate-x-0":"-translate-x-full rtl:lg:-translate-x-0 rtl:translate-x-full";
        return <>
        <div className={`fixed inset-0 z-20 w-full h-full bg-gray-900/50 lg:hidden transition-opacity ${this.backgroundClass(this.state)}`} onClick={this.props.onSidebarToggle}/>
        <aside className={`fixed inset-y-0 left-0 rtl:left-auto rtl:right-0 z-20 flex flex-col h-screen overflow-hidden shadow-2xl transition-transform bg-white  lg:border-r w-80 lg:z-0 lg:translate-x-0 ${className}`}>
            <header className="border-b h-[4rem] shrink-0 px-6 flex items-center">
                <a href="dashboard">
                    <div className="flex items-center">
                        <div>
                            <img src="/img/mindmill-icon-positive.svg" alt="Logo" className="h-8" />
                        </div>
                        <div className="px-4">
                            {process.env.REACT_APP_TITLE}
                        </div>
                    </div>        
                    </a>
            </header>
            <Navigation>
                <NavGroup>
                    <NavItem id="dashboard" active={this.props.active==="dashboard"} icon={Home} href="dashboard" >
                        Dashboard
                    </NavItem>
                </NavGroup>
                <NavGroup label="Customers">
                    <NavItem id="customers" active={this.props.active==="customers"||this.props.active==="customeredit"} icon={Suitcase} href="customers">
                        Customers
                    </NavItem>
                    <NavItem id="sites" active={this.props.active==="sites"||this.props.active==="siteedit"} href="sites">
                        Sites
                    </NavItem>
                    <NavItem id="services" active={this.props.active==="services"||this.props.active==="serviceedit"} href="services">
                        Services
                    </NavItem>
                    <NavItem id="trips" active={this.props.active==="trips"||this.props.active==="tripedit"||this.props.active==="tripcreate"} href="trips" icon={Truck}>
                        Trips
                    </NavItem>
                    <NavItem id="invoices" active={this.props.active==="invoices"||this.props.active==="invoiceedit"||this.props.active==="invoicecreate"} href="invoices" >
                        Invoices
                    </NavItem>
                </NavGroup>
                <NavGroup label="Users">
                    <NavItem id="users" active={this.props.active==="users"} icon={People} href="users">
                        Users
                    </NavItem>
                </NavGroup>
                <NavGroup label="Dashboards">
                    
                    
                    <NavItem id="MA" absolute target="_blank" href="https://lookerstudio.google.com/u/0/reporting/b6b14a2c-2750-4b4f-8614-0942791815de" icon={Chart}>
                        Marketing Automation
                    </NavItem>
                    <NavItem id="Consultants" absolute target="_blank" href="https://lookerstudio.google.com/reporting/28f54d07-5b73-4fed-bce8-e5de479010f3" icon={Chart}>
                        Consultants
                    </NavItem>
                    <NavItem id="Production" absolute target="_blank" href="https://lookerstudio.google.com/u/0/reporting/cdfd6c72-88a7-4119-8c69-0b6fb05ab96e" icon={Chart}>
                        Production
                    </NavItem>
                    <NavItem id="Presales" absolute target="_blank" href="https://lookerstudio.google.com/u/0/reporting/31bc466e-764c-4d93-b656-2e8e1deb4a52" icon={Chart}>
                        Presales
                    </NavItem>
                    
                    <NavItem id="Paid" absolute target="_blank" href="https://lookerstudio.google.com/u/0/reporting/2ff0e314-8667-4a53-ae7f-2f54da43db84" icon={Chart}>
                        Paid
                    </NavItem>
                    
                </NavGroup>
                <NavGroup label="Settings">
                    <NavItem id="platform-connection" active={this.props.active==="platform-connection"} href="platform-connection">
                        Platform Connection
                    </NavItem>
                </NavGroup>
                <NavGroup label="Tools">
                    <NavItem id="naming-generator" active={this.props.active==="naming-generator"} icon={Flask} href="naming-generator">
                        Naming Generator
                    </NavItem>
                    <NavItem id="demographics" active={this.props.active==="demographics"} icon={Globe} href="demographics">
                        Demographics
                    </NavItem>
                    <NavItem id="wordpressclone" active={this.props.active==="wordpressclone"} icon={Clone} href="wordpressclone">
                        Wordpress Clone
                    </NavItem>
                    <NavItem id="ppg" active={this.props.active==="ppg"} href="privacy-policy-generator">
                        Privacy policy generator
                    </NavItem>
                    <NavItem id="acfields" active={this.props.active==="acfields"} icon={ViewList}  href="activecampaign-fields">
                        ActiveCampaign fields
                    </NavItem>
                    <NavItem id="acstagesdata" active={this.props.active==="acstagesdata"} icon={ViewList} href="activecampaign-stagesdata">
                        ActiveCampaign stages data
                    </NavItem>
                    <NavItem target="_blank" absolute id="addeventhtmlgenerator" href="https://toolbox.mindmill.io/email/add-event">
                        AddEvent HTML Generator
                    </NavItem>
                    <NavItem target="_blank" absolute id="documentlist" href="https://toolbox.mindmill.io/wp-network/documents">
                        List WP documents
                    </NavItem>
                </NavGroup>
                <NavGroup label="Other">
                    <NavItem target="_blank" absolute id="privacy-policy" href="https://www.mindmill.dk/cookie-og-privatlivspolitik/">
                        Privacy Policy
                    </NavItem>
                </NavGroup>
            </Navigation>
        </aside>
        </>
    }
}

export default Sidebar;