import { useEffect, useRef, useState } from "react";
import DefaultService from "./DefaultService";
import useLoading from "../../components/useLoading";
import axiosClient from "../../axiosClient";
import { sendNotification } from "../../components/Notification";
import { EditBoolean, EditCard, EditColumn, EditDate, EditFooter,  EditGroup, EditLayout, EditSelect, EditTextField, GridLayout } from "../../components/Edit";
import ConfirmationButton from "../../components/ConfirmationButton";
import { CheckCircle } from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import FadeIn from "../../components/FadeIn";
import LoadingAnimation from "../../components/svg/LoadingAnimation"
import { createPortal } from "react-dom";
import MessageBox from "../../components/MessageBox";
import { ServiceStatusEdit } from "../ServiceEditNew";
import Switch, { SwitchLabel } from "../../components/form/Switch";

const Site = ({children,state})=>{
    const [domain, setDomain] = useState();
    const [service, setService] = state;
    const [forgeState, setForgeState] = useState(0);
    const [status, setStatus] = useState({});
    const [isLoading, loadingState, setLoading] = useLoading(true); 
    const [error, setError] = useState(null);
    const [action, setAction] = useState("single");
    const [closeSite, setCloseSite] = useState(true);
    let navigate = useNavigate(); 
    const actions =  document.getElementById("mm-datalayout-actions");

    
    
    useEffect(()=>{
        if(service?.id){
            setLoading(0, true);
            axiosClient({
                url:`admin/sites/${service?.id}`
            }).then((response)=>{
                if(response.data){
                    setStatus(response.data);
                    setForgeState(1);
                }
                else{
                    setForgeState(0);
                }
                setLoading(0,false);
            })
            .catch((error)=>{
                setLoading(0,false);
                setForgeState(-1);
                setError(error);
            });


            
        } else{
            setLoading(0, false);

            setForgeState(-1)
        }
    }, [service]);

    useEffect(()=>{
        switch(forgeState){
            case 1:
                if(status?.forge){
                    setForgeState(2)
                }
                break;
                case 2:
                if(status?.ssl){
                    setForgeState(3)
                }
                break;
                case 3:
                if(status?.wordpress==="Inactive"||status?.wordpress==="Active"){
                    setForgeState(4)
                }
                break;
                
                
        }
    }, [forgeState,status])


    const create = async (event,id)=>{
        setForgeState(0);
        setLoading(0, true);
        await axiosClient({
            url:`admin/sites/${id??service?.id}/forge`,
            method:"post"
        }).then((response)=>{
            setStatus(response.data);
            setForgeState(2);
            sendNotification("success", "Server created");
            setLoading(0, false);
            if(id){
                return navigate(`../services/${id}/edit`)
            }

        })
        .catch((error)=>{
        setLoading(0, false);

            setForgeState(-1);
            setError(error);
        });
    }
    const addSSL = ()=>{
        setForgeState(0);
        setLoading(0, true);
        axiosClient({
            url:`admin/sites/${service?.id}/forge/ssl`,
            method:"post"
        }).then((response)=>{
            setStatus(response.data);
            setForgeState(3);
        setLoading(0, false);

            sendNotification("success", "SSL certificate installed");
        })
        .catch((error)=>{
            setForgeState(-1);
            setError(error);
        setLoading(0, false);

        });
    }
    const addWp = ()=>{
        setForgeState(0);
        setLoading(0, true);

        axiosClient({
            url:`admin/sites/${service?.id}/wordpress`,
            method:"post"
        }).then((response)=>{
            setStatus(response.data);
            setForgeState(4);
        setLoading(0, false);

            sendNotification("success", "Wordpress installed");
        })
        .catch((error)=>{
            setForgeState(-1);
            setError(error);
        setLoading(0, false);

        });
    }
    const cloneWp = ()=>{
        setForgeState(0);
        setLoading(0, true);

        axiosClient({
            url:`admin/sites/${service?.id}/clone`,
            method:"post"
        }).then((response)=>{
            setLoading(0, false);
            setStatus(response.data);
            setForgeState(5);
            sendNotification("success", "Template site cloned");

        })
        .catch((error)=>{
            setLoading(0, false);
            setForgeState(-1);
        setError(error);
        });
    }

 
    const handleDelete = (value) => {
        if(service?.id){
            setLoading(0, true);
        axiosClient({
            url:`admin/sites/${service?.id}/forge`,
            method:"delete"
        }).then((response)=>{
            setStatus(response.data);
            
            sendNotification("success", "Server deleted");
            setLoading(0, false);

            setForgeState(0);
        })
        .catch((error)=>{
            setForgeState(-1);
            setLoading(0, false);
            setError(error);
        });
        }
    }
    const handleClose = (value) => {
        if(service?.id){
            setLoading(0, true);
        axiosClient({
            url:`admin/sites/${service?.id}/wordpress`,
            method:"delete"
        }).then((response)=>{
            setStatus(response.data);
            sendNotification("success", "Site closed down");
            setLoading(0, false);
            setForgeState(1)
        })
        .catch((error)=>{
            setForgeState(-1);
            setLoading(0, false);
            setError(error);
        });
        }
    }
    const handleShutdown = (value) => {
        if(service?.id){
            setLoading(5, true);
            setForgeState(0);

        axiosClient({
            url:`admin/sites/${service?.id}/close`,
            method:"post"
        }).then((response)=>{
            setStatus(response.data);
            setLoading(5, false);
            setForgeState(1);
            sendNotification("success", "Site closed down");
            window.location.reload(false);
        })
        .catch((error)=>{
            setForgeState(-1);
            setLoading(5, false);
            setError(error);
        });
        }
    }

        const handleSubmit = async (event) =>{

            
        var value = {
            
        };

        if(!event.value){
            return;
        }
        Object.entries(event.value).forEach(([key, entry])=>{
            if(key.startsWith("customFields.")){
                value.customFields??={};

                value.customFields[key.substring(13)]=entry;

            }
            else if(key==="payment_interval"||key==="discount_type"){
                value[key]=parseInt(entry);
            }
            else{
                value[key]=entry;

            }
        });

        if(value.customFields)  {
            let cfs = {

            }
            Object.entries(value.customFields).forEach(([key,entry])=>{
                cfs[key]=entry?.toString();
            });
            value.customFields=cfs;
        }
        if(value.name){
            value.name=value.name.replace(/[, ]/g, "");
        }
        
        await axiosClient({
            method: service?.id?"PUT":"POST",
            url: `/admin/services${service?.id?'/'+service?.id:''}`,
            data: value
        })
            .then(async (response) => {
                setError(null);
                sendNotification("success", "Saved");
                switch(event.action){
                    case "another":
                        
                        
                        setService({});
                        window.location.reload(false);
                        return navigate(`../services/create`)
                    case undefined:
                    case "":
                    case "createserver":
                        setLoading(0, true);
                        setService(response.data);
                        await create({},response.data.id);

                    default:
                        setService(response.data);
                        return navigate(`../services/${response.data.id}/edit`)
                }
                
            })
            .catch(setError);

    }
    

    const buttonClass="disabled:cursor-not-allowed inline-flex items-center justify-between font-medium tracking-tight rounded-lg border transition focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700 w-full disabled:ring-0 disabled:text-gray-400 disabled:border-gray-300 disabled:bg-gray-100 flex-row-reverse"

    return <>
    <EditLayout create={!service?.id} onSubmit={handleSubmit} method={service?.id?"PUT":"POST"} footer={<></>}>

        <EditColumn span="2">
            {children}
            {
                <EditGroup label="Site">
                <GridLayout>
                    
                    {
                        <>
                        <EditCard>
                            {
                                service?.id?<EditTextField label="Domain" ignoreValue disabled value={service?.name}/>:
                                <EditTextField label="Domain" required name="name" onChange={setDomain} />
                            }
                            {
                              
                                       <div className="space-y-3">
                            <button disabled={isLoading||(forgeState!==1&&!domain)} onClick={service?.id?create:()=>setAction("createserver")} type={service?.id?"button":"submit"} value="createserver" className={buttonClass}>
                        <span className="flex-1">Create Server</span>
                        {
                            isLoading&&<div className="text-primary-600 w-6 h-6 absolute"><LoadingAnimation/></div>
                        }
                        {
                            forgeState>1&&<div className="text-primary-600 w-6 h-6 absolute"><CheckCircle/></div>
                        }
                        
        
        
                    </button>
                    <button disabled={isLoading||forgeState!==2} onClick={addSSL} type="button" className={buttonClass}>
                        
                        <span className="flex-1">Add SSL</span>
                        {
                            isLoading&&<div className="text-primary-600 w-6 h-6 absolute"><LoadingAnimation/></div>
                        }
                        {
                            forgeState>2&&<div className="text-primary-600 w-6 h-6 absolute"><CheckCircle/></div>
                        }
                        
                    </button>
                    <button disabled={isLoading||forgeState!==3} onClick={addWp} type="button" className={buttonClass}>
                        
                        <span className="flex-1">Install Wordpress</span>
                        {
                            isLoading&&<div className="text-primary-600 w-6 h-6 absolute"><LoadingAnimation/></div>
                        }
                        {
                            forgeState>3&&<div className="text-primary-600 w-6 h-6 absolute"><CheckCircle/></div>
                        }
                        
                    </button>
                   
                    
                    
                    <button disabled={isLoading||forgeState!==4} onClick={cloneWp} type="button" className={buttonClass}>
                        <span className="flex-1">Clone Sandbox</span>
                        {
                            isLoading&&<div className="text-primary-600 w-6 h-6 absolute"><LoadingAnimation/></div>
                        }
                        {
                            forgeState>4&&<div className="text-primary-600 w-6 h-6 absolute"><CheckCircle/></div>
                        }
                    </button>
                            </div>
                            }
                   
                    </EditCard>
                        <EditCard>
                
                <div className="inline-flex items-center space-x-3 rtl:space-x-reverse text-sm font-medium leading-4 text-gray-700">
                    <span className="font-bold">Server:</span>
                    <span>{status?.forge?"Running":"N/A"}</span>
                </div>
                <div className="inline-flex items-center space-x-3 rtl:space-x-reverse text-sm font-medium leading-4 text-gray-700">
                    <span className="font-bold">Wordpress:</span>
                    <span>{status?.wordpress?status.wordpress:"N/A"}</span>
                </div>
                
                
                
                
                {
                    (status?.wordpress||status?.forge)&&
                    
                    <div className="space-y-3">
                        {createPortal(<>
                            {
                        
                        status?.wordpress&&<ConfirmationButton className="flex focus:ring-offset-danger-700 focus:bg-danger-700 hover:bg-danger-500 focus:text-white hover:text-white transition items-center w-full h-8 px-4 text-sm font-medium" onClick={handleClose} title="Archive WordPress" content="Are you sure you would like to do this?">
                            Archive Site
                        </ConfirmationButton>
                    }
                    {
                        status?.forge&&<ConfirmationButton className="flex focus:ring-offset-danger-700 focus:bg-danger-700 hover:bg-danger-500 focus:text-white hover:text-white transition items-center w-full h-8 px-4 text-sm font-medium" onClick={handleDelete} title="Delete server" content="Are you sure you would like to do this?">
                        Delete Server
                    </ConfirmationButton>
                    }
                        </>,actions, "siteActions")}
                    
                    {
                        (status?.forge||status?.wordpress)&&<ConfirmationButton className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-danger-600 hover:bg-danger-500 focus:bg-danger-700 focus:ring-offset-danger-700 w-full" onClick={handleShutdown} title="Shut down site" content="Are you sure you would like to do this?">
                        Close site
                    </ConfirmationButton>
                    }
                    </div>
                }
                </EditCard>
                        </>
                    }
                
                </GridLayout>
            </EditGroup>
            }

            <DefaultService state={state} typeName="Hosting"/>
            
        </EditColumn>
        <EditColumn span="1">
           <ServiceStatusEdit serviceStatus={service?.status} serviceEndDate={service?.end_date} serviceStartDate={service?.start_date} messageBoxDisplay={({customerName,endDate})=><div>
                    
                    Please confirm that {customerName} <span className="font-bold">should not get an invoice</span> for hosting ({service?.name})  by the end of {new Date(Date.parse( endDate??new Date())).toLocaleString("en-UK", {year:"numeric",month:"long"})}.
                    <div className="mt-6 pt-3 text-left border-t">
                        {/* <EditBoolean label="Close site" value={false} name="customFields.shouldCloseSite"/> */}
                        <SwitchLabel name="closeSite" label="Close site" help={`Site will close on ${new Date(Date.parse( endDate??new Date())).toLocaleString("en-UK", {year:"numeric",month:"long", day:"numeric"})}`}>
                            <Switch value={closeSite} onChange={setCloseSite}/>
                        </SwitchLabel>
                        <input type="hidden" name="customFields.shouldCloseSite" id="data.customFields.shouldCloseSite" value={closeSite}/>

                    </div>
                    </div>} />

       </EditColumn>
    </EditLayout>
    

    </>;
}

export default Site;