import Layout from "./Layout"
import GetUser from "../auth";
import Cookies from "js-cookie";
import useAuth from "../useAuth";

const Home = () => {
  
  const [user, checkAuth] = useAuth();

  if(!user){
    return <></>
  }
  

    return <div className=" col-span-1 ">
    <div className="p-2 space-y-2 bg-white shadow rounded-xl">
    
    
    <div className="space-y-2">
                    <div className="px-4 py-2 space-y-4">
                <div className="h-12 flex items-center space-x-4 rtl:space-x-reverse">
            <div className="w-10 h-10 rounded-full bg-gray-200 bg-cover bg-center" style={{backgroundImage: `url('${user.profile_photo_url}')`}}></div>

            <div>
                <h2 className="text-lg sm:text-xl font-bold tracking-tight">
                    Welcome, {user.name}
                </h2>

                <p className="text-sm">
                    <a onClick={()=>{ Cookies.set('token', "", {expires: -1, sameSite: "None", secure:true,domain:"."+window.location.host.match(/[^.]+\.[^.]+$/)})}} href="/logout" className="text-gray-600 hover:text-primary-500 focus:outline-none focus:underline">
                        Sign out
                    </a>
                </p>
            </div>
        </div>
            </div>
            </div>

    
        </div>
    </div>;
  };
  
  export default Home;