import { useParams } from "react-router-dom"
import CustomerLayout from "../components/CustomerNavigation";
import Invoices from "./Invoices";

const CustomerBilling = ({})=>{
    const {id} = useParams();

    return <CustomerLayout active="Billing">
        <Invoices customer={id}/>
    </CustomerLayout>
}
export default CustomerBilling;