import { useState } from "react"
import Title from "../components/Title";
import AdName from "./NamingGenerator/AdName";
import CampaignGroup from "./NamingGenerator/CampaignGroup";
import CampaignName from "./NamingGenerator/CampaignName";
import Email from "./NamingGenerator/Email";
import Workflow from "./NamingGenerator/Workflow";
import SalesLIOutreach from "./NamingGenerator/SalesLIOutreach";
import ConversionTracking from "./NamingGenerator/ConversionTracking";
import Layout from "./Layout";

const GetNamingGenerator = ({type})=>{
    switch(type){
        case "campaign_group":
            return <CampaignGroup/>
            case "campaign_name":
            return <CampaignName/>
            case "ad_name":
                return <AdName/>
            case "email":
                return <Email/>
                case "workflow":
                    return <Workflow/>;
            case "saleslioutreach":
                return <SalesLIOutreach/>
            case "conversiontracking":
                return <ConversionTracking/>
    }
}

const NamingGenerator = ()=>{
    const [type, setType] = useState(sessionStorage.getItem("naming-generator-type")??"-- Select Type --");

    return  <>
    <div className="px-6 py-4 bg-white mb-6 rounded-xl shadow">
        <div className="lg:grid grid-cols-3 gap-4">
            <div>
                <div>
                    <label htmlFor="type" className="block text-sm font-bold text-gray-700 mb-2">Type</label>
                    <select value={type} onChange={e=>{setType(e.target.value); sessionStorage.setItem("naming-generator-type", e.target.value)}}  id="type" name="type" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md">
                        <option  value="">-- Select Type --</option>
                        <option value="campaign_group">Campaign Group</option>
                        <option value="campaign_name">Campaign Name</option>
                        <option value="ad_name">Ad Name</option>
                        <option value="email">Email</option>
                        <option value="workflow">Workflow</option>
                        <option value="saleslioutreach">Sales LI Outreach</option>
                        <option value="conversiontracking">Conversion Tracking</option>
                    </select>
                </div>
                <div className="py-3 px-1">
                    <p className="text-xs text-gray-500">Vælg den type du vil generere.</p>
                </div>
            </div>
        </div>
    </div>

    <GetNamingGenerator type={type}/>
    </>
}

export default NamingGenerator;