import { useEffect, useState } from "react";
import axiosClient from "../axiosClient";
import { EditNumber, EditSelect, EditTextField, EditColumn, EditGroup, EditCard, EditLayout, EditDate } from "../components/Edit";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { sendNotification } from "../components/Notification";
import { EditDataLayout } from "./DataLayout";


const InvoiceEdit = ({})=> {
    const { id } = useParams();

   
    return <InvoiceForm id={id}/>
}
const InvoiceCreate = ({})=> {
    const [searchParams,]= useSearchParams();
    const customer = searchParams.get("customer");

    return <InvoiceForm customer={customer} />
}

const InvoiceForm = ({id, customer})=>{
    const [invoice, setInvoice] = useState({});
    const [error, setError] = useState();
    const [customers, setCustomers] = useState([]);
    let navigate = useNavigate(); 

    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
        }
    }, [error])
    useEffect(()=>{
        if(customer){
            invoice??={};
            invoice.customer_id=customer;
            setInvoice(invoice);
        }
    }, [customer])
    useEffect(()=>{
        if(id){
            axiosClient({
                method: "GET",
                url:`/admin/invoices/${id}`,
            }).then(response=>{
                setError(null);

                setInvoice(response.data);
            }).catch(setError);
        }
        axiosClient({
            method: "get",
            url: `/admin/customers`
        })
            .then((response) => {
                setError(null);
                
                setCustomers(response.data.sort((a, b)=>a.name>b.name?1:-1).map((customer)=>{
                    return {
                        name: customer.name,
                        id: customer.id
                    }
                }));
                
            })
            .catch(setError);
        
    }, [id])
    const handleSubmit = (data)=>{
        if(id){
            data.value.id=parseInt(id);
        }
        axiosClient({
            method: id?"PUT":"POST",
            url: `/admin/invoices`,
            data: data.value
        })
            .then((response) => {
                setError(null);
                sendNotification("success", "Saved");
                switch(data.action){
                    case "another":
                        
                        
                        setInvoice({});
                        window.location.reload(false);
                        return navigate(`../invoices/create`)
                        
                    default:
                        return navigate(`../invoices/${response.data.id}/edit`)
                }
                
            })
            .catch(setError);

            return invoice.id;
        }
        const handleDelete=(callback)=>{
            if(!id){
                return;
            }
    
            axiosClient({
                method: "DELETE",
                url: `/admin/invoices/${id}`
            })
                .then((response) => {
                    setError(null);
                    setInvoice(response.data);
                    callback();
                    
                })
                .catch(setError);

        }
    
    return <EditDataLayout onDelete={handleDelete} name="Invoice" title="Invoices" active="invoices" create={!id}>
        <EditLayout create={!id} onSubmit={handleSubmit}>
        <EditColumn span="2">
            <EditGroup label="Trip">
                <EditCard columns="1">
                    {id&&<input type="hidden" id="data.id" name="id"/>}
                    <EditSelect value={invoice?.customer_id} label="Kunde" name="customer_id" search>
                        {
                            customers.map((c,index)=><option key={index} value={c.id}>{c.name}</option>)
                        }
                    </EditSelect>
                    
                    <EditNumber value={invoice?.price} name="price" label="Price"/>

                   
                    <EditDate value={invoice?.date} name="date" label="Date"/>
                    <EditTextField value={invoice?.description} name="description" label="Description" />
                    
                </EditCard>
            </EditGroup>
            </EditColumn>
    </EditLayout>
    </EditDataLayout>
}

export {InvoiceCreate, InvoiceEdit};