import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Redirect, useParams, Navigate, Switch, NavigationType } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Customers from "./pages/Customers";
import Users from "./pages/Users";
import Login from "./pages/Login";
import NoPage from "./pages/NoPage";
import { useJwt } from "react-jwt";
import Cookies from "js-cookie";
import './index.css';
import NamingGenerator from "./pages/NamingGenerator";
import DataLayout from "./pages/DataLayout";
import PlatformConnection, { PlatformConnectionCallback, PlatformConnectionLogin } from "./pages/PlatformConnection";
import CustomerEdit, { CustomerCreate } from "./pages/CustomerEdit";
import ManageCustomerUsers from "./pages/CustomerUsers";
import NotificationBar from "./components/Notification";
import GoogleCallback from "./pages/Auth";
import React, { useEffect, useState } from "react";
import axiosClient from "./axiosClient";
import Demographics from "./pages/Demographics";
import WordpressClone from "./pages/WordpressClone";
import Services from "./pages/Services";
import ServiceTypes from "./pages/ServiceTypes";
import Sites from "./pages/Sites";
import SiteEdit, { SiteCreate } from "./pages/SiteEdit";
import useAuth from "./useAuth";
import {CustomerUserEdit, CustomerUserCreate} from "./pages/CustomerUserEdit";
import PrivacyPolicyGenerator, { PrivacyPolicyView } from "./pages/PrivacyPolicyGenerator";
import ActiveCampaignFields from "./pages/ActiveCampaignFields";
import ActiveCampaignStagesData from "./pages/ActiveCampaignStagesData";
import { ServiceCreate, ServiceEdit } from "./pages/ServiceEdit";
import Trips from "./pages/Trips";
import { TripCreate, TripEdit } from "./pages/TripEdit";
import Invoices from "./pages/Invoices";
import { InvoiceCreate, InvoiceEdit } from "./pages/InvoiceEdit";
import {ServiceCreateNew,ServiceEditNew} from "./pages/ServiceEditNew";
import CustomerServices from "./pages/CustomerServices";
import CustomerBilling from "./pages/CustomerBilling";
import UserGenerator from "./pages/UserGenerator";
import { UserCreate, UserEdit } from "./pages/UserEdit";



const routes = [
  {
    level: 1,
    title: "Customers",
    path: "customers",
    id: "customers",
    element: () => <Customers />,
    layout: ({ title, children, active }) => <DataLayout breadcrumbs={[
      {
        label: "Customers",
        href: "#"
      },
      {
        label: "List",
        href: "#"
      }
    ]} actions={[
      {
        label: "New customer",
        href: "./create"
      }
    ]} title={title} active={active}>{children}</DataLayout>
  },
  {
    level: 1,
    title: "Services",
    path: "services",
    id: "services",
    element: () => <Services />,
    layout: ({ title, children, active }) => <DataLayout actions={[
      {
        label: "Create Service",
        href: "./create"
      }
    ]} title={title} active={active}>{children}</DataLayout>
  },
  {
    level: 1,
    title: "Sites",
    path: "sites",
    id: "sites",
    element: () => <Sites />,
    layout: ({ title, children, active }) => <DataLayout actions={[
      
      
      {
        label: "Create site",
        href: "../services/create?serviceTypeId=2"
      }
    ]} title={title} active={active}>{children}</DataLayout>
  },
  {
    level: 1,
    title: "Manage services",
    path: "services/manage",
    id: "servicetypes",
    element: () => <ServiceTypes />,
    layout: ({ title, children, active }) => <DataLayout title={title} active={active}>{children}</DataLayout>
  },
  {
    level: 1,
    title: "Users",
    path: "users",
    id: "users",
    element: () => <Users />,
    layout: ({ title, children, active }) => <DataLayout actions={[
      {
        label: "New user",
        href: "./create"
      }
    ]} title={title} active={active}>{children}</DataLayout>
  },
  {
    level: 1,
    title: "Trips",
    path: "trips",
    id: "trips",
    element: () => <Trips />,
    layout: ({ title, children, active }) => <DataLayout actions={[
      {
        label: "New trip",
        href: "./create"
      }
    ]} title={title} active={active}>{children}</DataLayout>
  },
  {
    level: 1,
    title: "Invoices",
    path: "invoices",
    id: "invoices",
    element: () => <Invoices />
  },
  {
    level: 1,
    title: "Naming Generator",
    path: "naming-generator",
    id: "naming-generator",
    element: () => <NamingGenerator />
  },
  {
    level: 1,
    title: "Demographics",
    path: "demographics",
    id: "demographics",
    element: () => <Demographics />
  },
  {
    level: 1,
    title: "Wordpress Clone",
    path: "wordpressclone",
    id: "wordpressclone",
    element: () => <WordpressClone />
  },
  {
    level: 0,
    title: "Platform Connection",
    path: "platform-connection",
    id: "platform-connection",
    element: () => <PlatformConnection />
  },
  {
  level: 1,
  title: "User generator",
  path: "usergenerator",
  id: "usergenerator",
  element: () => <UserGenerator />
},
  {
    level: 0,
    title: "Platform Connection Login",
    path: "platform-connection/:platform/signin",
    id: "pclogin",
    element: () => <PlatformConnectionLogin />,
    layout: ({ children }) => <>{children}</>

  },
  {
    level: 0,
    title: "Platform Connection Callback",
    path: "platform-connection/:platform/callback",
    id: "pccallback",
    element: () => <PlatformConnectionCallback />,
    layout: ({ children }) => <>{children}</>

  },
  {
    level: 1,
    path: "privacy",
    id: "privacy",
    element: () => <Navigate to="https://www.mindmill.dk/cookie-og-privatlivspolitik/" />
  },
  {
    level: 1,
    title: "Edit customer",
    path: "customers/:id/edit",
    id: "customeredit",
    element: () => <CustomerEdit />,
    layout: ({ children }) => <>{children}</>

  },
  {
    level: 1,
    title: "Edit customer",
    path: "customers/:id/users",
    id: "customeredit",
    element: () => <ManageCustomerUsers />,
    layout: ({ children }) => <>{children}</>

  },
  {
    level: 2,
    title: "Edit user",
    path: "users/:id/edit",
    id: "useredit",
    element: () => <UserEdit />,
    layout: ({ children }) => <>{children}</>

  },
  {
    level: 2,
    title: "Create user",
    path: "users/create",
    id: "useredit",
    element: () => <UserCreate />,
    layout: ({ children }) => <>{children}</>

  },
  {
    level: 1,
    title: "Edit customer",
    path: "customers/:id/services",
    id: "customeredit",
    element: () => <CustomerServices />,
    layout: ({ children }) => <>{children}</>

  },
  {
    level: 1,
    title: "Edit customer",
    path: "customers/:id/billing",
    id: "customeredit",
    element: () => <CustomerBilling />,
    layout: ({ children }) => <>{children}</>

  },
  {
    level: 1,
    title: "Edit trip",
    path: "trips/:id/edit",
    id: "tripedit",
    element: () => <TripEdit />,
    layout:({children})=><>{children}</>

  },
  {
    level: 1,
    title: "Edit invoice",
    path: "invoices/:id/edit",
    id: "invoiceedit",
    element: () => <InvoiceEdit />,
    layout:({children})=><>{children}</>

  },
  {
    level: 1,
    title: "Create user",
    path: "customers/:customer/users/:id/edit",
    id: "customeruseredit",
    element: () => <CustomerUserEdit />,
    layout: ({ children }) => <>{children}</>

  },
  {
    level: 1,
    title: "Create user",
    path: "customers/:customer/users/create",
    id: "customerusercreate",
    element: () => <CustomerUserCreate />,
    layout: ({ children }) => <>{children}</>

  },
  
  {
    level: 1,
    title: "Edit site",
    path: "sites/:id/edit",
    id: "siteedit",
    element: () => <SiteEdit />,
    layout: ({ children }) => <>{children}</>

  },
  {
    level: 1,
    title: "Create site",
    path: "sites/create",
    id: "sitecreate",
    element: () => <SiteCreate />,
    layout: ({ children }) => <>{children}</>

  },
  
  {
    level: 1,
    title: "Create customer",
    path: "customers/create",
    id: "customercreate",
    element: () => <CustomerCreate />,
    layout: ({ children }) => <>{children}</>
  },
  {
    level: 1,
    title: "Create trip",
    path: "trips/create",
    id: "tripcreate",
    element: () => <TripCreate />,
    layout:({children})=><>{children}</>
  },
  {
    level: 1,
    title: "Create invoice",
    path: "invoices/create",
    id: "invoicecreate",
    element: () => <InvoiceCreate />,
    layout:({children})=><>{children}</>
  },
  {
    level: 1,
    title: "Documents | Privacy Policy",
    path: "privacy-policy-generator",
    id: "ppg",
    element: () => <PrivacyPolicyGenerator />
  },
  {
    level: 1,
    title: "Privacy Policy",
    path: "privacy-policy-generator/view",
    id: "ppgview",
    element: () => <PrivacyPolicyView />,
    layout: ({ children }) => <>{children}</>
  },
  {
    level: 1,
    title: "ActiveCampaign Fields",
    path: "activecampaign-fields",
    id: "acfields",
    element: () => <ActiveCampaignFields />
  },
  {
    level: 1,
    title: "ActiveCampaign Stages data",
    path: "activecampaign-stagesdata",
    id: "acstagesdata",
    element: () => <ActiveCampaignStagesData/>
  },
  {
    level: 1,
    title: "Create service",
    path: "services/create",
    id: "servicecreate",
    element: () => <ServiceCreateNew />,
    layout: ({ children }) => <>{children}</>

  },
  
  {
    level: 1,
    title: "Edit service",
    path: "services/:id/edit",
    id: "serviceedit",
    element: () => <ServiceEditNew />,
    layout: ({ children }) => <>{children}</>

  }
]

export default function App() {
  const [userInfo, checkAuth] = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    if(!userInfo){
      checkAuth(r=>setLoading(false));
    } else{
      setLoading(false);
      
      
    }
  }, [userInfo])

  
    

  
  

  
  return (
    <BrowserRouter>

      <Routes>
        
            {
              !userInfo&&<Route path="/auth">
              
              <Route path="google-callback" element={<GoogleCallback/>}/>
            </Route>
            }
        {
          !userInfo  ?


            <>
            {
               !loading&&<><Route path="*" >
               <Route path="*" element={<Navigate to="/login" />} />
             </Route>
             <Route path="">
               <Route path="*" element={<Navigate to="/login" />} />
               <Route index={true} path="login" element={<Login />} />
               <Route path="" element={<Navigate to="/login" />} />
             </Route></>
            }
            
              
            </>
            :
            <>
              <Route path="/admin" >
                <Route path="*" index={true} element={<Layout active="dashboard" title="Dashboard"><Home /></Layout>} />
                {
                  routes.filter(r=>r.level<=userInfo.level).map((r, index) => {


                    
                    if (r.layout) {
                      return <Route key={index} path={r.path} element={<r.layout active={r.id} title={r.title}><r.element /></r.layout>} />
                    } else {
                      return <Route key={index} path={r.path} element={<Layout active={r.id} title={r.title}><r.element /></Layout>} />
                    }

                  })
                }

              </Route>

              <Route path="*" >
                <Route path="*" element={<Navigate to="/admin/dashboard" />} />
              </Route>
            </>
        }


      </Routes>
    </BrowserRouter>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<><App /><NotificationBar /><div id="mm-modal"></div></>);