import { useState} from "react"
import GetUser from "../auth";
import Cookies from "js-cookie";
import GlobalSearch from "./GlobalSearch";
import Popup from "./Popup";
import useAuth from "../useAuth";

const ProfilePopup = ({name, photo}) =>{
    const Icon = ({})=>{
        return <></>
    }
    return <Popup buttonClass={"block flex-shrink-0 w-10 h-10 rounded-full bg-gray-200 bg-cover bg-center"} buttonStyle={{backgroundImage: `url('${photo}')`}} className={"right-0 rtl:right-auto rtl:left-0 mt-2 shadow-xl rounded-xl w-52 top-full"} icon={<Icon/>}>
        <ul className="py-1 space-y-1 overflow-hidden bg-white shadow rounded-xl">
            <li className="flex items-center w-full h-8 px-3 text-sm font-medium">
                <svg className="mr-2 -ml-1 rtl:ml-2 rtl:-mr-1 w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd"></path>
                </svg>
                {name}
            </li>

            <div>
            </div>

            <li>
                <a onClick={()=>{Cookies.set('token', "", {expires: -1, sameSite: "None", secure:true,domain:"."+window.location.host.match(/[^.]+\.[^.]+$/)})}} className="flex items-center w-full h-8 px-3 text-sm font-medium focus:outline-none hover:text-white focus:text-white group whitespace-nowrap hover:bg-primary-600 focus:bg-primary-700" href="/logout">
                    <svg className="mr-2 -ml-1 w-6 h-6 flex-shrink-0 rtl:ml-2 rtl:-mr-1 rtl:scale-x-[-1] group-hover:text-white group-focus:text-white text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd"></path>
                    </svg>
                    <span className="truncate">
                        Sign out
                    </span>

                </a>
            </li>
        </ul>
    </Popup>
}


const Header = ({children, onSidebarToggle}) => {
    const [user, checkAuth] = useAuth();

        if(!user){
            return <>aaa</>
        }
        return <header className="h-[4rem] shrink-0 w-full border-b flex items-center">
            <div className="flex items-center w-full px-2 mx-auto sm:px-4 md:px-6 lg:px-8 max-w-7xl">
                <button onClick={onSidebarToggle} className="shrink-0 flex items-center justify-center w-10 h-10 text-primary-500 rounded-full hover:bg-gray-500/5 focus:bg-primary-500/10 focus:outline-none lg:hidden">
                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>                    </button>

                <div className="flex-1 flex gap-4 items-center justify-between">
                    <div className="flex-1 ">
                        {children}
                    </div>

                    <GlobalSearch/>


                    <ProfilePopup name={user.name} photo={user.profile_photo_url}/>
                </div>
            </div>
        </header>;
}

export default Header;