import NamingGeneratorBase, { NamingGeneratorSelect, NamingGeneratorTextField } from "./NamingGeneratorBase"

const CampaignName = ()=>{
    return <NamingGeneratorBase title="Campaign Name">
        <NamingGeneratorSelect name="category" label="Category" options={[
        'Article',
        'Blog',
        'Branding',
        'Brochure',
        'Calculator',
        'Case',
        'Catalogue',
        'Checklist',
        'Competition',
        'Demo',
        'E-book',
        'Event',
        'Exhibition',
        'Explainer',
        'Guide',
        'Handbook',
        'Report',
        'Meeting',
        'Newsletter',
        'One pager',
        'Quiz',
        'Report',
        'Sample',
        'Simulation',
        'Webcast',
        'Webinar',
        'Website',
        'White paper',
        'Workshop'
    ]}/>
        <NamingGeneratorTextField name="title" label="Short title" placeholder="Better Finance 2020"/>
        <NamingGeneratorSelect optional name="objective" label="Objective" options={[
            'Engagement',
            'Awareness',
            'Leads',
            'Traffic',
            'Video views',
            'Website conversion',
        ]}/>
        <NamingGeneratorSelect name="format" label="Format" options={[
        'Graphic',
        'Video',
        'Carousel',
        'Event',
        'Conversation',
        'Document',
        'Dynamic',
        'Search',
        'Display'
        ]}/>
        <NamingGeneratorTextField optional name="target" label="Target Group" placeholder="Salg og Marketing"/>
        <NamingGeneratorTextField name="segmentation" label="Segmentation" placeholder="Industry + Job titles"/>
    </NamingGeneratorBase>
}

export default CampaignName;