import { useEffect } from "react";
import { useState } from "react";

const SelectNumberPair = ({value, onChange, name, disabled, children, defaultSelected, placeholder}) => {
    const [numberValue, setNumberValue] = useState(undefined);
    const [selected, setSelected] = useState();

    useEffect(()=>{
        setSelected(defaultSelected);
    },[defaultSelected])

    useEffect(()=>{
        
        if(value){
            setSelected(value.selected);
            setNumberValue(value.amount);
        }
        
    }, [value]);
    

    const updateValue = (numberV, sel) => {
        if(onChange){
            let floatValue ;

            if(!numberV||numberV== ""){
                floatValue=null;
            } else{
                floatValue=parseFloat(numberV);
            }


           
            onChange({
                amount:floatValue,
                selected:sel??defaultSelected
            });
        }
    }

    const handleChange = (e)=>{
        updateValue(e.target.value, selected);
        setNumberValue(e.target.value);   
    }
    const changeSelected = (event) =>{
        setSelected(event.target.value);
        updateValue(numberValue, event.target.value);
    }
   const ignoreStep = (e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false });

    return <div className="block relative group w-full transition duration-75 divide-y rounded-lg shadow-sm border overflow-hidden focus-within:border-primary-600 focus-within:ring-1 focus-within:ring-primary-600 border-gray-300">
        <input placeholder={placeholder} data-type="number" onFocus={ignoreStep} disabled={disabled} name={name} className="block w-full border-0" value={numberValue} onChange={handleChange} type="number"/>
        <select disabled={disabled} onChange={changeSelected} value={selected} className="absolute shadow-none inset-y-0 right-0 block border-none justify-center w-fit h-full ring-0 focus:ring-0 text-gray-500 group-focus-within:text-primary-500 text-right">
            {children}
        </select>
    </div>
}
export default SelectNumberPair;