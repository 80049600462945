import NamingGeneratorBase, { NamingGeneratorSelect, NamingGeneratorTextField } from "./NamingGeneratorBase"

function getResult(values){
    return `${values.mailtype??""} - ${values.contenttype??""}: ${values.title??""} (${values.template??""}) | ${values.segment??""} | ${values.language??""} [${values.segmentation??""}]`
}

function validate(values, field, optional = false){
    if(values[field]){
        return <span className="text-success-600" >{`${values[field]}`}</span>;
    } else {
        return <span className={optional?"text-warning-600":"text-danger-600"} >{`{${field}}`}</span>;
    }
}
function getValidation(values){
    return <>{validate(values, "mailtype")} - {validate(values, "contenttype")}: {validate(values, "title")} ({validate(values, "template")}) | {validate(values, "segment")} | {validate(values, "language")} [{validate(values, "segmentation")}]</>

}

const Workflow = ()=>{
    return <NamingGeneratorBase getResult={getResult} getValidation={getValidation} title="Email">
        <NamingGeneratorTextField name="mailtype" label="Mailtype" />
        <NamingGeneratorTextField name="contenttype" label="Indholdstype" />
        <NamingGeneratorTextField name="title" label="Titel" />
        <NamingGeneratorTextField name="template" label="Template" />
        <NamingGeneratorTextField name="segment" label="Segment" />
        <NamingGeneratorTextField name="language" label="Sprog" placeholder="DA" />
        <NamingGeneratorTextField name="segmentation" label="Segmentering" />

    </NamingGeneratorBase>
}

export default Workflow;