import { useEffect, useState } from "react";
import MultiSelect from "./form/MultiSelect";
import Select from "./form/Select";
import axiosClient from "../axiosClient";

import Filter from "./Filter";



const StatusFilter = ({onChange, value})=>{
    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        <option value="Aktiv">Aktiv</option>
        <option value="Pause">Pause</option>
        <option value="Inaktiv">Inaktiv</option>
    </MultiSelect>
}
const AdSpecialistFilter = ({onChange, value})=>{
     const [adSpecialists, setAdSpecialists] = useState([]);
    useEffect(() => {

            axiosClient({
                method: "get",
                url: `/admin/customers/ad_specialists`
            })
                .then((response) => {
                    
                    setAdSpecialists(response.data.$values.sort((a, b)=>a>b?1:-1));
                    
                })
                .catch(er=>console.error(er));
        
    }, []);
    
    return <Select value={value} onChange={onChange} placeholder="All">
                                             {adSpecialists.map((as, index)=>{
                                                 return <option key={index} value={as}>{as}</option>
                                             })}
                                         </Select>
}
const CustomerFilter = ({onChange}) => {


    return <Filter onChange={onChange} filters={[
        {
            name: "client_status",
            element: <StatusFilter/>,
            label: "Client status",
            defaultValue: []
        },
        {
            name: "podio_ad_specialist",
            element: <AdSpecialistFilter/>,
            label: "Ad Specialist",
            defaultValue: false
        }
    ]}/>
}

export default CustomerFilter;