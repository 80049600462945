import {DataTable} from '../components/DataTable'
import PlatformStatus from '../components/PlatformStatus'
import CustomerFilter from '../components/CustomerFilter'
import Monitor  from '../components/svg/Monitor'
import Report from '../components/svg/Report'


const Status = (props) => {

    
    const colors = {
        'Aktiv': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-success-700 bg-success-500/10`}>Aktiv</span>,
        'Opstart': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-gray-700 bg-gray-500/10`}>Opstart</span>,
        'Pause': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-primary-700 bg-primary-500/10`}>Pause</span>,
        'Inaktiv': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-danger-700 bg-danger-500/10`}>Inaktiv</span>

    }

    let color = colors[props.status];

    if(!color){
        color=<></>
    }
        
    
    return color;
}

const Customers = () => {
    return   <DataTable filter={<CustomerFilter />} searchable="name" path="customers">
    <td row={(name)=><>{name}</>} field="name" header="Name" sortable/>
    <td row={(client_id)=><>{client_id}</>} field="client_id" header="Client id" />
    <td row={(client_status)=><Status status={client_status}/>} field="client_status" header="Client status" sortable/>
    <td row={(podio_ad_specialist)=><>{podio_ad_specialist}</>} field="podio_ad_specialist" header="Ad specialist"/>
    
    <td row={(ac_api_url)=><div><PlatformStatus platform="AC" active={ac_api_url}/></div>} field="ac_api_url" header="AC"/>
    <td row={(channel_fb)=><div><PlatformStatus platform="FB" active={channel_fb}/></div>} field="channel_fb" header="FB"/>
    <td row={(channel_li)=><div><PlatformStatus platform="LI" active={channel_li}/></div>} field="channel_li" header="LI"/>
    <td row={(channel_yt)=><div><PlatformStatus platform="YT" active={channel_yt}/></div>} field="channel_yt" header="YT"/>
    <td row={(channel_gd)=><div><PlatformStatus platform="GD" active={channel_gd}/></div>} field="channel_gd" header="GD"/>
    <td row={(report_url)=>{
        if(report_url){
            return <Report className="w-6 h-6 text-gray-700" />
        }else{
            return <></>
        }
    }} field="report_url" header="Report" url={(report_url)=>report_url}/>
    <td row={(dashboard_url)=>{
        if(dashboard_url){
            return <Monitor className="w-6 h-6 text-gray-700"/>
        }else{
            return <></>
        }
    }} field="dashboard_url" header="Dashboard" url={(dashboard_url)=>dashboard_url}/>
</DataTable>
    
    
};


export default Customers;