import { useEffect, useState } from "react";
import OutsideAlerter from "../OutsideAlerter";


const MultiSelect = ({children, onChange, placeholder, name, value, required}) =>{
    const [className, setClassName] = useState("opacity-0 hidden")
    const [values, setValues] = useState({});
    const [showOptions, setShowOptions] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [search, setSearch] = useState();
    // const [search, setSearch] = useState("");

    let optionContent = {};
    
    children.forEach((c)=>{
        optionContent[c.props.value]=c.props.children??c.props.value;
    })

    useEffect(()=>{
        var state = {};
        children.forEach((element, index) => {
            state[element.value]=false;
        });
        if(value&&Array.isArray(value)){
            value.forEach((v)=>{
                state[v]=true;
            })
        }

        setValues(state)
        setSelectedValues(value??[])
        
    }, [value])

    useEffect(()=>{
        if(showOptions){
            setClassName("opacity-0")
            setTimeout(()=>{
                setClassName("opacity-100")
            }, 1);
        } else{
            setClassName("opacity-0")
                setTimeout(()=>{
                    setClassName("opacity-0 hidden")
                }, 100);
                    
        }
        
    }, [showOptions])

    useEffect(()=>{
        
    }, [selectedValues])
    

    const selectValue = (value)=>{

        var state = values;

        state[value]=!state[value];

        setValues(state);

        var selected=Object.entries(state).filter(v=>v[1]).map(v=>v[0])
        setSelectedValues(selected)

        if(onChange){
            onChange(selected);
        }
    }

    const removeValue = (value)=>{
        var state = values;

        state[value]=false;
        setValues(state);

        var selected=Object.entries(state).filter(v=>v[1]).map(v=>v[0])

        
        setSelectedValues(selected)

        if(onChange){
            onChange(selected);
        }
    }
    
    

    
    

    return <div>
    <div className="block w-full transition duration-75 divide-y rounded-lg shadow-sm border focus-within:border-primary-600 focus-within:ring-1 focus-within:ring-primary-600 border-gray-300">

        <div className="relative">
            <OutsideAlerter onOutsideClick={()=>setShowOptions(false)}>
            <div className="relative rounded-lg overflow-hidden">
            <input id={name} autoComplete="off" onChange={e=>setSearch(e.target.value.toLowerCase())} name={name} placeholder={placeholder} className="block w-full border-0" type="text"  onClick={e=>setShowOptions(!showOptions)} >
                
            </input>
            <span className="absolute inset-y-0 right-0 rtl:right-auto rtl:left-0 flex items-center pr-2 rtl:pr-0 rtl:pl-2 pointer-events-none">
                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="#6B7280" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6 8l4 4 4-4"></path>
                        </svg>
                </span>
            
           
            </div>
            <div className={`absolute z-10 w-full my-1 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none transition duration-100 ease-in ${className}`} style={{bottom:"auto"}}>
                <ul className="py-1 overflow-auto text-base leading-6 max-h-60 focus:outline-none">
                    {children.filter(c=>!search||c.props.children.toLowerCase().includes(search)).map((c, index)=>{
                        let value = c.props.value
                        let handleClick = () =>{
                            
                            selectValue(value);
                            setShowOptions(false)
                        }
                        

                        return <li key={index} onClick={handleClick} className="relative py-2 pl-3 flex items-center cursor-default select-none pr-9 text-gray-900 hover:text-white hover:bg-primary-500  group">
                            <span className="block truncate font-normal">
                                {c.props.children??c.props.value}
                            </span>
                            <span className={values[value]?"absolute inset-y-0 right-0 flex items-center pr-4 group-hover:text-white text-primary-500":"hidden"}>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                </svg>
                            </span>
                        </li>
                    })}
                </ul>
            </div>
            </OutsideAlerter>
            
        </div>
        <div className={selectedValues.length==0?"hidden":"overflow-hidden rtl:space-x-reverse relative w-full p-2"}>
            <div className="flex flex-wrap gap-1">
            {selectedValues.map((v, index)=>{
                return <button key={index} onClick={()=>removeValue(v)} type="button" className="inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight text-primary-700 rounded-xl bg-primary-500/10 space-x-1 rtl:space-x-reverse">
                <span className="text-left">{optionContent[v]}</span>

                                                <svg className="w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" >
<path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
</svg>                                                    </button>
            })}
            </div>
            
        </div>

</div>
    </div>
}

export default MultiSelect;