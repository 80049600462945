import { useEffect, useRef, useState } from "react";
import axiosClient from "../../axiosClient";
import X from "../svg/X";
import { sendNotification } from "../Notification";
import bytesToString from "../../scripts/bytesToString";
const Files = ({single, namee, id, value, onChange, folder})=>{
    const inputFile = useRef(null);
    const [fileIds, setFileIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [files, setFiles] = useState([]);

    useEffect(()=>{
        if(error){
            console.log(error);
            sendNotification("danger", "Something went wrong");
        }
        setLoading(false);
    }, [error]);

    useEffect(()=>{
        if(value){
            setFileIds(value.split(','));
        }
    },[value]);

    useEffect(()=>{
        axiosClient({
            url:"files?fileIds="+fileIds.join(','),
            method:"GET"
        }).then(response=>{

            setError(null);
            setFiles(response.data);
        }).catch(setError)
    },[fileIds]);




    const uploadFiles = (files)=>{
        const formData = new FormData();
        let index = 0;
        while(true){
            let file = files[index];
            if(!file){
                break;
            }
            formData.append(`file${index}`,file,file.name);
            index++;

        }


        if(index>0){
            setLoading(true);
            axiosClient({
                url:"files?folder="+folder,
                method:"POST",
                data:formData
            }).then(response=>{
    
                setError(null);
                let newFiles = fileIds.concat(response.data);
                setLoading(false);

                setFileIds(newFiles);
                if(onChange){

                    onChange(newFiles.join(','))
                }
            }).catch(setError)
        }
    }
    const openFileSelect = (e)=>{
        inputFile?.current.click();
    }
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };
      
      const handleDrop = (e) => {

        const {files} = e.dataTransfer;

        uploadFiles(files);

        e.preventDefault();
        e.stopPropagation();
      };
      const handleFileSelect = (e)=>{
        const files = e.target.files;

        uploadFiles(files);
      }
      const removeFile=(id)=>{
        let newFiles = fileIds.filter(f=>f!==id);

        setFileIds(newFiles);
 
      };
    
    return <div className="flex flex-col border-gray-300 border rounded-lg shadow-sm">
        <input type="file" className="hidden" ref={inputFile}  onChange={handleFileSelect} />
        <input type="hidden" name={namee} id={id} value={fileIds.join(',')} />
        <div onClick={openFileSelect} onDrop={handleDrop} onDragOver={handleDragOver}  className="flex items-center justify-center w-full p-6 transition duration-75  focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600 disabled:opacity-70  ">
            <div className="text-gray-600 font-normal text-sm">Drag & Drop your files or <span className="transition-colors cursor-pointer hover:text-primary-400 text-primary-600 font-medium z-10" >Browse</span></div>
        </div>
        {
            loading&&<div className="w-full bg-blue-500 text-white px-6 py-4 font-normal text-sm tracking-wider rounded-lg mt-1 flex justify-between items-center overflow-hidden">
                    <span className="animate-pulse overflow-hidden whitespace-nowrap overflow-ellipsis">Uploading</span>
                    <span><svg className="inline-block animate-spin w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"></path>
                                    </svg></span>
            </div>
        }
        {
            files?.map((file, index)=>{
                return <a key={index} target="_blank" href={`https://drive.google.com/file/d/${file.id}/view`} className="group w-full bg-gray-500 hover:bg-gray-600 transition-colors text-gray-200 px-6 py-4 font-normal text-sm tracking-wider rounded-lg mt-1 flex justify-between items-center overflow-hidden">
                    <div className="flex flex-col">
                        <span className="group-hover:text-white transition-colors overflow-hidden whitespace-nowrap overflow-ellipsis">{file.name}</span>
                        <span className=" text-xs text-gray-300 transition-colors overflow-hidden whitespace-nowrap overflow-ellipsis">{bytesToString(file.size)}</span>
                    </div>
                    
                    <a href="#" className="hover:text-white transition-colors" type="button" onClick={()=>removeFile(file.id)}><X className="w-5 h-5"/></a>
                </a>
            })
        }
        
    </div>
}
export default Files;