import { useEffect,useState } from "react"

const MessageBox = ({ title, content, onSubmit, onHide, children, buttonType }) => {

    const [outerClassName, setOuterClassName] = useState("-translate-y-1 opacity-0");
    const [className, setClassName] = useState("-translate-y-1 opacity-0");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTimeout(() => {

            setOuterClassName("opacity-100")
            setClassName("-translate-y-0")
        }, 1)

    }, [])

    const close = (option) => {
        setOuterClassName("opacity-0")
        setClassName("-translate-y-8")

        setIsLoading(true);
        onSubmit(option);
        setIsLoading(false);
        setTimeout(() => {
            setOuterClassName(" opacity-0 hidden")
            onHide();
        }, 300)
    }






    return <div className={`fixed inset-0 z-40 flex items-center min-h-screen p-4 overflow-y-auto transition ease-in-out duration-300 ${outerClassName}`}>
        <button type="button" onClick={e => close("")} className="fixed inset-0 w-full h-full bg-black/50 focus:outline-none" />
        <div className={`relative w-full mt-auto md:mb-auto cursor-pointer ease-in-out duration-300 ${className}`}>
            <div className="w-full mx-auto p-2 space-y-3 bg-white rounded-xl cursor-default  max-w-sm">


                <div className="space-y-2 ">
                    <div className="p-4 pb-1 space-y-2 text-center">
                        <h2 className="text-xl font-bold tracking-tight " >
                            {title}
                        </h2>

                        <h3 className="text-gray-500 ">
                            {content}
                        </h3>
                    </div>



                </div>

                

                <div className="px-4 pt-1 pb-2 ">
                    <div className=" grid gap-2 grid-cols-[repeat(auto-fit,minmax(0,1fr))]">
                        <button onClick={(e) => close("cancel")} type="button" className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset  h-9 px-4 text-gray-800 bg-white border-gray-300 hover:bg-gray-50 focus:ring-primary-600 focus:text-primary-600 focus:bg-primary-50 focus:border-primary-600 ">

                            <span>Cancel</span>

                        </button>

                        <button type={buttonType??"button"} onClick={(e) => close("confirm")} className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset  h-9 px-4 text-white shadow focus:ring-white border-transparent bg-danger-600 hover:bg-danger-500 focus:bg-danger-700 focus:ring-offset-danger-700 ">
                            {isLoading && <svg className=" w-6 h-6 mr-1 -ml-2 rtl:ml-1 rtl:-mr-2 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"></path>
                            </svg>}

                            <span>Confirm</span>

                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

export default MessageBox;