import { useEffect, useState } from "react";
import { useParams, useNavigate  } from "react-router-dom"
import axiosClient from "../axiosClient";
import {Tab, TabLayout, GridLayout, EditBoolean, EditCard, EditColumn, EditFooter, EditGroup, EditLayout, EditList, EditSelect, EditTextField} from "../components/Edit";
import { EditDataLayout } from "./DataLayout";
import { sendNotification } from "../components/Notification";
import MessageBox from "../components/MessageBox";
import { CheckCircle } from "../components/Icons";
import useLoading from "../components/useLoading";
import ConfirmationButton from "../components/ConfirmationButton";
import ServiceForm from "./ServiceEdit";
import LabelInputPair from "../components/form/LabelInputPair";
import Switch from "../components/form/Switch";
import Label from "../components/form/Label";

const SiteCreate = ({}) =>{

    return <ServiceForm active="sites" type="Site" typeName="Hosting" name="Create site">
        <EditGroup label="Site">
    <GridLayout>
        <EditCard>
            <EditTextField label="Domain" required name="name"/>
            <input type="hidden" id="data.service_type_id" name="service_type_id" value="2"/>
        </EditCard> 
    </GridLayout>
</EditGroup>
        </ServiceForm>;
}
const SiteEdit = ({}) =>{
    let { id } = useParams();

    const [error, setError] = useState(null);
    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong. Press f12 for more info");
            console.log(error);
        }
    }, [error])

    return <ForgeEdit id={id} setError={setError}/>;
}

const SiteForm  = ({id})=>{
  
    const [site, setSite] = useState({});
    const [customers, setCustomers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
        }
    }, [error])
    useEffect(() => {
        if(id){
            axiosClient({
                method: "get",
                url: `/admin/services/${id}`
            })
                .then((response) => {
                    setError(null);
                    let data = response.data;
                    setSite(data);
                    
                })
                .catch(setError);
            
        }
        
            axiosClient({
                method: "get",
                url: `/admin/customers`
            })
                .then((response) => {
                    setError(null);
                    
                    setCustomers(response.data.sort((a, b)=>a.name>b.name?1:-1).map((as)=>{
                        return {
                            name: as.name,
                            id: as.id
                        }
                    }));
                    
                })
                .catch(setError);
        
    }, []);

   

    let navigate = useNavigate(); 
    

    const handleSubmit = (event) =>{
        if(event?.value?.domain){
            event.value.domain=event.value.domain.trim();
        }
        axiosClient({
            method: site.site.id?"PUT":"POST",
            url: `/admin/sites`,
            data: event.value
        })
            .then((response) => {
                setError(null);
                sendNotification("success", "Saved");
                switch(event.action){
                    case "another":
                        
                        
                        setSite({site:{}, service:{}});
                        window.location.reload(false);
                        return navigate(`../sites/create`)
                        
                    default:
                        return navigate(`../sites/${response.data.site.id}/edit`)
                }
                
            })
            .catch(setError);

            return site.site.id;
    }

    

    const handleDelete = (callback) =>{
        // if(!id){
        //     return;
        // }

        // axiosClient({
        //     method: "DELETE",
        //     url: `/admin/customers/${id}`
        // })
        //     .then((response) => {
        //         setError(null);
        //         setCustomer(response.data);
        //         callback();
                
        //     })
        //     .catch(setError);
    }

    return  <EditDataLayout onDelete={handleDelete} name={site.name} title="Sites" active="sites" create={!id}>
        <EditLayout create={!id} onSubmit={handleSubmit} method={id?"PUT":"POST"}>
        {id&&<input type="hidden" name="id" id="data.id" value={id}/>}
    <EditColumn span="2">
        <EditGroup key="group-1" label="General">
            <EditCard columns="2">
                
                <EditTextField key="text-domain" value={site.name} required label="Domain" name="domain" placeholder="campains.example.com" />
                <EditSelect  label="Customer" name="client_id" value={site.customer_id}>
                    {
                        customers&&customers.map((c, index)=><option key={index} value={c.id}>{c.name}</option>)
                    }
                </EditSelect>
                {/* <EditTextField key="text-id" value={site.site.id} disabled label="Id" name="id" ignoreValue /> */}
                {/* <EditTextField key="text-php_version" value={site.php_version} disabled label="PhP version" name="php_version" /> */}
                {/* <EditTextField key="text-server_id" value={site.site.server_id} disabled label="Server" name="server_id" /> */}
                
            </EditCard>
        </EditGroup>
        <EditGroup key="group-2" label="Service">
            <EditCard columns="1">
                <EditTextField value="Hosting" disabled/>
                <GridLayout columns="1" columnsLg="2">
                <EditTextField key="text-price" value={site.price} label="Price" name="price"/>
                <EditSelect defa key="select-billing-interval" value={site.payment_interval} label="Billing interval" name="billing" defaultValue="1">
                    <option value="12">Yearly</option>
                    <option value="3">Quarterly</option>
                    <option value="1">Monthly</option>
                </EditSelect>
            </GridLayout>
            </EditCard>
            
        </EditGroup>
        <ForgeEdit id={id} setError={setError}/>
       
       
        
    </EditColumn>
    <EditColumn span="1">
        <EditGroup key="group-6" label="Status">
            <EditCard>
                <EditSelect name="client_status" value={site.status} key="select-status" label="Client Status">
                    <option value="Aktiv">Aktiv</option>
                    <option value="Pause">Pause</option>
                    <option value="Ophørt">Ophørt</option>
                </EditSelect>
            </EditCard>
        </EditGroup>
    </EditColumn>
</EditLayout>
<div className="block bg"></div>
    </EditDataLayout>
}

const ForgeEdit = ({id, setError}) => {
    const [forgeState, setForgeState] = useState(0);
    const [status, setStatus] = useState({});
    const [isLoading, loadingState, setLoading] = useLoading(true); 
    

    useEffect(()=>{
        if(id){
            setLoading(0, true);
            axiosClient({
                url:`admin/sites/${id}`
            }).then((response)=>{
                if(response.data){
                    setStatus(response.data);
                    setForgeState(1);
                }
                else{
                    setForgeState(0);
                }
                setLoading(0,false);
            })
            .catch((error)=>{
                setLoading(0,false);
                setForgeState(-1);
                setError(error);
            });


            
        } else{
            setForgeState(1)
        }
    }, [id]);

    useEffect(()=>{
        switch(forgeState){
            case 1:
                if(status?.forge){
                    setForgeState(2)
                }
                break;
                case 2:
                if(status?.ssl){
                    setForgeState(3)
                }
                break;
                case 3:
                if(status?.wordpress==="Inactive"||status?.wordpress==="Active"){
                    setForgeState(4)
                }
                break;
                
                
        }
    }, [forgeState])


    const create = ()=>{
        setForgeState(0);
        axiosClient({
            url:`admin/sites/${id}/forge`,
            method:"post"
        }).then((response)=>{
            setStatus(response.data);
            setForgeState(2);
            sendNotification("success", "Server created");

        })
        .catch((error)=>{
            setForgeState(-1);
            setError(error);
        });
    }
    const addSSL = ()=>{
        setForgeState(0);
        axiosClient({
            url:`admin/sites/${id}/forge/ssl`,
            method:"post"
        }).then((response)=>{
            setStatus(response.data);
            setForgeState(3);
            sendNotification("success", "SSL certificate installed");
        })
        .catch((error)=>{
            setForgeState(-1);
            setError(error);
        });
    }
    const addWp = ()=>{
        setForgeState(0);
        axiosClient({
            url:`admin/sites/${id}/wordpress`,
            method:"post"
        }).then((response)=>{
            setStatus(response.data);
            setForgeState(4);
            sendNotification("success", "Wordpress installed");
        })
        .catch((error)=>{
            setForgeState(-1);
            setError(error);
        });
    }
    const cloneWp = ()=>{
        setForgeState(0);
        axiosClient({
            url:`admin/sites/${id}/clone`,
            method:"post"
        }).then((response)=>{
            setStatus(response.data);
            setForgeState(5);
            sendNotification("success", "Template site cloned");
        })
        .catch((error)=>{
            setForgeState(-1);
            setError(error);
        });
    }

 
    const handleDelete = (value) => {
        if(id){
            setLoading(4, true);
        axiosClient({
            url:`admin/sites/${id}/forge`,
            method:"delete"
        }).then((response)=>{
            setStatus(response.data);
            setLoading(4, false);
            setForgeState(4);
        })
        .catch((error)=>{
            setForgeState(-1);
            setLoading(4, false);
            setError(error);
        });
        }
    }
    const handleClose = (value) => {
        if(id){
            setLoading(3, true);
        axiosClient({
            url:`admin/sites/${id}/wordpress`,
            method:"delete"
        }).then((response)=>{
            setStatus(response.data);
            setLoading(3, false);
            setForgeState(4)
        })
        .catch((error)=>{
            setForgeState(-1);
            setLoading(3, false);
            setError(error);
        });
        }
    }
    const handleShutdown = (value) => {
        if(id){
            setLoading(5, true);
            setForgeState(0);

        axiosClient({
            url:`admin/sites/${id}/close`,
            method:"post"
        }).then((response)=>{
            setStatus(response.data);
            setLoading(5, false);
            setForgeState(1);
            sendNotification("success", "Site closed down");
            window.location.reload(false);
        })
        .catch((error)=>{
            setForgeState(-1);
            setLoading(5, false);
            setError(error);
        });
        }
    }
    if(!id){
        return <></>
    }

    const buttonClass="disabled:cursor-not-allowed inline-flex items-center justify-between font-medium tracking-tight rounded-lg border transition focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700 w-full disabled:ring-0 disabled:text-gray-400 disabled:border-gray-300 disabled:bg-gray-100"

    return <ServiceForm id={id} active="sites" type="Site" typeName="Hosting" name={`${status.domain}`}>
        <EditGroup label="Site">
        <GridLayout>
            
            {
                isLoading?<EditCard>
                <div className="flex flex-1 flex-col items-center justify-center p-6 mx-auto space-y-6 text-center ">
                

                <div className="flex items-center justify-center w-16 h-16 text-primary-500 rounded-full ">
                <svg className="animate-spin w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"></path>&gt;
                    </svg>
                       </div>
                       </div>
                </EditCard>:<>
                <EditCard>
            <EditTextField label="Domain" ignoreValue disabled value={status.domain}/>

            </EditCard>
                <EditCard>
        
        <div className="inline-flex items-center space-x-3 rtl:space-x-reverse text-sm font-medium leading-4 text-gray-700">
            <span className="font-bold">Server:</span>
            <span>{status?.forge?"Running":"N/A"}</span>
        </div>
        <div className="inline-flex items-center space-x-3 rtl:space-x-reverse text-sm font-medium leading-4 text-gray-700">
            <span className="font-bold">Wordpress:</span>
            <span>{status?.wordpress?status.wordpress:"N/A"}</span>
        </div>
        
        
        </EditCard>
        <EditCard>
            <button disabled={forgeState!==1} onClick={create} type="button" className={buttonClass}>
                <span className="flex-1">Create Server</span>
                <div className="text-primary-600 w-6 h-6 flex-2"><CheckCircle/></div>


            </button>
            <button disabled={forgeState!==2} onClick={addSSL} type="button" className={buttonClass}>
                
                <span className="flex-1">Add SSL</span>
                {
                    forgeState>2&&<div className="text-primary-600 w-6 h-6 flex-2"><CheckCircle/></div>
                }
                
            </button>
            <button disabled={forgeState!==3} onClick={addWp} type="button" className={buttonClass}>
                
                <span className="flex-1">Install Wordpress</span>
                {
                    forgeState>3&&<div className="text-primary-600 w-6 h-6 flex-2"><CheckCircle/></div>
                }
                
            </button>
           
            
            
            <button disabled={forgeState!==4} onClick={cloneWp} type="button" className={buttonClass}>
                <span className="flex-1">Clone Sandbox</span>
                {
                    forgeState>4&&<div className="text-primary-600 w-6 h-6 flex-2"><CheckCircle/></div>
                }
            </button>
        </EditCard>
        <EditCard>
            {
                
                status?.wordpress&&<ConfirmationButton className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-danger-600 hover:bg-danger-500 focus:bg-danger-700 focus:ring-offset-danger-700 w-full" onClick={handleClose} title="Remove Wordpress" content="Are you sure you would like to do this?">
                    Close Site
                </ConfirmationButton>
                
                // <button  onClick={setMessageBox({
                //     title: "Remove Wordpress",
                //     content: "Are you sure you would like to do this?",
                //     onSubmit: handleDelete
                // })} type="button" className={buttonClass}>
                
                // </button>
            }
            {
                status?.forge&&<ConfirmationButton className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-danger-600 hover:bg-danger-500 focus:bg-danger-700 focus:ring-offset-danger-700 w-full" onClick={handleDelete} title="Delete Servers" content="Are you sure you would like to do this?">
                Delete Server
            </ConfirmationButton>
                // <button  onClick={()=>setMessageBox(<MessageBox onHide={setMessageBox(<></>)} onSubmit={handleDelete} content="Are you sure you would like to do this?" title="Delete Server"/>)} type="button" className={buttonClass}>
                // Delete Server
                // </button>
            }
            {
                (status?.forge||status?.wordpress)&&<ConfirmationButton className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-danger-600 hover:bg-danger-500 focus:bg-danger-700 focus:ring-offset-danger-700 w-full" onClick={handleShutdown} title="Shut down site" content="Are you sure you would like to do this?">
                Shut down
            </ConfirmationButton>
            }
        </EditCard>
        {/* <messageBox/> */}
                </>
            }
        
        </GridLayout>
    </EditGroup>
    </ServiceForm>
}
export {SiteCreate};
export default SiteEdit;