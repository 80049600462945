import { useEffect, useState } from "react";
import axiosClient from "../axiosClient";
import {DataTable} from "../components/DataTable"
import Select from "../components/form/Select";
import Filter from "../components/Filter";
import MultiSelect from "../components/form/MultiSelect";

const DateFormat = (value)=>{
    if(!value){
        return <></>;
    }
    let date = new Date(value);
    if(date.getTime()<=0){
        return <></>;
    } 
    const dateOptions = {
        day: 'numeric',
        month:'long',
        year: 'numeric'
    }
    
    let dateString = date.toLocaleDateString('en-UK', dateOptions);
    
    return <>{dateString}</>
}




const MonthFilter = ({onChange, value})=>{
    const [months, setMonths] = useState([]);
    
    useEffect(()=>{
        let monthsTemp = [];
        let year = 2023;
        let month = 10;

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth()+1;

        do{
            

            if(month===12){
                year++;
                month=1;
            } else{
                month++;
            }
            let code = `${year}${month<10?'0':''}${month}`;
            let display = new Date(year, month-1,1).toLocaleString("en-UK", {
                year:"numeric",
                month: "long"
            });
            
            monthsTemp.unshift({
                code:code,
                display:display
            })
            
        } while(!(year===currentYear&&month===currentMonth))

 

        setMonths(monthsTemp);
    }, []);

    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        {
            months.map((m,i)=><option key={i} value={m.code}>{m.display}</option>)
        }
    </MultiSelect>
}
const UserFilter = ({onChange, value})=>{
     const [users, setUsers] = useState([]);
    useEffect(() => {

            axiosClient({
                method: "get",
                url: `/admin/employees`
            })
                .then((response) => {
                    
                    setUsers(response.data.sort((a, b)=>a.name>b.name?1:-1))?.map((employee)=>{
                        return {
                            name: employee.name,
                            id: employee.id
                        }
                    });
                    
                })
                .catch(er=>console.error(er));
        
    }, []);
    
    return <Select value={value} onChange={onChange} placeholder="All">
                                             {
                                                users.map((as, index)=>{
                                                    return <option key={index} value={as.id}>{as.name}</option>
                                                })
                                             }
                                         </Select>
}
const TripsFilter = ({onChange}) => {


    return <Filter onChange={onChange} filters={[
        {
            name: "month",
            element: <MonthFilter/>,
            label: "Month",
            defaultValue: []
        },
        {
            name: "user_id",
            element: <UserFilter/>,
            label: "User",
            defaultValue: false
        }
    ]}/>
}

const Trips = ()=>{
    return <DataTable path="trips?include=User,Customer" filter={<TripsFilter/>} searchable="customer_name,user_name"  >
        <td row={DateFormat} field="date" header="Date" sortable/>
        <td row={(user_name)=><>{user_name}</>} field="user_name" header="Medarbejder" sortable/>
        <td row={(customer_name)=><>{customer_name}</>} field="customer_name" header="Kunde" sortable/>
        <td row={(distance)=><>{distance}</>} field="distance" header="Distance (Km)" sortable/>
        <td row={(expense)=><>{expense},-</>} field="expense" header="Price" sortable/>
    </DataTable>


}

export default Trips;