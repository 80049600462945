import { useEffect, useState } from "react";
import { Link, useBeforeUnload, useNavigate, useNavigation } from "react-router-dom";
import { sendNotification } from "./Notification";
import Currency from "./form/Currency";
import { jPathSet } from "../scripts/jPath";
import Number from "./form/Number";
import DatePicker from "./form/DatePicker";
import SelectNumberPair from "./form/SelectNumberPair";
import { useBlocker } from "react-router";
import Select from "./form/Select";

const EditBoolean = ({ label, name, value, ignoreValue, disabled, onChange }) => {


    const [boolean, setBoolean] = useState(false);

    useEffect(()=>{
        setBoolean(value);
    }, [value])

    function switchValue() {
        if(!disabled){
            setBoolean(!boolean);
            onChange(!boolean);
        }
    }


    

    var buttonClass = boolean ? "relative inline-flex shrink-0 h-min w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:border-primary-500 focus:ring-2 focus:ring-primary-500 border-gray-300 bg-primary-600" : "relative inline-flex shrink-0 h-min w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:border-primary-500 focus:ring-2 focus:ring-primary-500 border-gray-300 bg-gray-200";
  
   

    return <div className="col-span-1">
        <div>

            <div className="space-y-2">
                <div className="flex items-center justify-between space-x-2 rtl:space-x-reverse">
                    <label className="inline-flex items-center space-x-3 rtl:space-x-reverse" htmlFor={`data.${name}`}>
                        <button role="switch" name={`data.${name}`}  type="button" onClick={switchValue} className={buttonClass+(disabled?" opacity-75":"")}>
                            <span className={`pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 ease-in-out transition duration-200 ${boolean ? "translate-x-full rtl:-translate-x-full" : "translate-x-0"}`}>
                                <span className="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity opacity-0 ease-out duration-100" aria-hidden="true" >
                                </span>

                                <span className="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity opacity-100 ease-in duration-200" aria-hidden="true" >
                                </span>
                            </span>
                        </button>
                        <input name={name} id={`${ignoreValue?"ignore":"data"}.${name}`} type="hidden" data-type="bool" value={boolean?true:false} readOnly/>

                        <span className="text-sm font-medium leading-4 text-gray-700">
                            {label}

                        </span>


                    </label>

                </div>




            </div>
        </div>

    </div>
}

const EditSelect = ({ label, required, name, children, value, ignoreValue, defaultValue, onChange, search, placeholder }) => {
    
    const [selectedValue, setSelectedValue] = useState();

    useEffect(()=>{
        setSelectedValue(value??defaultValue)

    }, [])
    useEffect(()=>{
        setSelectedValue(value??defaultValue);

    }, [value])
    
    function setValue(event){
        let value = event?.target?.value??event;
        setSelectedValue(value);
        if(onChange){
            onChange(value);
        }
    }
    
    return <div className="col-span-1 ">
        <div>

            <div className="space-y-2">
                <div className="flex items-center justify-between space-x-2 rtl:space-x-reverse">
                    <label className="inline-flex items-center space-x-3 rtl:space-x-reverse" htmlFor={`${name}`}>


                        <span className="text-sm font-medium leading-4 text-gray-700">
                            {label}

                            {required && <sup className="font-medium text-danger-700">*</sup>}
                        </span>


                    </label>

                </div>

                <div className="flex items-center space-x-1 group">

                    <div className="flex-1">
                        {
                            search?<>
                            <Select name={`display.${name}`} placeholder={placeholder} onChange={setValue} value={value} >
                                {children}
                            </Select>
                            <input type="hidden" value={selectedValue} name={name} id={`${ignoreValue?"ignore":"data"}.${name}`}  />
                            </>:
                            <select  onChange={setValue} name={name} id={`${ignoreValue?"ignore":"data"}.${name}`} value={selectedValue} className="text-gray-900 block w-full transition duration-75 rounded-lg shadow-sm focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600 disabled:opacity-70 border-gray-300">
                            {!defaultValue&&<option value="__default__">Select an option</option>}
                            {children}
                        </select>
                        
                        
                            
                        }
                    </div>

                </div>


            </div>
        </div>

    </div>
}

const EditList = ({ label, required, name, placeholder, value, ignoreValue}) => {
    
    
    const [values, setValues] = useState([]);
    const [inputValue, setInputValue] = useState("");

    useEffect(()=>{
        var state=[];
        if(value){
            state=value.split(',');
        }
        setValues(state);
    }, [value])

    function addValue(value){
        setInputValue("");
        if(!value){
            return;
        }
        value=value.trim();

        if(value===""||values.includes(value)){
            return;
        }

        
        values.push(value);

        setValues(values);

    }

    function removeValue(value){
        setValues(values.filter(v=>v!=value));
    }

    function handleEnter(event){
        event.preventDefault();
        addValue(inputValue);
    }

    return <div className="col-span-1 ">
        <div>

            <div className="space-y-2">
                <div className="flex items-center justify-between space-x-2 rtl:space-x-reverse">
                    <label className="inline-flex items-center space-x-3 rtl:space-x-reverse" htmlFor={`data.${name}`}>


                        <span className="text-sm font-medium leading-4 text-gray-700">
                            {label}

                            {required && <sup className="font-medium text-danger-700"> *</sup>}
                        </span>


                    </label>

                </div>
                <div>
                <div className="block w-full transition duration-75 divide-y rounded-lg shadow-sm border overflow-hidden focus-within:border-primary-600 focus-within:ring-1 focus-within:ring-primary-600 border-gray-300">

                    <div>
                        <input name={`data.${name}`} onKeyDown={(e) => { e.key === 'Enter' && handleEnter(e); }} onSubmit={(e)=>{e.preventDefault(); }} value={inputValue} onBlur={(e)=>addValue(e.target.value)} onChange={e=>{
                            setInputValue(e.target.value)
                            if(e.target.value.endsWith(",")){
                                addValue(e.target.value.replace(","))
                            } 
                        }} autoComplete="off" placeholder={placeholder} type="text" required={required} className="block w-full border-0" />
                        <input id={`${ignoreValue?"ignore":"data"}.${name}`} type="hidden" value={values.join(',')} readOnly name={name}/>
                    </div>
                    <div className={values.length==0?"hidden":"overflow-hidden rtl:space-x-reverse relative w-full p-2"}>
                        <div className="flex flex-wrap gap-1">
                        {values.map((v, index)=>{
                            return <button key={index} onClick={()=>removeValue(v)} type="button" className="inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight text-primary-700 rounded-xl bg-primary-500/10 space-x-1">
                            <span className="text-left" x-text="tag">{v}</span>

                                                            <svg className="w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" >
  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
</svg>                                                    </button>
                        })}
                        </div>
                        
                    </div>

</div>
                </div>
                


            </div>
        </div>

    </div>
}

const EditCurrency = ({label, onChange, required, amountName, currencyName, disabled, amountValue, currencyValue, ignoreValue, placeholder}) => {
    const [value, setValue] = useState({});

    useEffect(()=>{
        setValue({
            amount: amountValue??"",
            currency: currencyValue,
        })
    }, [amountValue, currencyValue])

    useEffect(()=>{
        if(onChange){
            onChange(value);
        }
    },[value])

    return <div className="col-span-1 ">
    <div>

        <div className="space-y-2">
            <div className="flex items-center justify-between space-x-2 rtl:space-x-reverse">
                <label className="inline-flex items-center space-x-3 rtl:space-x-reverse" htmlFor={`input.${amountName}`}>


                    <span className="text-sm font-medium leading-4 text-gray-700">
                        {label}

                        {required && <sup className="font-medium text-danger-700"> *</sup>}
                    </span>


                </label>

            </div>

            <div className="flex items-center space-x-1 group">

                <div className="flex-1">
                    <Currency value={value} onChange={setValue} disabled={disabled} placeholder={placeholder} name={`input.${amountName}`}/>
                    {
                        !ignoreValue&&<>
                        <input value={value?.amount} type="hidden" data-type="number" name={amountName} id={`data.${amountName}`} />
                    <input value={value?.currency} type="hidden" name={currencyName} id={`data.${currencyName}`} />
                    </>
                    }
                </div>

            </div>


        </div>
    </div>

</div>
}
const EditSelectNumberPair = ({label, required, amountName, selectedName, disabled, amountValue, selectedValue, ignoreValue, placeholder, defaultSelected, children}) => {
    const [value, setValue] = useState({});

    useEffect(()=>{
        setValue({
            amount: amountValue??"",
            selected: selectedValue,
        })
    }, [amountValue, selectedValue])

    return <div className="col-span-1 ">
    <div>

        <div className="space-y-2">
            <div className="flex items-center justify-between space-x-2 rtl:space-x-reverse">
                <label className="inline-flex items-center space-x-3 rtl:space-x-reverse" htmlFor={`input.${amountName}`}>


                    <span className="text-sm font-medium leading-4 text-gray-700">
                        {label}

                        {required && <sup className="font-medium text-danger-700"> *</sup>}
                    </span>


                </label>

            </div>

            <div className="flex items-center space-x-1 group">

                <div className="flex-1">
                    <SelectNumberPair defaultSelected={defaultSelected} value={value} onChange={setValue} disabled={disabled} placeholder={placeholder} name={`input.${amountName}`}>
                        {children}
                    </SelectNumberPair>
                    {
                        !ignoreValue&&<>
                        <input value={value?.amount} type="hidden" data-type="number" name={amountName} id={`data.${amountName}`} />
                        <input value={value?.selected} type="hidden" name={selectedName} id={`data.${selectedName}`} />
                    </>
                    }
                </div>

            </div>


        </div>
    </div>

</div>
}

const EditNumber = ({ label, required, name, placeholder, disabled, value, ignoreValue, icon, onChange }) => {
    
    const [numberValue, setNumberValue] = useState(0);
    useEffect(()=>{
        setNumberValue(value??0);
    }, [value])
    function setValue(v){
       
        setNumberValue(v);
        if(onChange){
            onChange(parseInt(v));
        }
    }
    
    
    return <div className="col-span-1 ">
        <div>

            <div className="space-y-2">
                <div className="flex items-center justify-between space-x-2 rtl:space-x-reverse">
                    <label className="inline-flex items-center space-x-3 rtl:space-x-reverse" htmlFor={`${name}`}>


                        <span className="text-sm font-medium leading-4 text-gray-700">
                            {label}

                            {required && <sup className="font-medium text-danger-700"> *</sup>}
                        </span>


                    </label>

                </div>

                <div className="flex items-center space-x-1 group">

                    <div className="flex-1">
                    <Number icon={icon} value={numberValue} onChange={setValue} disabled={disabled} placeholder={placeholder} name={name} id={`${ignoreValue?"ignore":"data"}.${name}`} required={required} />

                    </div>

                </div>


            </div>
        </div>

    </div>
}
const EditTextField = ({ label, required, name, placeholder, disabled, value, ignoreValue, secret, type="text", onChange }) => {
    
    const [textValue, setTextValue] = useState("");
    useEffect(()=>{
        setTextValue(value??"");
    }, [value])
    function setValue(event){
       let value = event.target.value;

        setTextValue(value);
        if(onChange){
            onChange(value);
        }
    }
    
    
    return <div className="col-span-1 ">
        <div>

            <div className="space-y-2">
                <div className="flex items-center justify-between space-x-2 rtl:space-x-reverse">
                    <label className="inline-flex items-center space-x-3 rtl:space-x-reverse" htmlFor={`${name}`}>


                        <span className="text-sm font-medium leading-4 text-gray-700">
                            {label}

                            {required && <sup className="font-medium text-danger-700"> *</sup>}
                        </span>


                    </label>

                </div>

                <div className="flex items-center space-x-1 group">

                    <div className="flex-1">
                        <input value={textValue} onChange={setValue} disabled={disabled} placeholder={placeholder} type={secret?"password":type} name={name} id={`${ignoreValue?"ignore":"data"}.${name}`} required={required} className="block w-full transition duration-75 rounded-lg shadow-sm focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600 disabled:opacity-70 border-gray-300" />
                    </div>

                </div>


            </div>
        </div>

    </div>
}
const EditTextArea = ({ label, required, name, placeholder, disabled, value, ignoreValue, secret, type="text" }) => {
    
    const [textValue, setTextValue] = useState("");
    useEffect(()=>{
        setTextValue(value??"");
    }, [value])
    function setValue(event){
       
        setTextValue(event.target.value);
    }
    
    
    return <div className="col-span-1 ">
        <div>

            <div className="space-y-2">
                <div className="flex items-center justify-between space-x-2 rtl:space-x-reverse">
                    <label className="inline-flex items-center space-x-3 rtl:space-x-reverse" htmlFor={`${name}`}>


                        <span className="text-sm font-medium leading-4 text-gray-700">
                            {label}

                            {required && <sup className="font-medium text-danger-700"> *</sup>}
                        </span>


                    </label>

                </div>

                <div className="flex items-center space-x-1 group">

                    <div className="flex-1">
                        <textarea value={textValue} onChange={setValue} disabled={disabled} placeholder={placeholder} type={secret?"password":type} name={name} id={`${ignoreValue?"ignore":"data"}.${name}`} required={required} className="block w-full transition duration-75 rounded-lg shadow-sm focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600 disabled:opacity-70 border-gray-300" />
                    </div>

                </div>


            </div>
        </div>

    </div>
}
const EditDate = ({label, required, name, disabled, value, ignoreValue, onChange })=>{
    const [dateValue, setDateValue] = useState("");

    const handleChange = (value)=>{
        setDateValue(value);
        if(onChange){
            onChange(value);
        }
    }

    useEffect(()=>{
        setDateValue(value);
    }, [value])


    return <div className="col-span-1 ">
        <div>

            <div className="space-y-2">
                <div className="flex items-center justify-between space-x-2 rtl:space-x-reverse">
                    <label className="inline-flex items-center space-x-3 rtl:space-x-reverse" htmlFor={`${name}`}>


                        <span className="text-sm font-medium leading-4 text-gray-700">
                            {label}

                            {required && <sup className="font-medium text-danger-700"> *</sup>}
                        </span>


                    </label>

                </div>

                <div className="flex items-center space-x-1 group">

                    <div className="flex-1 max-w-full">
                        <DatePicker onChange={handleChange} disabled={disabled} value={dateValue} name={name} id={`${ignoreValue?"ignore":"data"}.${name}`}/>
                    </div>

                </div>


            </div>
        </div>

    </div>
}
const EditCard = ({ children, columns }) => {

    var lgGridCols = "lg:grid-cols-1";

    if (columns == "2") {
        lgGridCols = "lg:grid-cols-2"
    }

    return <div className="p-6 bg-white shadow rounded-xl">
        <div className={`grid gap-6 grid-cols-1 ${lgGridCols}`}>
            {children}
        </div>
    </div>
}

const EditGroup = ({ children, label }) => {
    return <div className="col-span-full">
        <fieldset className="rounded-xl shadow-sm border border-gray-300 p-6">
            <legend className="text-sm leading-tight font-medium px-2 -ml-2">
                {label}
            </legend>
            <div className="grid gap-6 grid-cols-1 lg:grid-cols-1">
                <div className="col-span-full" >
                    {children}
                </div>
            </div>
        </fieldset>
    </div>
}
const EditColumn = ({ children, span }) => {


    // doing `col-span-${span}` would not work because of tailwind optimizing

    var className;
    var columns;

    switch (span) {
        case "1":
            className = "col-span-1";
            columns = "sm:grid-cols-1";
            break;
        case "2":
            className = "col-span-2";
            columns = "sm:grid-cols-2";
            break;
    }



    return <div className={className}>
        <GridLayout columns="1" columnsLg="2" columnsSm={span}>
            {children}
        </GridLayout>

    </div>
}



const EditLayout = ({ formRef, children, method, onSubmit, create, cancelRedirect, footer, shouldBlockNavigation }) => {
    const [isDirty, setIsDirty] = useState(false);

    

    const handleChange = ()=>{
        if(!isDirty){
            setIsDirty(true);
        window.addEventListener("beforeunload", unloadCallback);

        }
    }
    const handleSubmit = (event) =>{

        window.removeEventListener("beforeunload", unloadCallback);
        
        event.preventDefault();
        var object = {};
        
        Object.entries(event.target).map(e=>e[1]).filter(node=>node.id==`data.${node.name}`).forEach(node=>{
            let value=node.value==="__default__"||node.value===""?null:node.value;
            
            switch(node.attributes["data-type"]?.value){
                case "number":
                    value=parseFloat(value);
                    break;
                case "bool":
                    value=value==="true"?true:false;
                    break;
            }
            
            jPathSet(object, node.name, value);
            
        });
        let action = event.target["edit.action"]?.value;
        
        onSubmit({value:object, action:action});
       
    }
    const confirmation=()=>{

    }
    const unloadCallback = (event) => {

            if(shouldBlockNavigation){
                event.preventDefault(); 
                event.returnValue = "";
            }
            return "";
        };
    useEffect(() => {
        

      
        
        return () => {
          
            window.addEventListener("popstate", confirmation());
            window.removeEventListener("beforeunload", unloadCallback);
        }
      },[]);
      


    return <form ref={formRef} onSubmit={handleSubmit} method={method} onChange={handleChange} className="space-y-6" >
       
        <div className="grid gap-6 grid-cols-1">
            <div className="col-span-full">
                <div className="grid gap-6 grid-cols-1 lg:grid-cols-3">
                    {children}
                </div>
            </div>
        </div>
        {
            footer??<EditFooter create={create} cancelRedirect={cancelRedirect} />
        }
    </form>
}
const EditFooter = ({loading, create, cancelRedirect, className}) => {
    const [action, setAction] = useState("single");
   
    return <div className={className??"flex flex-wrap items-center gap-4 justify-start w-full col-span-full"}>
        
        {
            create?
            <>
            <input type="hidden" name="edit.action" value={action}/>
            <SaveButton onClick={()=>setAction("single")} name="action.single" text="Create" loading={loading}/>
           
            <Link relative="path"  className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset  h-9 px-4 text-gray-800 bg-transparent border-gray-300 hover:bg-gray-50 focus:ring-primary-600 focus:text-primary-600 focus:bg-primary-50 focus:border-primary-600" to={cancelRedirect??"../"}>

                <span>Cancel</span>

            </Link>
            </>
            :<>
            <input type="hidden" name="edit.action" value={action}/>

            <SaveButton text="Save"  loading={loading}/>
            <Link className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset  h-9 px-4 text-gray-800 bg-transparent border-gray-300 hover:bg-gray-50 focus:ring-primary-600 focus:text-primary-600 focus:bg-primary-50 focus:border-primary-600" relative="path" to={cancelRedirect??"../../"}>

                <span>Cancel</span>

            </Link>
            </>
        }
        

    </div>
}
const SaveButton = ({text, loading, onClick}) =><button onClick={onClick} value="single" type="submit" className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700">
{loading&&<svg className="w-6 h-6 mr-1 -ml-2 rtl:ml-1 rtl:-mr-2 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
    <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"></path>
</svg>}

<span>{text}</span>

</button>;

const GridLayout = ({ children, columns, columnsSm, columnsLg }) => {

    var classes =
        [
            "grid",
            "gap-6",
        ]

    if (columns) {
        switch (columns) {
            case "1":
                classes.push("grid-cols-1");
                break;
            case "2":
                classes.push("grid-cols-2");
                break;
        }
    }

    if (columnsSm) {
        switch (columnsSm) {
            case "1":
                classes.push("sm:grid-cols-1");
                break;
            case "2":
                classes.push("sm:grid-cols-2");
                break;
        }
    }

    if (columnsLg) {
        switch (columnsLg) {
            case "1":
                classes.push("lg:grid-cols-1");
                break;
            case "2":
                classes.push("lg:grid-cols-2");
                break;
        }
    }

    var className = classes.join(' ');

    return <div className={className}>
        {children}
    </div>
}

const TabLayout = ({ children }) => {
    const [tab, setTab] = useState(children[0].props.id)

    return <div className="rounded-xl shadow-sm border border-gray-300 bg-white">
        <div className="rounded-t-xl flex overflow-y-auto bg-gray-100">
            {children.map((child, index) => <button onClick={() => setTab(child.props.id)} type="button" key={index} className={`p-3 text-sm font-medium ${tab == child.props.id ? "bg-white" : ""}`}>
                {child.props.label}
            </button>)}
        </div>
        {children.map((child, index) => <div key={index} className={child.props.id == tab ? "p-6 focus:outline-none" : "hidden"}>
            {child}
        </div>)}


    </div>
}
const Tab = ({ children, id }) => {

    return <GridLayout columns="1">
        {children}
    </GridLayout>
}




export {Tab, TabLayout, GridLayout, EditSelectNumberPair, EditBoolean, EditCard, EditColumn, EditFooter, EditGroup, EditLayout, EditList, EditSelect, EditTextField, EditCurrency, EditNumber, EditDate, EditTextArea}