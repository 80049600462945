import { Link, useParams } from "react-router-dom"
import useData from "../hooks/useData";
import { useEffect, useState } from "react";
import { EditDataLayout } from "../pages/DataLayout";
import axiosClient from "../axiosClient";

const CustomerLayout = ({active, children, customerState, actionButton})=>{
    let { id } = useParams();
    const [error, setError] = useState();
    const [customer,] = useData({},"admin/customers/"+id);
    const [,setCustomer] = customerState??[{},()=>{}];

    useEffect(()=>{
        setCustomer(customer);
    },[customer]);


    const Element = ({children, to})=>{
        return <li className="me-2">
            {
            
            children===active?<div className="inline-block cursor-pointer p-4 text-primary-600 border-b-2 border-primary-600 rounded-t-lg active " aria-current="page">{children}</div>:<Link  to={"../"+to} relative="path" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300">{children}</Link>
            }
        </li>
    }
    const handleDelete = (callback) =>{
        if(!id){
            return;
        }

        axiosClient({
            method: "DELETE",
            url: `/admin/customers/${id}`
        })
            .then((response) => {
                setError(null);
                callback();
                
            })
            .catch(setError);
    }

    return <EditDataLayout breadcrumb={active} onDelete={handleDelete} name={customer.name} title="Customers" active="customers" create={!id} actionButton={actionButton}>
    <div className="space-y-12">
        {
            id&&<nav className="text-sm font-medium text-center text-gray-500 border-b border-gray-300 ">
            <ul className="flex flex-wrap -mb-px">
               
                <Element to="edit">Edit</Element>
                <Element to="billing">Billing</Element>
                <Element to="services">Services</Element>
                <Element to="users">Users</Element>
            </ul>
        </nav>
        }
    {children}
    </div>
    </EditDataLayout>
  
}

export default CustomerLayout;