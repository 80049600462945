import { useState } from "react"
import NamingGeneratorBase, { NamingGeneratorSelect, NamingGeneratorTextField } from "./NamingGeneratorBase"


function getResult(values){
    if(values.workflowtype=='Mailflow'){
        return `${values.workflowtype??""} - ${values.segment??""} (${values.funnel??""}) | ${values.language??""}`
    } else if(values.workflowtype=='Takkemail'){
        return `${values.workflowtype??""} - ${values.title??""} | ${values.language??""}`
    }
    else{
        return "";
    }
    
}
function validate(values, field, optional = false){
    if(values[field]){
        return <span className="text-success-600" >{`${values[field]}`}</span>;
    } else {
        return <span className={optional?"text-warning-600":"text-danger-600"} >{`{${field}}`}</span>;
    }
}
function getValidation(values){
    if(values.workflowtype=='Mailflow'){
        return <>{validate(values, "workflowtype")} - {validate(values, "segment")} ({validate(values, "funnel")}) | {validate(values, "language")}</>
    } else if(values.workflowtype=='Takkemail'){
        return <>{validate(values, "workflowtype")} - {validate(values, "title")} | {validate(values, "language")}</>
    }
    else{
        return <><span className="text-danger-600" >{"{workflowtype}"}</span></>;
    }
}

const Workflow = ()=>{
    const [selectedType, setSelectedType] = useState(sessionStorage.getItem('naming-generator.workflowtype'));
    

    switch(selectedType){
        case "Takkemail":
            return <NamingGeneratorBase getResult={getResult} getValidation={getValidation} title="Workflow">
            <NamingGeneratorSelect label="Type" name="workflowtype" onChange={e=>setSelectedType(e.target.value)} options={[
                "Takkemail",
                "Mailflow"
            ]} />
           
            <NamingGeneratorTextField name="title" label="Titel" />
            <NamingGeneratorTextField name="language" label="Sprog" placeholder="DA" />
        </NamingGeneratorBase>

        case "Mailflow":
            return <NamingGeneratorBase getResult={getResult}  getValidation={getValidation} title="Workflow">
            <NamingGeneratorSelect label="Type" name="workflowtype" onChange={e=>setSelectedType(e.target.value)} options={[
                "Takkemail",
                "Mailflow"
            ]} />
            <NamingGeneratorTextField name="segment" label="Segment" />
            <NamingGeneratorSelect name="funnel" label="Funnel" options={[
                'Awareness',
                'Consideration',
                'Decision',
                'Framework'
            ]}/>
            <NamingGeneratorTextField name="language" label="Sprog" placeholder="DA" />
            </NamingGeneratorBase>

            default:
                return <NamingGeneratorBase getResult={getResult}  getValidation={getValidation}  title="Workflow">
                <NamingGeneratorSelect label="Type" name="workflowtype" onChange={e=>setSelectedType(e.target.value)} options={[
                    "Takkemail",
                    "Mailflow"
                ]} />
                <input type="hidden" name="language"/>
                <input type="hidden" name="funnel"/>
                <input type="hidden" name="segment"/>
                <input type="hidden" name="title"/>
                <></>
                </NamingGeneratorBase>
    }
   
}

export default Workflow;