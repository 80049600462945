const Title = ({title, children})=>{
    return <div className="space-y-6">
                <div className="space-y-2 items-start justify-between sm:flex sm:space-y-0 sm:space-x-4 sm:py-4 ">
                    <h1 className="text-2xl font-bold tracking-tight md:text-3xl ">{title}</h1>
                </div>
                <div className="content-wrapper">
                    {children}
            </div>
            </div>
}

export default Title;