import React, { Component } from "react";

class NamingGeneratorBase extends Component{
    constructor(props){
        super(props);
        

        var currentState = {};

        props.children.map(child=>{
            
            currentState[child.props.name]=sessionStorage.getItem(`naming-generator.${child.props.name}`);
        })
        
        this.state={
            copied:false,
            values:currentState};

        
    }



    render(){
        return <><div className="px-6 py-4 mb-6 bg-white overflow-hidden rounded-xl shadow">
        <div className="bg-white pb-4 border-b border-gray-200 mb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{this.props.title}</h3>
        </div>

            <div className="lg:grid grid-cols-3 gap-4 mb-6">
            {this.props.children.map((child, index)=>{
                    
                    return <React.Fragment key={index}>
                        {
                            React.cloneElement(child, {
                                options:child.props.options,
                                placeholder: child.props.placeholder,
                                label: child.props.label,
                                name: child.props.name,
                                setValue: (name,value)=>{
                                    sessionStorage.setItem(`naming-generator.${name}`, value)
                                    var newState= this.state;
                                    newState.values[name]=value;
                                    this.setState(newState);
                                },
                                onChange: child.props.onChange,
                                value: sessionStorage.getItem(`naming-generator.${child.props.name}`)
        
                            })
                        }
                    </React.Fragment>
                })}
            </div>

        </div>
        
        <div className="px-6 py-4 mb-6 bg-white overflow-hidden rounded-xl shadow">
        <div className="bg-white pb-4 border-b border-gray-200 mb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Result</h3>
        </div>

        
        
            <div className="" x-data="{ copied: false, copyTimeout: null }">

                <div className="flex items-center py-3">
                    <div className="flex-1 mr-3 ">
                        <input type="text" readOnly x-ref="campaign_group_output" className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md" value={this.result()}/>
                    </div>

                    <div className="w-6 h-6 -ml-12">

                        {
                            this.isValid()?
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-success-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            :
<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-danger-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                        }
                            

                        
                    </div>

                    <div className="w-64 h-6 ml-6">
                        <div className="flex -mx-2 items-center">
                            <div className="px-2">

                                <button onClick={()=>this.copyToClipboard()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
                                    </svg>
                                </button>
                            </div>

                            <div className="px-2 -mt-3">

                                <span x-show="copied" className="text-xs text-gray-500" style={{display: (this.state.copied?"block":"none")}}>copied to clipboard</span>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex items-center">
                    <div className="px-3 py-1 text-sm select-none">
                        {this.validation()}
                    </div>


                </div>

            </div>
        
        
        
            </div>
        
        </>
    }

    copyToClipboard(){
        navigator.clipboard.writeText(this.result());

        var newState1 = this.state;
        
            newState1.copied=true;
            this.setState(newState1);

        setTimeout(() => {
            var newState = this.state;

            newState.copied=false;

            this.setState(newState)
         }, 1500);
    }

    result(){
        if(this.props.getResult){
            return this.props.getResult(this.state.values);
        }
        return this.props.children.map(child=>{
            return this.state.values[child.props.name];
        }).join(" | ");
    }

    validation(){
        if(this.props.getValidation){
            return this.props.getValidation(this.state.values);
        }

        var index = 0;
        var last = this.props.children.length;
        return <>
        {
            this.props.children.map((child, index)=>{
                var value = this.state.values[child.props.name];
                index++;
                return <React.Fragment key={index}>{value? <span className="text-success-600" >{`${value}`}</span>:<span className={child.props.optional?"text-warning-600":"text-danger-600"} >{`{${child.props.name}}`}</span>}{index<last&&" | "}</React.Fragment>;
            })
        }
        </>;
    }

    isValid(){

        var result = true;

        this.props.children.forEach(child => {
            if(!this.state.values[child.props.name])
                result=false;
        });

        return result;
    }

}

class NamingGeneratorTextField extends React.Component{
    constructor(props){
        super(props);

       
    }
    handleChange = (e)=>{
        this.props.setValue(this.props.name, e.target.value); 
        if(this.props.onChange){
            this.props.onChange(e)
        }
    }
    render(){

        

        return <div className="">
        <label htmlFor={this.props.name} className="block text-sm font-bold text-gray-700 mb-2">{this.props.label}</label>
            <div className="mt-1">
                <input value={this.props.value??""} onChange={this.handleChange}  type="text" name={this.props.name} id={this.props.name} placeholder={this.props.placeholder} className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"/>
            </div>
        </div>
    }
}

class NamingGeneratorSelect extends React.Component{
    constructor(props){
        super(props);

        this.state = {};
    }
    handleChange = (e)=>{
        this.props.setValue(this.props.name, e.target.value); 
        if(this.props.onChange){
            this.props.onChange(e)
        }
    }
    render(){

        return <div className="">
        <label htmlFor={this.props.name} className="block text-sm font-bold text-gray-700 mb-2">{this.props.label}</label>
        <select value={this.props.value??""} onChange={this.handleChange} id={this.props.name}  name={this.props.name}  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md">
                        <option value="">-- Select {this.props.name} --</option>
                        {this.props.options.map((option, index)=>{
                            return <option key={index} value={option}>{option}</option>
                        })}
                                            </select>
        </div>
    }
}

// export  NamingGeneratorTextField;
export {
    NamingGeneratorBase, 
    NamingGeneratorTextField,
    NamingGeneratorSelect
}
export default NamingGeneratorBase;