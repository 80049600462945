import { useParams } from "react-router-dom"
import CustomerLayout from "../components/CustomerNavigation";
import { DataTable } from "../components/DataTable";
import ServiceFilter from "../components/ServiceFilter";
import DateFormat from "../components/DateFormat";

const StatusDisplay = (status) =>{
    const colors = {
        'Aktiv': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-success-700 bg-success-500/10`}>Aktiv</span>,
        'Pause': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-primary-700 bg-primary-500/10`}>Pause</span>,
        'Ophørt': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-danger-700 bg-danger-500/10`}>Ophørt</span>

    }

    let color = colors[status];

    if(!color){
        color=<></>
    }
        
    
    return color;
}


const CustomerServices = ()=>{
    const {id} = useParams();

        
        return <CustomerLayout active="Services" actionButton={<a href={`../../services/create?customer=${id}`} className="w-full inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700">Add service</a>}>
            <DataTable path={`services?customer=${id}`} searchable="client_name,name" filter={<ServiceFilter defaultStatus={[]}/>}>
                <td row={(type)=><>{type}</>} field="service_type" header="Service"/>
                <td row={(description)=><>{description}</>} field="description" header="Description"/>
                <td row={DateFormat} field="end_date" header="End date" sortable/>
                <td row={StatusDisplay} field="status" header="Status"/>
                <td row={(service)=><>{service?.price} {service?.currency?.toUpperCase()}</>} header="Price" sortable/>
                {/* <td row={(currency)=><>{currency}</>}  field="currency" header=""/> */}

                
            </DataTable>

    </CustomerLayout>
}

export default CustomerServices;

