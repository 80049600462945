import React, { useState } from "react";
import MessageBox from "./MessageBox";
import { createPortal } from "react-dom";

const ConfirmationButton = ({className, title, content, onClick, children, id}) => {
    const [state, setState] = useState(false);

    const onHide = ()=>{
        setState(false);
    }

    const handleSubmit = (value) =>{
        if(value==="confirm"){
        onClick();
        }
    }
    const Display = ()=>{
        return <>
        {
    state&&<MessageBox onHide={onHide} title={title} content={content} onSubmit={handleSubmit}/>
}</>
    };
    const modal = document.getElementById("mm-modal");


    

    

    return <>
    <button type="button" onClick={()=>setState(true)} className={className}>
        {children}
        {createPortal(<Display/>, modal)}
</button>

    </>;

    

}

export default ConfirmationButton;