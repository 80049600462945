import NamingGeneratorBase, { NamingGeneratorSelect, NamingGeneratorTextField } from "./NamingGeneratorBase";
import axiosClient from "../../axiosClient";
import { useState, useEffect } from "react";

const getResult = (values)=>{
    return `${values.customer?"MM_"+values.customer:""} | ${values.geography??""} | ${values.industry??""} | ${values.company_size??""} | ${values.function??""} | ${values.seniority??""}`
}
const getValidation = (values)=>{
    return <>
    {values.customer?
    <span className="text-success-600" >{`MM_${values.customer}`}</span>
:<span className="text-danger-600" >{`{customer}`}</span>} | {validate(values, "geography")} | {validate(values, "industry", true)} | {validate(values, "company_size", true)} | {validate(values, "function", true)} | {validate(values, "seniority", true)}
    </>
}
function validate(values, field, optional = false){
    if(values[field]){
        return <span className="text-success-600" >{`${values[field]}`}</span>;
    } else {
        return <span className={optional?"text-warning-600":"text-danger-600"} >{`{${field}}`}</span>;
    }
}
const SalesLIOutreach = ()=>{
    const [customers, setCustomers] = useState([]);

    useEffect(()=>{
        axiosClient({
            method: "get",
            url: `/admin/customers`
        })
            .then((response) => {
                
                setCustomers(response.data.filter(customer=>customer.account_li&&customer.channel_li&&customer.client_status==="Aktiv").sort((a, b)=>a.name>b.name?1:-1).map((customer)=>{
                    return customer.name;
                }));
                
            })
            .catch(console.log);

        
    },[])
    return <NamingGeneratorBase title="Sales LI Outreach" getResult={getResult} getValidation={getValidation}>
        <NamingGeneratorSelect name="customer" label="Customer" options={customers}/>
        <NamingGeneratorTextField name="geography" label="Geography" placeholder="Denmark"/>
        <NamingGeneratorTextField optional name="industry" label="Industry" placeholder="Banking"/>
        <NamingGeneratorTextField optional name="company_size" label="Company size" placeholder="51-200"/>
        <NamingGeneratorTextField optional name="function" label="Function/Title" placeholder="Owner"/>
        <NamingGeneratorTextField optional name="seniority" label="Seniority" placeholder=""/>
        
    </NamingGeneratorBase>
}

export default SalesLIOutreach;