
import { useEffect, useState } from 'react';
import { json, Link, useNavigate } from 'react-router-dom';
import axiosClient from '../axiosClient';
import Cookies from 'js-cookie';
import { useEncryptor } from '../scripts/passwordHash';



const Login = ({ }) => {
    
    const [username, setName] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);
    const [encrypt] = useEncryptor();
    
    const [error, setError] = useState();

    let navigate = useNavigate()
    
    const overrideSubmit = async (event)=>{
        if(event.key==="Enter"){
            await handleSubmit(event);
        }
    }
    const handleSubmit = async (event)=>{
        if(!username || !password ){
            return;
        }
        event.preventDefault();
        var pw = await encrypt(password??"test");

        var value = {
            email: username,
            password: pw,
            remember: remember
        }
        var body = {
            method:"login",
            value: JSON.stringify(value)
        };
        
        axiosClient({
            method: "post",
            url: `/auth`,
            data: body
        })
            .then((response) => {
                
                if(response.data.success){
                    
                    Cookies.set('token', response.data.token, {expires: 30, sameSite: "None", secure:true,domain:"."+window.location.host.match(/[^.]+\.[^.]+$/)});
                    navigate("../../admin/dashboard");
                    window.location.reload(false);
                } else{
                    setError("These credentials do not match our records.")
                }
                
            }).catch(console.log);

    };


    const logoStyle = {
      fill: "#18395b"
    }
    
    return <div className='w-full font-sans text-gray-900 antialiased'>
     <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">
<div>
        <a href="/">
    <svg  id="Lag_1" data-name="Lag 1" xmlns="http://www.w3.org/2000/svg" width="70.12" height="70.17" viewBox="0 0 70.12 70.17">
        <defs>
            
        </defs>
        <path style={logoStyle} className="cls-1" d="M675.39,512.38a35.06,35.06,0,1,1-34.56-35.15C659.71,477.34,675.43,493.26,675.39,512.38Zm-48.95-2.57h0c0,3,0,6.05,0,9.08,0,1.13.4,1.49,1.46,1.42a19.64,19.64,0,0,1,2.87,0c1.19.11,1.59-.3,1.58-1.53-.06-5.1,0-10.2,0-15.3,0-.92.25-1.55,1.18-1.8a23.63,23.63,0,0,1,8.15-.93c4.44.39,6.58,2.65,6.75,7.11,0,.47,0,.95,0,1.43,0,3.27,0,6.53,0,9.8,0,.85.27,1.25,1.17,1.21,1.15,0,2.31,0,3.46,0,.95,0,1.27-.39,1.23-1.3s0-2,0-3c-.06-3.63.05-7.26-.24-10.87a9.85,9.85,0,0,0-7.9-9.23,28.43,28.43,0,0,0-17.65,1.78,3.09,3.09,0,0,0-1.95,3.13Zm3,13.24c-.64,0-1.28,0-1.91,0-.82,0-1.17.27-1.14,1.11s0,1.91,0,2.86c0,2,.31,2.32,2.38,2.33,3.48,0,3.48,0,3.48-3.44C632.28,523.05,632.28,523.05,629.46,523.05Zm18.9,2.9c0,.59,0,1.19,0,1.78-.05,1.13.55,1.59,1.6,1.6h.95c3.28,0,3.28,0,3.28-3.32,0-3,0-3-3-3S648.36,523.05,648.36,526Z" transform="translate(-605.28 -477.23)"></path>
    </svg>
</a>
    </div>
    <div className='w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg'>
        {
            error&&<div className="mb-4">
            <div className="font-medium text-red-600">Whoops! Something went wrong.</div>
    
            <ul className="mt-3 list-disc list-inside text-sm text-red-600">
                                <li>{error}</li>
                        </ul>
        </div>
        }
    <div className="flex items-center justify-center my-6">
            <a href={`https://accounts.google.com/o/oauth2/v2/auth?promt=consent&client_id=708729931635-0p53d04d54919vnaau0ob0tmrs8das85.apps.googleusercontent.com&scope=profile%20openid%20email&response_type=code&redirect_uri=https://${window.location.host}/auth/google-callback`} className="block px-6 py-3 bg-gray-700 text-white rounded hover:bg-gray-600">

                <span className="inline-block mr-1">
                    <i className="fab fa-google"></i>
                </span>
                <span>
                    Continue with Google
                </span>
            </a>
        </div>
    <form onKeyDown={(e) => { e.key === 'Enter' && handleSubmit(e); }}>
    
        
    <div>
                    <label className="block font-medium text-sm text-gray-700" htmlFor="email">
    Email
      </label>
                    <input required  onKeyDown={overrideSubmit} onChange={e => setName(e.target.value)} value={username} className="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm block mt-1 w-full" id="name" type="email" name="name" autoFocus="autofocus"/>
                </div>
                <div className="mt-4">
                    <label className="block font-medium text-sm text-gray-700" htmlFor="data.password">
    Password
</label>
                    <input  onKeyDown={overrideSubmit} onChange={e => setPassword(e.target.value)} value={password} className="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm block mt-1 w-full" id="password" type="password" name="data.password" required="required" autoComplete="current-password"/>
                </div>
                <div className="block mt-4">
                    <label htmlFor="remember_me" className="flex items-center">
                        <input type="checkbox" className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" id="remember_me" name="remember"/>
                        <span className="ml-2 text-sm text-gray-600">Remember me</span>
                    </label>
                </div>
                <div className="flex items-center justify-end mt-4">
                                            <a className="underline text-sm text-gray-600 hover:text-gray-900" href="*">
                            Forgot your password?
                        </a>
                    
                    <button  onClick={handleSubmit} className="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition ml-4">
    Log in
</button>
                </div>
        
    
    </form>
    </div>
    </div> 
    </div>
}

export default Login;
