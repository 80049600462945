import { Link } from "react-router-dom";


const TableElement = ({ url, children, relative, openNew, readonly }) => {
    const Child = () => <div className="px-4 py-3">
        {children}
    </div>

    if(readonly){
        return <td><Child/></td>
    }

    const Content = () => {
        if(url){
            if(relative){
                return <Link to={"../" + url}>
                <Child />
            </Link>
            } else{
                return <a target={openNew?"_blank":""} href={url}>
                <Child />
                </a>
            }
        } else{
            return <Child/>
        }
    } 
    return <td>
        <Content/>
    </td>
}

export default TableElement;