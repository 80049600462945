import { Outlet, Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from "react";
import MessageBox from "../components/MessageBox";
import { sendNotification } from "../components/Notification";
import Popup from "../components/Popup";
import ThreeDots from "../components/svg/ThreeDots";

const DataLayout = ({active,title, children, actions, breadcrumbs}) => {
  const [sidebar, setSidebar] = useState(true);
  const toggleSidebar = ()=>{
    setSidebar(!sidebar);
  }
  useEffect(()=>{
    document.title = `${title} - Mindmill Console`;

  }, []);
  var single = (title.endsWith("s")?title.slice(0, title.length-1):title).toLowerCase();
  return (
    <>
    <Sidebar  isOpen={sidebar} onSidebarToggle={toggleSidebar} active={active}/>
      <div className="w-screen space-y-6 flex-1 flex flex-col lg:pl-80 rtl:lg:pl-0 rtl:lg:pr-80">
        <Header onSidebarToggle={toggleSidebar} >
          {
            breadcrumbs&&<ul className="hidden gap-4 items-center font-medium text-sm lg:flex">
              {
                breadcrumbs.map((crumb, index)=>{
                  var last = index===breadcrumbs.length-1;

                  return <li key={index} className={last?"":"after:h-6 after:border-gray-300 after:-skew-x-12 after:border-r after:ml-4 after:inline-flex inline-flex"}>
                    <a href={crumb.href} className={last?"text-gray-500":""}>
                                  {crumb.label}
                              </a>
                  </li>
                })
              }
            </ul>
          }
        </Header>
        <main className="flex-1 w-full px-4 mx-auto md:px-6 lg:px-8  max-w-7xl">
            <div className="space-y-6">
                <div className="space-y-2 items-start justify-between sm:flex sm:space-y-0 sm:space-x-4 sm:py-4 ">
                    <h1 className="text-2xl font-bold tracking-tight md:text-3xl ">{title}</h1>
                    <div className="flex flex-wrap items-center gap-4 justify-start shrink-0">
                        {
                          actions&&actions.map((action, index)=>{
                            return <Link key={index} to={action.href} className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset  h-9 px-4 text-white shadow focus:ring-white border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700">
                            <span>{action.label}</span>
                            </Link>
                          })
                        }
                    </div>
                </div>
                <div className="content-wrapper">
              {children}

            </div>
            </div>

            
        </main>
      </div>
      
    </>
  )
};

const EditDataLayout = ({active, name,title, children, onDelete, create, deleteButton, actions, actionButton, breadcrumb="Edit"}) => {
  const [sidebar, setSidebar] = useState(true);
  const [showMessageBox, setShowMessageBox] = useState(false);

  const toggleSidebar = ()=>{
    setSidebar(!sidebar);
  }

  var navigate = useNavigate();
  var single = (title.endsWith("s")?title.slice(0, title.length-1):title).toLowerCase();
  useEffect(()=>{
    if(create){
      document.title = `Create ${single} - Mindmill Console`;

    }else{
      document.title = `Edit ${name} - Mindmill Console`;
    }

  }, [name]);
  return (
    <>
    <Sidebar isOpen={sidebar} onSidebarToggle={toggleSidebar} active={active}/>
    {showMessageBox&&(deleteButton?React.cloneElement(deleteButton, {onHide:()=>setShowMessageBox(false)}):(<MessageBox onHide={()=>setShowMessageBox(false)} onSubmit={(value)=>{
      if(value!="confirm"){
        return;
      }
      onDelete(()=>{
        sendNotification("success", "Saved");
        navigate(`../${title}/`);

      });
    }} title={`Delete ${single}`} content="Are you sure you would like to do this?"/>))}
    

      <div className="w-screen space-y-6 flex-1 flex flex-col lg:pl-80 rtl:lg:pl-0 rtl:lg:pr-80">
        <Header  onSidebarToggle={toggleSidebar}>
        <ul className="hidden gap-4 items-center font-medium text-sm lg:flex">
                          <li>
                              <a href="../">
                                  {title}
                              </a>
                          </li>

                          <li className="h-6 border-r border-gray-300 -skew-x-12"></li>
                          {
                            create?<>
                          <li>
                              <a href="#" className="text-gray-500">
                                  Create
                              </a>
                          </li></>:
                          <>
                          <li>
                              <a href="#">
                                  {name}
                              </a>
                          </li>
                          <li className="h-6 border-r border-gray-300 -skew-x-12"></li>
                          <li>
                              <a href="#" className="text-gray-500">
                                  {breadcrumb}
                              </a>
                          </li></>
                          }
                        </ul>
        </Header>
        <main className="flex-1 w-full px-4 mx-auto md:px-6 lg:px-8  max-w-7xl">
            <div className="space-y-6">
                {
                  create?<div className="space-y-2 items-start justify-between sm:flex sm:space-y-0 sm:space-x-4 sm:py-4 ">
                  <h1 className="text-2xl font-bold tracking-tight md:text-3xl ">Create {single}</h1>
                      <div className="flex flex-wrap items-center gap-4  justify-start shrink-0">
                         
                      </div>
                  </div>
                  :<div className="space-y-2 items-start justify-between sm:flex sm:space-y-0 sm:space-x-4 sm:py-4 ">
                  <h1 className="text-2xl font-bold tracking-tight md:text-3xl ">Edit {name}</h1>
                      <div className="flex flex-wrap items-center gap-4  justify-start shrink-0">
                          {
                            actionButton?actionButton:<Popup buttonClass="flex items-center justify-center w-9 h-9 rounded-full hover:bg-gray-500/5 focus:outline-none  text-primary-500 focus:bg-primary-500/10" className="right-0 rtl:right-auto rtl:left-0 mt-2 shadow-xl rounded-xl w-52 top-full" icon={<ThreeDots className="text-gray-500  rotate-90 w-5 h-5"/>}>
                            <div id="mm-datalayout-actions" className="overflow-hidden  bg-white shadow rounded-xl text-gray-700">
                                <button onClick={()=>{
                                  setShowMessageBox(true)
                           
                                }} className="flex focus:ring-offset-danger-700 focus:bg-danger-700 hover:bg-danger-500 focus:text-white hover:text-white transition items-center w-full h-8 px-4 text-sm font-medium">
                                <span >Delete {single}</span>
                              </button>
                              {actions}
                            </div>
                          
                          </Popup>
                          }
                      </div>
                  </div>
                }
                <div className="content-wrapper">
              {children}

            </div>
            </div>

            
        </main>
        <footer className="py-4 shrink-0 "></footer>
      </div>
      
    </>
  )
};

export {EditDataLayout};
export default DataLayout;