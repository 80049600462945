import React from "react";
import axiosClient from "../axiosClient";
import CustomerFilter from "./CustomerFilter";
import Popup from "./Popup";
import MessageBox from "./MessageBox";
import { sendNotification } from "./Notification";
import TableElement from "./TableElement";
import jPath from "../scripts/jPath";
import { Link } from "react-router-dom";
class DataTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            query: {
                field: "name",
                direction: 1,
                page: 0,
                limit: 10,
                search: "",
                filter: {}
            },
            itemCount: 0,
            items: [],
            filteredItems: [],
            queriedItems: [],
            shouldFilter: false,
            shouldQuery: false,
            selectedItems: [],
            isLoading: true
        };
    }

    updateItems = () => {
        this.setState({isLoading: true});
        axiosClient({
            method: "get",
            url: `/admin/${this.props.path}`
        })
            .then((response) => {

                var items = response.data;
                
                if(!Array.isArray(items)){
                    items=items.$values;
                }
                this.setState({
                    isLoading: false,
                    items: items,
                    itemCount: response.data.length,
                    selectedItems: []
                }, () => {
                    this.filterItems(this.state.query)
                });
                ;
            });
    }

    componentDidMount() {
        this.updateItems();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

      

        if (this.state.changeSelections) {
            let items = this.state.filteredItems;
            let selectedItems = this.state.selectedItems;
            this.state.changeSelections.forEach(element => {

                let prevValue = items[element.index]["_selected_"]
                items[element.index]["_selected_"] = element.value;

                if (element.value&&!prevValue) {
                    selectedItems = selectedItems.concat(element.index);
                }
            });

            selectedItems = selectedItems.filter(i => items[i]["_selected_"]);


            this.setState({
                changeSelections: null,
                filteredItems: items,
                queriedItems: this.state.queriedItems.map((item) => {
                    item["_selected_"] = items[item["_index_"]]["_selected_"];

                    return item;
                }),
                selectedItems: selectedItems
            });
        }

        if (this.state.shouldFilter) {
            this.filterItems(this.state.query);
        }
        if (this.state.shouldQuery) {
            this.queryItems(this.state.query);
        }



    }


    tableMain = () => {

        const selectAll = (event) => {
            let value = !this.state.queriedItems.every(item => item["_selected_"]);
            this.setState({
                changeSelections: this.state.queriedItems.map(item => {
                    return {
                        index: item["_index_"],
                        value: value
                    }
                })
            });
        }

        let allSelected = this.state.queriedItems.every(item => item["_selected_"]);


        let anySelected = this.state.selectedItems.length > 0

        var className = this.props.searchable || anySelected ? 'overflow-y-auto relative border-t' : 'overflow-y-auto relative rounded-t-xl'

        return <div className={className}>
            {
                this.state.itemCount>0?
                <table className='w-full text-left rtl:text-right divide-y table-auto'>
                <thead>
                    <tr className='bg-gray-50'>
                        {
                            !this.props.readonly&&<th className="w-4 px-4 whitespace-nowrap ">
                            <input onChange={selectAll} checked={allSelected} value={allSelected} className="block border-gray-300 rounded shadow-sm text-primary-600 focus:border-primary-600 focus:ring focus:ring-primary-200 focus:ring-opacity-50" type="checkbox" />
                        </th>
                        }
                        {this.props.children.map((child, index) => {
                            return <React.Fragment key={index}>
                                {this.dataTableHeaderElement({className:child.props.className,field:child.props.field, sortable:child.props.sortable, children:<>{child.props.header}</>})}
                            </React.Fragment>
                        })}
                        {
                            !this.props.readonly&&<th className="w-5"></th>
                        }
                    </tr>
                </thead>
                {this.dataTableBody()}
            </table>:
            <div className="flex items-center justify-center p-4">
                <div className="flex flex-1 flex-col items-center justify-center p-6 mx-auto space-y-6 text-center bg-white">
                    <div className="flex items-center justify-center w-16 h-16 text-primary-500 rounded-full bg-primary-50">
                        {this.state.isLoading?<svg x-show="isLoading" className="animate-spin w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"></path>&gt;
                        </svg>:<svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg> }
                           </div>

                    <div className="max-w-xs space-y-1">
                        <h2 className="text-xl font-bold tracking-tight ">
                            {this.state.isLoading?<>Loading</>:<>No records found</>}
                        </h2>

                        <p className="text-sm font-medium text-gray-500 ">

                        </p>
                    </div>

                </div>
            </div>
            }
        </div>
    }

    filterItems(query) {

        var items = this.state.items;

        const filters = Object.entries(query.filter ?? {}).filter(f => f[1]).map(f => {

            return {
                field: f[0],
                value: f[1]
            }
        });

        const search = (query.search ?? "").trim()?.toLowerCase();

        if(this.props.searchable){
            if (search != "") {
                var tags = search.split(' ');
    
                items = items.filter(i => {
                    return this.props.searchable.split(',').find(searchable=>{
                        var name = jPath(i, searchable)?.toLowerCase();
                        return tags.filter(t => name?.includes(t)).length == tags.length;
                    })
                });
            }
        }

        if(this.props.filter){
            filters.forEach(({ field, value }) => {

                if (Array.isArray(value)) {
                    if (value.length > 0) {
                        items = items.filter(i => !value.every(v=>v!=jPath(i,field)));
                    }
                } else {
                    items = items.filter(i => jPath(i,field) == value);
                }
            });
        }

        if(items){
            items = items.map((item, index) => {
                item["_selected_"] = false;
                item["_index_"] = index;
                return item;
            });
        } else{
            items=[];
        }

        this.setState({ filteredItems: items, shouldQuery: true, shouldFilter: false, selectedItems: [] });

    }

    queryItems(query) {



        const limit = query.limit;

        const sortField = query.field;
        const sortDir = query.direction;





        var items = this.state.filteredItems;


        const itemCount = items.length;

        while (query.page * limit >= itemCount && query.page > 0) {
            query.page--;
        }

        const page = query.page;
        const start = (limit ?? 10) * (page ?? 0);

        items = items.concat().sort((a, b) => (a[sortField] > b[sortField] ? 1 : -1) * sortDir).slice(start, start + limit);



        this.setState({ queriedItems: items, itemCount: itemCount, shouldQuery: false });
    }
    render() {


        let anySelected = this.state.selectedItems.length > 0;



        return <div className='border border-gray-300 shadow-sm bg-white rounded-xl'>
            {
                (this.props.searchable || anySelected) && <this.tableHeader anySelected={anySelected} searchable={this.props.searchable} />
            }
            <this.tableMain />
            <this.tablePaginator/>
            <this.messageBox />

        </div>
    }
    
    
    
    messageBox = () => {
        const confirmMessage = (option) => {
            if (option === "confirm") {
                this.deleteSelected();
            }
        }
        return <>
            {this.state.showMessageBox && <MessageBox title="Delete selected" content="Are you sure you would like to do this?" onHide={() => this.setState({ showMessageBox: false })} onSubmit={confirmMessage} />}
        </>
    }
    tablePaginator = () => {
        const setLimit = (event) => {
            var newQuery = this.state.query;

            newQuery.limit = Number(event.target.value);

            this.setState({ query: newQuery, shouldQuery: true })
        }

        const maxPages = Math.round((this.state.itemCount / this.state.query.limit) - 0.5);

        const startIndex = this.state.query.limit * this.state.query.page + 1;
        var endIndex = startIndex - 1 + this.state.query.limit;

        if (endIndex > this.state.itemCount) {
            endIndex = this.state.itemCount;
        }



        const changePage = (page) => {
            var newQuery = this.state.query;

            newQuery.page = page;

            this.setState({ query: newQuery, shouldQuery: true })
        }




        return <div className="p-2 border-t">
            <nav role="navigation" aria-label="" className="flex items-center justify-between">
                <div className="flex justify-between items-center flex-1 lg:hidden">
                    <div className="w-10">
                    </div>

                    <div className="flex items-center space-x-2 rtl:space-x-reverse">
                        <select defaultValue="10" onChange={setLimit} id="tableRecordsPerPageSelect" className="h-8 text-sm pr-8 leading-none transition duration-75 border-gray-200 rounded-lg shadow-sm focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>

                        <label htmlFor="tableRecordsPerPageSelect" className="text-sm font-medium">
                            per page
                        </label>
                    </div>

                    <div className="w-10">
                        <button type="button" className="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-500/5 focus:outline-none  text-primary-500 focus:bg-primary-500/10" rel="next">
                            <span className="sr-only">
                                Next
                            </span>

                            <svg className="w-5 h-5 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                            </svg>    </button>
                    </div>
                </div>

                <div className="hidden flex-1 items-center lg:grid grid-cols-3">
                    <div className="flex items-center">
                        <div className="pl-2 text-sm font-medium">
                            {this.state.itemCount>1&&<>Showing {startIndex} to {endIndex} of {this.state.itemCount} results</>}
                        </div>
                    </div>

                    <div className="flex items-center justify-center">
                        <div className="flex items-center space-x-2 rtl:space-x-reverse">
                            <select defaultValue="10" onChange={setLimit} id="tableRecordsPerPageSelect" className="h-8 text-sm pr-8 leading-none transition duration-75 border-gray-200 rounded-lg shadow-sm focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>

                            <label htmlFor="tableRecordsPerPageSelect" className="text-sm font-medium">
                                per page
                            </label>
                        </div>
                    </div>

                    <div className="flex items-center justify-end">
                        {this.state.query.limit < this.state.itemCount &&
                            <div className="py-3 border rounded-lg">
                                <ol className="flex items-center text-sm text-gray-500 divide-x divide-gray-300">

                                    {
                                        this.state.query.page > 0 && <li>
                                            <button onClick={() => changePage(this.state.query.page - 1)} type="button" className="relative flex items-center justify-center font-medium min-w-[2rem] px-1.5 h-8 -my-3 rounded-md focus:outline-none hover:bg-gray-500/5 focus:bg-primary-500/10 focus:ring-2 focus:ring-primary-500 transition text-primary-600" aria-label="Next" rel="next">
                                                <svg className="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span></span>
                                            </button>
                                        </li>
                                    }

                                    {
                                        maxPages > 8 ?
                                            <>
                                                {this.pageButton({page:0}) }
                                                {this.pageButton({page:1}) }

                                                {this.state.query.page < 5 && <>
                                                    {this.pageButton({page:2}) }
                                                    {this.pageButton({page:3}) }
                                                    {this.pageButton({page:4}) }
                                                    {this.pageButton({page:5}) }
                                                    {this.pageButton({disabled:true}) }

                                                </>}
                                                {
                                                    this.state.query.page >= 5 && this.state.query.page < maxPages - 4 ?
                                                        <>

                                                            {this.pageButton({disabled:true})}
                                                            {this.pageButton({page:this.state.query.page - 1})}
                                                            {this.pageButton({page:this.state.query.page})}
                                                            {this.pageButton({page:this.state.query.page + 1})}
                                                            {this.pageButton({disabled:true})}


                                                        </> : <></>
                                                }
                                                {
                                                    this.state.query.page >= maxPages - 4 &&
                                                    <>

                                                        {this.pageButton({disabled:true})}
                                                        {this.pageButton({ page:maxPages - 5})}
                                                        {this.pageButton({ page:maxPages - 4})}
                                                        {this.pageButton({ page:maxPages - 3})}
                                                        {this.pageButton({ page:maxPages - 2})}



                                                    </>
                                                }


                                                {this.pageButton({page:maxPages - 1}) }
                                                {this.pageButton({page:maxPages}) }
                                            </>
                                            : <>{this.pageButtons({
                                                count: maxPages
                                            })}</>
                                    }









                                    {
                                        this.state.query.page < maxPages && <li>
                                            <button onClick={() => changePage(this.state.query.page + 1)} type="button" className="relative flex items-center justify-center font-medium min-w-[2rem] px-1.5 h-8 -my-3 rounded-md focus:outline-none hover:bg-gray-500/5 focus:bg-primary-500/10 focus:ring-2 focus:ring-primary-500 transition text-primary-600" aria-label="Next" rel="next">
                                                <svg className="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span></span>
                                            </button>
                                        </li>
                                    }
                                </ol>
                            </div>
                        }
                    </div>
                </div>
            </nav>
        </div>
    }
    deleteSelected = () => {

        let ids = this.state.selectedItems.map(i => this.state.filteredItems[i].id).join(',');

        axiosClient({
            method: "delete",
            url: `/admin/${this.props.path.match(/[^\?]*/)}?ids=${ids}`
        })
            .then(() => {
                this.updateItems();
                sendNotification("success", "Records deleted")
            });

    }
    tableHeader = ({ anySelected, searchable }) => {



        const ActionsIcon = () => {
            return <svg className="w-5 h-5 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
            </svg>
        }

        return <div>
            <div className='flex items-center justify-between p-2 h-14'>
                <div>
                    {
                        anySelected && <Popup icon={(<ActionsIcon />)} className="shadow-xl rounded-xl w-52">
                            <ul className="py-1 space-y-1 overflow-hidden bg-white shadow rounded-xl">
                                <li>
                                    <button onClick={(e) => this.setState({ showMessageBox: true })} type="button" className="flex items-center w-full h-8 px-3 text-sm font-medium focus:outline-none hover:text-white focus:text-white group whitespace-nowrap hover:bg-danger-600 focus:bg-danger-700" >
                                        <svg className="mr-2 -ml-1 w-6 h-6 flex-shrink-0 rtl:ml-2 rtl:-mr-1 group-hover:text-white group-focus:text-white text-danger-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                        </svg>
                                        <span className="truncate">
                                            Delete selected
                                        </span>

                                    </button>
                                </li>
                            </ul>

                        </Popup>
                    }
                </div>
                {searchable &&
                    <>{this.tableSearch()}</>
                }

            </div>
        </div>
    }
    tableSearch = () => {
        const search = (event) => {
            var newQuery = this.state.query;

            newQuery.search = event.target.value;

            this.setState({ query: newQuery, shouldFilter: true })
        }

        const filter = (filter) => {
            var newQuery = this.state.query;

            newQuery.filter = filter;

            this.setState({ query: newQuery, shouldFilter: true })
        }
        return <div className='w-full md:w-auto flex items-center gap-2 md:max-w-md'>
            <div className="flex-1">
                <div>
                    <label htmlFor="tableSearchQueryInput" className="sr-only">
                        Search
                    </label>
                    <div className="relative group">
                        <span className="absolute inset-y-0 left-0 flex items-center justify-center w-9 h-9 text-gray-400 pointer-events-none group-focus-within:text-primary-500">
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                            </svg>        </span>
                        <input onChange={search} id="tableSearchQueryInput" placeholder="Search" type="search" autoComplete="off" className="block w-full h-9 pl-9 placeholder-gray-400 transition duration-75 border-gray-200 rounded-lg shadow-sm focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600" />
                    </div>
                </div>
            </div>
            {
                React.cloneElement(this.props.filter, {
                    onChange: filter
                })
            }
            
        </div>

    }
    pageButtons = ({ count }) => {
        var returnValues = [];

        for (let i = 0; i <= count; i++) {
            returnValues.push(i);
        }
        return <>
            {returnValues.map((v, i) => <React.Fragment key={i}>
                {this.pageButton({page: i})}
            </React.Fragment> )}
        </>
    }
    pageButton = ({ page, disabled }) => {
        const changePage = () => {
            var newQuery = this.state.query;

            newQuery.page = page;

            this.setState({ query: newQuery, shouldQuery: true })
        }

        if (disabled) {
            return <li>
                <button disabled="" type="button" className="relative flex items-center justify-center font-medium min-w-[2rem] px-1.5 h-8 -my-3 rounded-md focus:outline-none cursor-not-allowed opacity-75">
                    <span>...</span>
                </button>
            </li>
        }

        return <li>
            {
                page == this.state.query.page ?
                    <button type="button" className="relative flex items-center justify-center font-medium min-w-[2rem] px-1.5 h-8 -my-3 rounded-md focus:outline-none transition text-primary-600 focus:underline bg-primary-500/10 ring-2 ring-primary-500" aria-label="Go to page 1" >

                        <span>{page + 1}</span>
                    </button>
                    :
                    <button onClick={changePage} type="button" className="relative flex items-center justify-center font-medium min-w-[2rem] px-1.5 h-8 -my-3 rounded-md focus:outline-none hover:bg-gray-500/5 focus:bg-primary-500/10 focus:ring-2 focus:ring-primary-500 focus:text-primary-600 transition" aria-label="Go to page 1" >
                        <span>{page + 1}</span>
                    </button>
            }
        </li>
    }

    changeAllSelections = (value) => {

        this.setState({ isLoading: true });
        var items = this.state.filteredItems.map(i => {
            i["_selected_"] = value;
            return i;
        })
        var queriedItems = this.state.queriedItems.map(i => {
            i["_selected_"] = value;
            return i;
        })
        let selectedItems = [];
        if (value) {
            selectedItems = items.map((i) => i["_index_"]);
        }
        this.setState({
            isLoading: false,
            filteredItems: items,
            queriedItems: queriedItems,
            selectedItems: selectedItems
        });
    }

    dataTableBody = () => {

        const selectItem = (event, index) => {
            this.setState({
                changeSelections: [{
                    index: index,
                    value: !this.state.filteredItems[index]["_selected_"]
                }]
            });

        }



        let selectedCount = this.state.selectedItems.length;

        return <tbody className='divide-y whitespace-nowrap'>
            {selectedCount > 0 &&
                <tr className="bg-primary-500/10">
                    <td className="px-4 py-2 whitespace-nowrap text-sm" colSpan="13">
                        <div>
                            {this.state.isLoading &&
                                <span>
                                    <svg className="inline-block animate-spin w-4 h-4 mr-3 text-primary-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"></path>
                                    </svg>
                                </span>
                            }
                            {selectedCount > 1 ?
                                <span>{selectedCount} records selected. </span> : <span>1 record selected. </span>
                            }


                            {selectedCount < this.state.itemCount &&
                                <span>
                                    <button onClick={e => this.changeAllSelections(true)} className="text-primary-600 text-sm font-medium">
                                        Select all {this.state.itemCount}.
                                    </button><span> </span>
                                </span>
                            }

                            <span>
                                <button onClick={e => this.changeAllSelections(false)} className="text-primary-600 text-sm font-medium">
                                    Deselect all.
                                </button>
                            </span>
                        </div>
                    </td>
                </tr>
            }
            {this.state.queriedItems

                .map((item, index) => {

                    return <tr key={index}>
                        {
                            !this.props.readonly&&<th className="w-4 px-4 whitespace-nowrap ">
                            <input onChange={(e) => selectItem(e, item["_index_"])} checked={item["_selected_"]} value={item["_selected_"]} className="block border-gray-300 rounded shadow-sm text-primary-600 focus:border-primary-600 focus:ring focus:ring-primary-200 focus:ring-opacity-50" type="checkbox" />
                        </th>
                        }

                        {
                            this.props.children.map((child, index) => {

                                let fieldValue = jPath(item, child.props.field)
                                


                                var url = this.props.edit?this.props.edit(item):`${this.props.editPath??this.props.path.match(/[^\?]*/)}/${item.id}/edit`;
                                let openNew = false;

                                if (child.props.url) {
                                    url = child.props.url(fieldValue);
                                    openNew=true;
                                }



                                return <TableElement readonly={this.props.readonly} openNew={openNew}  relative={child.props.relative??!child.props.url} key={index} url={url}>
                                    {child.props.row? child.props.row(fieldValue):<>{fieldValue}</>}
                                </TableElement>
                             

                            })
                        }
                        {
                            !this.props.readonly&&<td className="px-4 py-3 whitespace-nowrap ">
                            <div className="flex items-center justify-center gap-4">
                                <Link className="hover:underline focus:outline-none focus:underline  text-primary-600 hover:text-primary-500 text-sm font-medium " to={this.props.edit?this.props.edit(item):`../${this.props.path}/${item.id}/edit`}>
                                    Edit
                                </Link>

                            </div>
                        </td>
                        }
                    </tr>
                })}
        </tbody>
    }
    
 




    dataTableHeaderElement = ({className, field, sortable, children }) => {

        
        const toggle = () => {

            var newQuery = this.state.query;

            var sortField = sortable===true?field:sortable;

            if (sortField != newQuery.field) {
                newQuery.field = sortField;
                newQuery.direction=1;
            } else {
                newQuery.direction *= -1;
            }
            this.setState({ query: newQuery, shouldQuery: true });
        }


        if (sortable) {
            return <th className={`p-0 ${className}`}>
                <button onClick={toggle} type="button" className="flex items-center w-full px-4 py-2 whitespace-nowrap space-x-1 rtl:space-x-reverse font-medium text-sm text-gray-600 ">
                    <span>
                        {children}
                    </span>



                    <span className="relative flex items-center">
                        {
                            this.state.query.field == field ?
                                <>
                                    {
                                        this.state.query.direction == 1 ?
                                            <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd"></path>
                                            </svg>
                                            : <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                            </svg>
                                    }
                                </>
                                : <svg className="w-3 h-3 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                        }

                    </span>

                </button>
            </th>
        }
        else {
            return <th className="p-0 ">
                <button type="button" className="flex items-center w-full px-4 py-2 whitespace-nowrap space-x-1 rtl:space-x-reverse font-medium text-sm text-gray-600 cursor-default ">
                    <span>
                        {children}
                    </span>

                </button>
            </th>
        }
    }
}



export { DataTable };