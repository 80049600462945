import { useEffect, useState } from "react";
import { redirect, useNavigate, useParams, useSearchParams } from "react-router-dom"
import axiosClient from "../axiosClient";
import Cookies from "js-cookie";


const GoogleCallback = ({}) => {
    const [response, setResponse] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const code = searchParams.get("code");
    const hd = searchParams.get("hd");
    const state = searchParams.get("state");

    let navigate = useNavigate();
    
    

    useEffect(()=>{
        let data = {
            redirect: `https://${window.location.host}/auth/google-callback`,
            code: code,
            hd: hd
        }
        let body = {
            method:"google",
            value: JSON.stringify(data)
        }

        axiosClient({
            method: "post",
            url: `/auth`,
            data: body
        })
            .then((response) => {
                
            setResponse(response.data);
                
            }).catch(console.log);
        
    }, [])

    useEffect(()=>{
        if(response.success){
            Cookies.set('token', response.token, {expires: 30, sameSite: "None", secure:true,domain:"."+window.location.host.match(/[^.]+\.[^.]+$/)});
            navigate("../../admin/dashboard");
            window.location.reload(false);
        }
    }, [response])

    return <></>
}

export default GoogleCallback;