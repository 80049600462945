import { useEffect, useState } from "react";

const Number = ({onChange, id, name, value, min, max, placeholder, disabled, icon})=>{
    const [number, setNumber] = useState();
    useEffect(()=>{
        setNumber(parseFloat(value));
    }, [value])

    const handleChange=(e)=>{
        let n = parseFloat(e.target.value);
        setNumber(n);
        onChange(n);
    }

    return <div className="flex items-center relative group w-full transition duration-75 divide-y rounded-lg shadow-sm border overflow-hidden focus-within:border-primary-600 focus-within:ring-1 focus-within:ring-primary-600 border-gray-300">
        <input disabled={disabled} data-type="number" placeholder={placeholder} onChange={handleChange} id={id} type="number" min={min} max={max} name={name} value={number} className="border-0 w-full focus:ring-0 disabled:opacity-70 "/>
        {icon&&<span className=" pointer-events-none select-none border-none px-3 py-2 text-gray-500 group-focus-within:text-primary-500">{icon}</span>}
    </div>
}

export default Number;