import NamingGeneratorBase, { NamingGeneratorSelect, NamingGeneratorTextField } from "./NamingGeneratorBase"

const getResult = (values)=>{
    return `${values.funnel?"MM_"+values.funnel:""} | ${values.segment??""} | ${values.language??""} | ${values.objective??""}`
}
const getValidation = (values)=>{
    return <>
    {values.funnel?
    <span className="text-success-600" >{`MM_${values.funnel}`}</span>
:<span className="text-danger-600" >{`{funnel}`}</span>} | {validate(values, "segment")} | {validate(values, "language")} | {validate(values, "objective", true)}
    </>
}
function validate(values, field, optional = false){
    if(values[field]){
        return <span className="text-success-600" >{`${values[field]}`}</span>;
    } else {
        return <span className={optional?"text-warning-600":"text-danger-600"} >{`{${field}}`}</span>;
    }
}

const CampaignGroup = ()=>{
    return <NamingGeneratorBase getValidation={getValidation} getResult={getResult} title="Campaign Group">
        <NamingGeneratorSelect name="funnel" label="Funnel" options={[
            'Awareness',
            'Consideration',
            'Decision',
            'Framework'
        ]}/>
        <NamingGeneratorTextField name="segment" label="Segment" placeholder="Asset Management"/>
        <NamingGeneratorTextField name="language" label="Language" placeholder="DA"/>
        <NamingGeneratorSelect optional name="objective" label="Objective" options={[
        'Engagement',
        'Awareness',
        'Leads',
        'Traffic',
        'Video views',
        'Website conversion',
    ]}/>
    </NamingGeneratorBase>
}

export default CampaignGroup;