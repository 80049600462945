import NamingGeneratorBase, { NamingGeneratorSelect, NamingGeneratorTextField } from "./NamingGeneratorBase"

const AdName = ()=>{
    return <NamingGeneratorBase title="Ad Name">
        <NamingGeneratorTextField name="variant" label="Variant" placeholder="Vinkel - V1"/>
        <NamingGeneratorSelect name="adtype" label="Type" options={[
        '1920',
        '1200',
        '1350',
        '1080',
        'Ani',
        'Carousel',
        'Video',
        'Promo'
    ]}/>
        <NamingGeneratorTextField name="copynr" label="Copynr" placeholder="Tekst1"/>

        
    </NamingGeneratorBase>
}

export default AdName;