import { useEffect } from "react";
import { useState } from "react";

const Currency = ({value, onChange, name, disabled, placeholder}) => {
    const [numberValue, setNumberValue] = useState(undefined);
    const [currency, setCurrency] = useState("dkk");

    useEffect(()=>{
        
        if(value){
            setCurrency(value.currency);
            setNumberValue(value.amount);
        }
        
    }, [value]);
    

    const updateValue = (numberV, curr) => {
        if(onChange){
            let floatValue ;

            if(!numberV||numberV== ""){
                floatValue=null;
            } else{
                floatValue=parseFloat(numberV);
            }


           
            onChange({
                amount:floatValue,
                currency:curr??"dkk"
            });
        }
    }

    const handleChange = (e)=>{
        updateValue(e.target.value, currency);
        setNumberValue(e.target.value);   
    }
    const changeCurrency = (event) =>{
        setCurrency(event.target.value);
        updateValue(numberValue, event.target.value);
    }
   const ignoreStep = (e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false });

    return <div className="block relative group w-full transition duration-75 divide-y rounded-lg shadow-sm border overflow-hidden focus-within:border-primary-600 focus-within:ring-1 focus-within:ring-primary-600 border-gray-300">
        <input placeholder={placeholder} data-type="number" onFocus={ignoreStep} disabled={disabled} name={name} step="0.01" className="block w-full border-0" value={numberValue} onChange={handleChange} type="number"/>
        <select disabled={disabled} onChange={changeCurrency} value={currency} className="absolute shadow-none inset-y-0 right-0 block border-none justify-center w-fit h-full ring-0 focus:ring-0 text-gray-500 group-focus-within:text-primary-500 text-right">
            <option value="dkk">DKK</option>
            <option value="usd">$</option>
            <option value="gbp">£</option>
            <option value="eur">€</option>
        </select>
    </div>
}
export default Currency;