import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import axiosClient from "../axiosClient";
import {BoldLabel} from "../components/form/Label";
import Select from "../components/form/Select";
import MultiSelect from "../components/form/MultiSelect";
import LabelInputPair from "../components/form/LabelInputPair";
import DatePicker from "../components/form/DatePicker";
import Submit from "../components/form/Submit";
import FadeIn from "../components/FadeIn";
 import { sendNotification } from "../components/Notification";


const Demographics = ({}) =>{

    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [campaignGroups, setCampaignGroups] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [error, setError] = useState();
    const [selectedLevel, setSelectedLevel] = useState("customer");
    const [selectedTimeGranularity, setSelectedTimeGranularity] = useState("ALL");
    const [selectedPivots, setSelectedPivots] = useState(["MEMBER_COMPANY"]);
    const [selectedFields, setSelectedFields] = useState(["clicks", "impressions", "conversions", "leads", "video_views"]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [sheet, setSheet] = useState();

    useEffect(()=>{
        axiosClient({
            method: "get",
            url: `/admin/customers`
        })
            .then((response) => {
                setError(null);
                
                setCustomers(response.data.filter(customer=>customer.account_li&&customer.channel_li&&customer.client_status==="Aktiv").sort((a, b)=>a.name>b.name?1:-1).map((customer)=>{
                    return {
                        name: customer.name,
                        id: `urn:li:sponsoredAccount:${customer.account_li}`
                    }
                }));
                
            })
            .catch(setError);

        
    },[])

    useEffect(()=>{
        setSelectedGroups([])
        if(!selectedCustomer){
            setCampaignGroups([]);
            return;
        }
        axiosClient({
            method:"get",
            url: `/demographics/campaignGroups?urn=${selectedCustomer}`
        }).then((response)=>{
            let groups = response.data.$values.filter(campaignGroup=>campaignGroup.name.startsWith("MM_")).map((campaignGroup)=>{
                    
                return {
                    name: campaignGroup.name,
                    id: `urn:li:sponsoredCampaignGroup:${campaignGroup.id}`
                }
            });
                setError(null);
                let url=`/demographics/campaigns?account=${selectedCustomer.match(/\d+/)}&urn=${groups.map(c=>c.id).join(',')}`;
                console.log(url)
                axiosClient({
                    method:"get",
                    url: url
                }).then((response)=>{
                        setError(null);
        
                        setCampaigns(response.data.$values.map((campaign)=>{
                            return {
                                name: campaign.name,
                                id: `urn:li:sponsoredCampaign:${campaign.id}`
                            }
                        }))
                    }).catch(setError);
                setCampaignGroups(groups)
            }).catch(setError);

            

    }, [selectedCustomer]);
    

    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
            console.log(error)
        }
    }, [error])

    const handleSubmit = (event) =>{
        event.preventDefault();
        let urns = [selectedCustomer];
        let search = "accounts";
        if(selectedGroups.length>0){
            urns=selectedGroups;
            search="campaignGroups"
        }
        if(selectedCampaigns.length>0){
            urns=selectedCampaigns;
            search="campaigns"
        }
       
        let body = {
            urns: urns,
            search:search,
            timeGranularity:selectedTimeGranularity,
            pivots:selectedPivots,
            fields:selectedFields,
            customer:selectedCustomer,
            level:selectedLevel
            
        }
        if(startDate){
            body.startDate=startDate;
        }
        if(endDate){
            body.endDate=endDate;
        }

        axiosClient({
            method:"post",
            url: `/demographics`,
            data: body
        }).then((response)=>{
                setError(null);

                if(!response.data.sheet){
                    setError(response.data);
                    return;
                }
                setSheet(response.data.sheet);
                window.open(response.data.sheet, '_blank').focus();


            }).catch(setError);
    }
    return <form onSubmit={handleSubmit}>
    <div className="px-6 py-4 mb-6 bg-white rounded-xl shadow">
        <div className="lg:grid grid-cols-3 gap-4">
            <LabelInputPair help="Vælg kunde" name="customer" label="Customer *">
                <Select onChange={setSelectedCustomer}>
                    {
                        customers.map((customer, index)=><option key={index} value={customer.id}>{customer.name}</option>)
                    }
                </Select>
            </LabelInputPair>
            <FadeIn visible={campaignGroups.length>0}>
            <LabelInputPair help="Vælg Kampagnegrupper (valgfrit)" label="Campaign Groups" name="campaignGroups">
                    <MultiSelect value={selectedGroups} onChange={setSelectedGroups}>
                        {
                            campaignGroups.map((campaignGroup, index)=><option key={index} value={campaignGroup.id}>{campaignGroup.name}</option>)
                        }
                    </MultiSelect>
                </LabelInputPair>
            </FadeIn>
            <FadeIn visible={campaigns.length>0}>
            <LabelInputPair help="Vælg Kampagner (valgfrit)" label="Campaigns" name="campaigns">
                    <MultiSelect value={selectedCampaigns} onChange={setSelectedCampaigns}>
                        {
                            campaigns.map((campaign, index)=><option key={index} value={campaign.id}>{campaign.name}</option>)
                        }
                    </MultiSelect>
                </LabelInputPair>
            </FadeIn>
        </div>
        
        

    </div>
    <FadeIn visible={selectedCustomer} className="px-6 py-4 mb-6 bg-white rounded-xl shadow">
            <div className="bg-white pb-4 border-b border-gray-200 mb-4"><h3 className="text-lg leading-6 font-medium text-gray-900">Options</h3></div>
        <div className="lg:grid grid-cols-3 gap-4">
            <LabelInputPair label="Level" name="level">
                <Select excludeDefault value={selectedLevel} onChange={setSelectedLevel}>
                    <option value="customer">Customer</option>
                    <option value="campaign_group">Campaign Group</option>
                    <option value="campaign">Campaign</option>
                </Select>
            </LabelInputPair>
            <LabelInputPair label="Interval" name="timeGranularity">
                <Select excludeDefault value={selectedTimeGranularity} onChange={setSelectedTimeGranularity}>
                    <option value="ALL">All</option>
                    <option value="DAILY">Daily</option>
                    <option value="MONTHLY">Monthly</option>
                    <option value="YEARLY">Yearly</option>
                </Select>
            </LabelInputPair>
            <LabelInputPair label="Pivots *" name="pivots">
                <MultiSelect value={selectedPivots} onChange={setSelectedPivots}>
                    <option value="MEMBER_COMPANY">Company</option>
                    <option value="MEMBER_COMPANY_SIZE">Company Size</option>
                    <option value="MEMBER_JOB_TITLE">Job Title</option>
                    <option value="MEMBER_COUNTRY_V2">Country</option>
                    <option value="MEMBER_INDUSTRY">Industry</option>
                </MultiSelect>
            </LabelInputPair>
            <LabelInputPair label="Fields *" name="fields">
                <MultiSelect value={selectedFields} onChange={setSelectedFields}>
                    <option value="clicks">Clicks</option>
                    <option value="impressions">Impressions</option>
                    <option value="conversions">Conversions</option>
                    <option value="leads">Leads</option>
                    <option value="video_views">Video Views</option>
                    <option value="video_views_25_percent">25% Video Views</option>
                    <option value="video_views_50_percent">50% Video Views</option>
                    <option value="video_views_75_percent">75% Video Views</option>
                    <option value="video_views_100_percent">100% Video Views</option>
                    <option value="document_views_25_percent">25% Document Views</option>
                    <option value="document_views_50_percent">50% Document Views</option>
                    <option value="document_views_75_percent">75% Document Views</option>
                    <option value="document_views_100_percent">100% Document Views</option>
                </MultiSelect>
            </LabelInputPair>
            <LabelInputPair help=" " label="Start Date" name="startDate">
                <DatePicker max={endDate} value={startDate} onChange={setStartDate}/>
            </LabelInputPair>
            <LabelInputPair label="End Date" name="endDate">
                <DatePicker min={startDate} value={endDate} onChange={setEndDate}/>
            </LabelInputPair>
        </div>
    </FadeIn>
    
    <div className="flex space-x-6">
        <FadeIn visible={selectedCustomer&&selectedPivots.length>0}>
            <Submit>
                Submit
            </Submit>
        </FadeIn>
    <FadeIn visible={sheet}>
    <a href={sheet} className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-green-600 hover:bg-green-500 focus:bg-green-700 focus:ring-offset-green-700">
            <span>
                Open sheet    
            </span>
        </a>
    </FadeIn>
    
    </div>
    
    </form>
}

export default Demographics;