import { useEffect, useState } from "react";
import { useParams, useNavigate  } from "react-router-dom"
import axiosClient from "../axiosClient";
import {Tab, TabLayout, GridLayout, EditBoolean, EditCard, EditColumn, EditFooter, EditGroup, EditLayout, EditList, EditSelect, EditTextField, EditCurrency, EditNumber, EditDate} from "../components/Edit";
import { EditDataLayout } from "./DataLayout";
import { sendNotification } from "../components/Notification";
import MessageBox from "../components/MessageBox";
import { CheckCircle } from "../components/Icons";
import useLoading from "../components/useLoading";
import ConfirmationButton from "../components/ConfirmationButton";


const ServiceEdit = ({})=>{
    let { id } = useParams();

    return <ServiceForm id={id} type="service" active="services"/>
}
const ServiceCreate = ({})=>{
    

    return <ServiceForm type="service" active="services"/>
}
const ServiceForm  = ({id, type, typeName, name, children, active})=>{
  
    // const [service, setService] = useState({});
    // const [error, setError] = useState(null);
    
    // const handleDelete = ()=>{};
    // useEffect(()=>{
    //     if(id){
    //         if(id){
    //             axiosClient({
    //                 method: "get",
    //                 url: `/admin/services/${id}`
    //             })
    //                 .then((response) => {
    //                     setError(null);
    //                     let data = response.data;
    //                     setService(data);
                        
                        
    //                 })
    //                 .catch(setError);
                
    //         }
    //     }
    // },[]);
    // return  <EditDataLayout onDelete={(handleDelete)} name={name??service?.name} title={type} active={active} create={!id}>
    //     <InnerServiceForm state={[service, setService]}/>
    // </EditDataLayout>
}

// const InnerServiceForm = ({state})=>{

//     const [service, setService] = state;
//     const [customers, setCustomers] = useState([]);
//     const [error, setError] = useState(null);
//     const [serviceTypes, setServiceTypes] = useState([]);
//     const [customFieldMeta, setCustomFieldMeta] = useState([]);
//     const [id, setId] = useState();

//     useEffect(()=>{
//         if(error){
//             sendNotification("danger", "Something went wrong");
//         }
//     }, [error]);

//     useEffect(()=>{
//         if(service?.service_type_id){
//             setId(service.id);
//             axiosClient({
//                 method: "GET",
//                 url: `/admin/services/customFields?service_type_id=${service.service_type_id}`
//             })
//                 .then((response) => {
//                     setError(null);
//                     setCustomFieldMeta(response.data??[]);
                    
//                 })
//                 .catch(setError);
//         }
//     }, [service])
//     useEffect(() => {
       
//         if(!typeName){
//             axiosClient({
//                 method: "get",
//                 url: `/admin/services/types?include=custom_fields`
//             })
//                 .then((response) => {
                    
//                     setServiceTypes(response.data);
//                 }).catch(setError);
//         }
        
//             axiosClient({
//                 method: "get",
//                 url: `/admin/customers`
//             })
//                 .then((response) => {
//                     setError(null);
                    
//                     setCustomers(response.data.sort((a, b)=>a.name>b.name?1:-1).map((as)=>{
//                         return {
//                             name: as.name,
//                             id: as.id
//                         }
//                     }));
                    
//                 })
//                 .catch(setError);
        
//     }, []);

    

   

//     let navigate = useNavigate(); 
    

//     const handleSubmit = (event) =>{

//         var value = {
            
//         };

//         if(!event.value){
//             return;
//         }
//         Object.entries(event.value).forEach(([key, entry])=>{
//             if(key.startsWith("customFields.")){
//                 value.customFields??={};

//                 value.customFields[key.substring(13)]=entry;

//             }
//             else if(key==="payment_interval"||key==="discount_type"){
//                 value[key]=parseInt(entry);
//             }
//             else{
//                 value[key]=entry;

//             }
//         });

//         if(value.customFields)  {
//             let cfs = {

//             }
//             Object.entries(value.customFields).forEach(([key,entry])=>{
//                 cfs[key]=entry?.toString();
//             });
//             value.customFields=cfs;
//         }
        
//         axiosClient({
//             method: id?"PUT":"POST",
//             url: `/admin/services${id?'/'+id:''}`,
//             data: value
//         })
//             .then((response) => {
//                 setError(null);
//                 sendNotification("success", "Saved");
//                 switch(event.action){
//                     case "another":
                        
                        
//                         setService({});
//                         window.location.reload(false);
//                         return navigate(`../${type.toLowerCase()}s/create`)
                        
//                     default:
//                         return navigate(`../${type.toLowerCase()}s/${response.data.id}/edit`)
//                 }
                
//             })
//             .catch(setError);

//     }

    

    
//     const selectServiceType = (value) =>{
//         var serviceType = serviceTypes.find(s=>s.id==value);
//         setCustomFieldMeta(serviceType?.custom_fields?.$values??[]);

//         let newService=service??{};

//         newService.price=serviceType.default_price;
//         newService.currency=serviceType.default_currency;
//         newService.interval=serviceType.default_interval;
//         newService.expenditure=serviceType.default_expenditure;

//         setService(newService);

        
//     }
//     const customField = (customFieldMeta) => {
//         if(!customFieldMeta||!customFieldMeta.visible){
//             return <></>;
//         }
//         let value = service?.customFields?.[customFieldMeta.name];
//         let name = `customFields.${customFieldMeta.name}`

//         if(customFieldMeta.is_readonly){
//             if(!id){
//                 return <></>;
//             }
//             return <EditTextField ignoreValue value={value} label={customFieldMeta.title} name={name} disabled/>;
//         }

//         switch(customFieldMeta.type){
//             case "INT":
//                 return <EditNumber value={value} label={customFieldMeta.title} name={name}/>
//             case "STRING":
//                 return <EditTextField value={value} label={customFieldMeta.title} name={name}/>
//             case "BOOL":
//                 return <EditBoolean value={value} label={customFieldMeta.title} name={name}/>
//             default:
//                 return <EditTextField ignoreValue value={value} label={customFieldMeta.title} name={name} disabled/>
//         }
//     }


//     return <EditLayout create={!id} onSubmit={handleSubmit} method={id?"PUT":"POST"}>
//         {id&&<input type="hidden" name="id" id="data.id" value={id}/>}
//     <EditColumn span="2">
//     <EditGroup key="group-1" label="General">
//             <EditCard columns="1">
//                 {
//                     (id||typeName)?<EditTextField value={typeName??serviceTypes.find(st=>st.id==service.service_type_id)?.name} label="Type" disabled/>:<EditSelect onChange={selectServiceType} label="Type" name="service_type_id">
//                         {serviceTypes.map((st, index)=><option key={index} value={st.id}>{st.name}</option>)}
//                     </EditSelect>
//                 }
//                 <EditSelect  label="Customer" name="customer_id" value={service?.customer_id}>
//                     {
//                         customers&&customers.map((c, index)=><option key={index} value={c.id}>{c.name}</option>)
//                     }
//                 </EditSelect>
//                 <GridLayout columns="1" columnsLg="2">
//                 <EditCurrency  key="text-price"  label="Price" amountName="price" amountValue={service?.price} currencyName="currency" currencyValue={service?.currency}/>
//                 <EditSelect defa key="select-billing-interval" value={service?.payment_interval} label="Billing interval" name="payment_interval" defaultValue="1">
//                     <option value="12">Yearly</option>
//                     <option value="3">Quarterly</option>
//                     <option value="1">Monthly</option>
//                 </EditSelect>
//                 <EditNumber value={service?.discount} name="discount" label="Discount" />
//                 <EditSelect key="select-discount-type" value={service?.discount_type} label="Discount type" name="discount_type" defaultValue="0">
//                     <option value="0">Percentage</option>
//                     <option value="1">Fixed</option>
//                 </EditSelect>
//                 </GridLayout>
//                 <EditNumber value={service?.expenditure} name="expenditure" label="Expenditure"/>
//                 <EditTextField value={service?.description} name="description" label="Description"/>
//                 <GridLayout key="editdates" columns="1" columnsLg="2">
//                     <EditDate value={service?.start_date} name="start_date" label="Start date" key="editstartdate"/>
//                     <EditDate value={service?.end_date} name="end_date" label="End date" key="editlastdate"/>
//                 </GridLayout>

//             </EditCard>
//         </EditGroup>
//         {
//             customFieldMeta.length>0&&<EditGroup label="Custom Fields">
//             <EditCard columns="1">
//                 {
//                     customFieldMeta.map(customField)
//                 }
//             </EditCard>
//         </EditGroup>
//         }
//         {children}
        
       
       
        
//     </EditColumn>
//     <EditColumn span="1">
//         <EditGroup key="group-6" label="Status">
//             <EditCard>
//                 <EditSelect name="status" value={service?.status} key="select-status" label="Status" defaultValue="Aktiv">
//                     <option value="Aktiv">Aktiv</option>
//                     <option value="Pause">Pause</option>
//                     <option value="Ophørt">Ophørt</option>
//                 </EditSelect>
//             </EditCard>
//         </EditGroup>
//     </EditColumn>
// </EditLayout>;
// }
export default ServiceForm;
export {ServiceEdit, ServiceCreate};