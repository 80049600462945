import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import LabelInputPair from "../components/form/LabelInputPair";
import Select from "../components/form/Select";
import Switch, {SwitchLabel} from "../components/form/Switch";
import Submit from "../components/form/Submit";
import FadeIn from "../components/FadeIn";
import { sendNotification } from "../components/Notification";

const client = (options, oldVersion) => axios.create({
    baseURL:oldVersion?"https://wp-network.mmbit.dk/wp-json/v1/wp-remote/":"https://sites.mindmill.io/wp-json/v1/wp-remote/",
    params:{
        secret:"mindmillion"
    }
})(options);

const WordpressClone = () => {
    const [sites, setSites] = useState([]);
    const [siteId, setSiteId] = useState("1");
    const [newSiteId, setNewSiteId] = useState();
    const [copyPosts, setCopyPosts] = useState(true);
    const [copyForms, setCopyForms] = useState(true);
    const [deletePosts, setDeletePosts] = useState(false);
    const [error, setError] = useState(null);
    const [oldVersion, setOldVersion] = useState(false);


    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
        }
    }, [error])

   useEffect(()=>{
    client({
        url:"get_all_sites"
    }, oldVersion).then(response=>{
                    setError(null);
        setSites(response.data.sort((a, b) => a>b?1:-1).map(site=>{
            return {
                domain: site.domain,
                id: site.blog_id
            }
        }));
        setSiteId("1");
        setNewSiteId(null);
    }).catch(setError)
   }, [oldVersion]); 

   const handleSubmit = (event) => {
       event.preventDefault();
    let params = {
        template_site_id: siteId,
        new_site_id: newSiteId,
        
    }
    if(copyPosts){
        params["add_posts_from_template"]=true;
    }
    if(copyForms){
        params["create_forms_from_template"]=true;
    }
    if(deletePosts){
        params["overwrite_posts"]=true;
    }
     client({
        method:"post",
        url:"clone_site",
        params:params
     }, oldVersion).then(response=>{
        setError(null);
        sendNotification("success", "Template site cloned");

     }).catch(setError)
   }

   return <form  onSubmit={handleSubmit}>
    <div className="px-6 py-4 mb-6 bg-white  rounded-xl shadow space-y-6">
    <SwitchLabel help="Use old version" name="add_posts_from_template" label="Old Version">
           <Switch value={oldVersion} onChange={setOldVersion}/>
       </SwitchLabel>
   <LabelInputPair  name="template_site_id" label="Template Site *">
           <Select value={siteId} onChange={setSiteId}>
               {
                   sites.map((site, index)=><option key={index} value={site.id}>{site.domain}</option>)
               }
           </Select>
       </LabelInputPair>
       <LabelInputPair name="new_site_id" label="New Site *">
           <Select value={newSiteId} onChange={setNewSiteId}>
               {
                   sites.map((site, index)=><option key={index} value={site.id}>{site.domain}</option>)
               }
           </Select>
       </LabelInputPair>
       
       <SwitchLabel help="Add Posts From Template." name="add_posts_from_template" label="Copy Posts">
           <Switch value={copyPosts} onChange={setCopyPosts}/>
       </SwitchLabel>
       <SwitchLabel help="Add Forms From Template." name="create_forms_from_template" label="Copy Forms">
           <Switch value={copyForms} onChange={setCopyForms}/>
       </SwitchLabel>
       {/* <SwitchLabel help="Overwrite Posts On New Site." name="overwrite_posts" label="Delete Posts">
           <Switch value={deletePosts} onChange={setDeletePosts}/>
       </SwitchLabel> */}
       
   
</div>
<FadeIn visible={siteId&&newSiteId} time={150}>
<Submit>Clone Site</Submit>
</FadeIn>
  
   </form>
}

export default WordpressClone;