


import React from "react"

function color(value){
    if(!value){
        return "bg-gray-300";
    }
    // else if(!this.props.account){
    //     return "bg-yellow-300"
    // }
    else{
        return "bg-green-300";
    }
    
}

const PlatformStatus = (props) =>{
    return <div className={"w-6 h-6 flex flex-col justify-center items-center text-gray-100 text-xs rounded-full " + color(props.active)}>
    {props.platform}
</div>
}

// class PlatformStatus extends React.Component {

//     constructor(props) {
//         super(props)
//     }

//     render() {

        
//     }

    
// }

export default PlatformStatus;