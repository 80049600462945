import React, { useEffect, useState } from "react";
import Label from "./form/Label";
import MultiSelect from "./form/MultiSelect";
import Select from "./form/Select";
import Popup from "./Popup";
import axiosClient from "../axiosClient";

const Icon = ({selectedFilters}) => {
    return <>
    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"></path>
    </svg>
    {
        selectedFilters>0&&<div style={{insetInlineStart:"100%"}} className="absolute top-0 z-[1] -ms-1 -translate-x-1/2 rounded-md rtl:translate-x-1/2 ">
            <div className=" flex items-center justify-center gap-x-1 whitespace-nowrap rounded-md  text-xs font-medium ring-1 ring-inset px-0.5 min-w-[theme(spacing.4)] tracking-tighter bg-primary-50 text-primary-600 ring-primary-600/10 ">
                {selectedFilters}
            </div>

        </div>
    }
    </>
}



class Filter extends React.Component {

    constructor(props) {
        super(props);

        
        this.state = {
            shouldUpdate: false,
            values: {

            }
        }

    }
    
    getCurrentPathName = () =>document.location.pathname;
    

    componentDidMount(){
        var state = {

        }

        const pathname = this.getCurrentPathName();

        this.props.filters.forEach(element => {
            var jsonValue = sessionStorage.getItem(`filters.${pathname}.${element.name}`);
            if(!jsonValue){
                jsonValue=JSON.stringify(element.defaultValue);
                sessionStorage.setItem(`filters.${pathname}.${element.name}`, jsonValue);
                state[element.name]=  element.defaultValue;
                
            }
            else{
                state[element.name]=  JSON.parse(jsonValue);

            }
            
        });

        this.setState({
            values: state,
            shouldUpdate: true
        });

    }
    componentDidUpdate(){
        if(this.state.shouldUpdate){
            this.setState({shouldUpdate: false});
            this.props.onChange(this.state.values);

        }
    }

    resetFilters = () => {
        var state = {

        }
        const pathname = this.getCurrentPathName();

        this.props.filters.forEach(element => {
            state[element.name]=element.defaultValue;
            sessionStorage.setItem(`filters.${pathname}.${element.name}`, JSON.stringify(element.defaultValue));

        });

        this.setState({
            values: state,
            shouldUpdate: true
        });
    }

    Control = ({name, label, element})=>{
        const setValue = (value) =>{
            const pathname = this.getCurrentPathName();
            let newState = this.state.values;

            newState[name]=value;
            this.setState({
                shouldUpdate: true,
                values: newState 
            });

            sessionStorage.setItem(`filters.${pathname}.${name}`, JSON.stringify(value));

        }

        return <div className="col-span-1">
        <div className="grid gap-6 grid-cols-1">
            <div className="col-span-1 ">
                <div>
                    <div className="space-y-2">
                        <Label htmlFor={`filter.${name}`}>
                            {label}
                        </Label>
                        {
                            React.cloneElement(element, {
                                name: `filter.${name}`,
                                onChange:setValue,
                                value: this.state.values[name]
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    getSelectedFilters = () =>Object.values(this.state.values).filter(element => {
        if(Array.isArray(element)){
            return element.length>0;
        } else{
            return element;
        }
    }).length;
    render(){
        return <Popup icon={<Icon selectedFilters={this.getSelectedFilters()} />} align="left" className="right-0 rtl:right-auto rtl:left-0 z-10 w-screen pl-12 rtl:pr-12 top-full max-w-sm">
        <div className="px-6 py-4 bg-white space-y-6 shadow-xl rounded-xl">
            <div className="grid gap-6 grid-cols-1 lg:grid-cols-1">
                {
                    this.props.filters.map((filter, index)=><React.Fragment key={index}>{this.Control(filter)}</React.Fragment>)
                }
                
            </div>
            <div className="text-right">
                <button type="button" className="hover:underline focus:outline-none focus:underline  text-danger-600 hover:text-danger-500 text-sm font-medium" onClick={this.resetFilters}>
        Reset filters
    </button>
            </div>
        </div>
    </Popup>
    }
}




export default Filter;