import { useEffect, useState } from "react";
import { useParams, useNavigate  } from "react-router-dom"
import axiosClient from "../axiosClient";
import {Tab, TabLayout, GridLayout, EditBoolean, EditCard, EditColumn, EditFooter, EditGroup, EditLayout, EditList, EditSelect, EditTextField, EditCurrency} from "../components/Edit";
import { EditDataLayout } from "./DataLayout";
import { sendNotification } from "../components/Notification";
import MessageBox from "../components/MessageBox";
import { CheckCircle } from "../components/Icons";
import useLoading from "../components/useLoading";
import ConfirmationButton from "../components/ConfirmationButton";
import Label from "../components/form/Label";
import { LabelInputPairRegular } from "../components/form/LabelInputPair";
import Text from "../components/form/Text";

const CustomerUserEdit = () => {
    let { customer,id } = useParams();

    return <UserForm id={id} customer={customer} active="customers" />

}
const CustomerUserCreate = () => {
    let { customer } = useParams();
    return <UserForm customer={customer} active="customers" />

}
const UserForm  = ({id, customer, active})=>{
  
    const name= "User";
    const [user, setUser] = useState({});
    const [customerName, setCustomerName] = useState();
    const [error, setError] = useState(null);

    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
        }
    }, [error])
    useEffect(() => {
        if(id){
            axiosClient({
                method: "get",
                url: `/admin/customers/${customer}/users/${id}`
            })
                .then((response) => {
                    setError(null);
                    let data = response.data;
                    setUser(data);
                    
                })
                .catch(setError);
            
        }
        if(customer){
            axiosClient({
                method: "get",
                url: `/admin/customers/${customer}`
            })
                .then((response) => {
                    setError(null);
                    let data = response.data;
                    setCustomerName(data.name);
                    
                })
                .catch(setError);
            
        }
            
        
    }, []);

   

    let navigate = useNavigate(); 
    

    const handleSubmit =async (event) =>{

        var value = event.value;

        if(!event.value){
            return;
        }
        
        axiosClient({
            method: id?"PUT":"POST",
            url: `/admin/customers/${customer}/users`,
            data: value
        })
            .then((response) => {
                setError(null);
                sendNotification("success", "Saved");
                switch(event.action){
                    case "another":
                        
                        
                        setUser({});
                        window.location.reload(false);
                        return navigate(`../customers/${customer}/users/create`)
                        
                    default:
                        return navigate(`../customers/${customer}/edit`)
                }
                
            })
            .catch(setError);

    }

    

    const handleDelete = (callback) =>{
        if(!id){
            return;
        }

        axiosClient({
            method: "DELETE",
            url: `/admin/users/${id}`
        })
            .then((response) => {
                setError(null);
                callback();
                return navigate(`../customers/${customer}/edit`)
                
                
            })
            .catch(setError);
    }

    return  <>
    <EditDataLayout onDelete={handleDelete} name={name} title={"User"} active={active} create={!id}>
        <EditLayout create={!id} onSubmit={handleSubmit} method={id?"PUT":"POST"}>
        {id&&<input type="hidden" data-type="int" name="id" id="data.id" value={id}/>}
    <EditColumn span="2">
        <EditGroup key="group-1" label="User">
            <EditCard columns="1">
                <EditTextField label="Name" name="name" value={user?.name}/>
                <EditTextField type="email" disabled={id} label="Email" name="email" value={user?.email}/>
            </EditCard>
        </EditGroup>
        <EditGroup key="group-2" label="Kundeadgang">
            <EditCard columns="1">
                <EditBoolean label="mindmill.io" name="access_platform" ignoreValue value={true} disabled/>
                <EditBoolean label="Dashboard" name="access_dashboard" value={user?.access_dashboard??true}/>
            </EditCard>
        </EditGroup>
        
        
       
       
        
    </EditColumn>
    <EditColumn>
    <EditGroup label="Info">
        {
        customerName&&<span className="text-gray-600">
            Brugeren vil få adgang til <span className="text-black">{customerName}</span> på <a className="underline" href="https://app.mindmill.io/" target="_blank">mindmill.io</a>
        </span>
    }
        </EditGroup>
    </EditColumn>
</EditLayout>
    </EditDataLayout>
    </>

}


export  {CustomerUserEdit, CustomerUserCreate};