import { useEffect, useState } from "react";
import { useParams, useNavigate  } from "react-router-dom"
import axiosClient from "../axiosClient";
import {Tab, TabLayout, GridLayout, EditBoolean, EditCard, EditColumn, EditFooter, EditGroup, EditLayout, EditList, EditSelect, EditTextField} from "../components/Edit";
import { EditDataLayout } from "./DataLayout";
import { sendNotification } from "../components/Notification";
import MessageBox from "../components/MessageBox";
import Currency from "../components/form/Currency";
import Label from "../components/form/Label";
import Select from "../components/form/Select";
import DatePicker from "../components/form/DatePicker";
import Popup from "../components/Popup";
import Text from "../components/form/Text";
import List from "../components/form/List";
import CustomerLayout from "../components/CustomerNavigation";

const CustomerEdit = () => {
    let { id } = useParams();

    

    return <CustomerForm id={id}/>
}
const CustomerCreate = () => {
    return <CustomerForm />
}
const CustomerForm = ({id})=>{
  
    const [customer, setCustomer] = useState({});
    const [podioCustomers, setPodioCustomers] = useState([]);
    const [error, setError] = useState(null);


    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
        }
    }, [error])
    useEffect(() => {
        if(id){
            
            
        }
           
                axiosClient({
                    method: "get",
                    url: `/podio/customers`
                })
                    .then((response) => {
                        setError(null);
                        
                        setPodioCustomers(response.data.sort((a, b)=>a.kunde>b.kunde?1:-1).map((as)=>{
                            return {
                                name: as.kunde,
                                id: as.item_id
                            }
                        }));
                        
                    })
                    .catch(setError);
        
    }, []);

   

    let navigate = useNavigate(); 
    

    const handleSubmit = (event) =>{
        
        axiosClient({
            method: customer.id?"PUT":"POST",
            url: `/admin/customers`,
            data: event.value
        })
            .then((response) => {
                setError(null);
                sendNotification("success", "Saved");
                switch(event.action){
                    case "another":
                        
                        
                        setCustomer({});
                        window.location.reload(false);
                        return navigate(`../customers/create`)
                        
                    default:
                        return navigate(`../customers/${response.data.id}/edit`)
                }
                
            })
            .catch(setError);

            return customer.id;
    }


    

    return  <CustomerLayout active="Edit" customerState={[customer, setCustomer]}>
            <EditLayout create={!id} onSubmit={handleSubmit} method={id?"PUT":"POST"} footer={<></>}>
        {id&&<input type="hidden" name="id" id="data.id" value={id}/>}
    <EditColumn span="2">
        <EditGroup key="group-1" label="Generelt">
            <EditCard columns="2">
                
                <EditTextField key="text-name" value={customer.name} required label="Name" name="name" placeholder="" />
                <EditTextField key="text-cliid" value={customer.client_id} disabled label="Client Id" name="client_id" ignoreValue />
                <EditTextField key="text-cvr" value={customer.cvr} label="CVR" name="cvr" />
            </EditCard>
        </EditGroup>
        <EditGroup key="group-2" label="Annnoncering">
            <GridLayout columns="1" columnsLg="1">
                <EditTextField  label="Ad specialist user" name="podio_ad_specialist" value={customer.podio_ad_specialist} ignoreValue disabled/>
                   
                <TabLayout>
                    <Tab id="facebook" label="Facebook">
                        <EditTextField  key="text-ac_fb" label="Account ID" name="account_fb" value={customer.account_fb} />
                        <EditBoolean label="Active" name="channel_fb" key="bool-channel_fb" value={customer.channel_fb} />
                    </Tab>
                    <Tab id="linkedin" label="LinkedIn">
                        <EditTextField key="text-ac_li" label="Account ID" name="account_li" value={customer.account_li} />
                        <EditBoolean label="Active" name="channel_li" key="bool-channel_li" value={customer.channel_li} />
                    </Tab>
                    <Tab id="google" label="Google (Ads + Display + YouTube)">
                        <EditTextField key="text-ac_g" label="Google Account ID" name="account_ga" value={customer.account_ga} />
                        <EditBoolean label="Google Ads Active" name="channel_ga" key="bool-channel_ga" value={customer.channel_ga} />
                        <EditBoolean label="Google Display Active" name="channel_gd" key="bool-channel_gd" value={customer.channel_gd} />
                        <EditBoolean label="Youtube Active" name="channel_yt" key="bool-channel_yt" value={customer.channel_yt} />
                    </Tab>
                    <Tab id="twitter" label="Twitter">
                        <EditTextField key="text-ac_tw" label="Account ID" name="account_tw" value={customer.account_tw} />
                        <EditBoolean label="Active" name="channel_tw" key="bool-channel_tw" value={customer.channel_tw} />
                    </Tab>
                    
                    
                </TabLayout>
            </GridLayout>
        </EditGroup>
        <EditGroup key="group-3" label="Rapportering">
            <EditCard  columns="1">
                <EditTextField key="text-dashboard" label="Dashboard URL" name="dashboard_url" value={customer.dashboard_url} />
                <EditTextField key="text-report" label="Report URL" name="report_url" value={customer.report_url} />
                <EditTextField key="text-calls" label="Calls URL" name="dashboard_calls_url" value={customer.dashboard_calls_url} />
            </EditCard>
        </EditGroup>
        <EditGroup key="group-4" label="Integrationer">
            <GridLayout columns="1">
                <EditCard columns="1">
                    <EditBoolean name="active" key="bool-active" value={customer.active} label="Active" />
                </EditCard>
                <TabLayout>
                    <Tab id="active_campaign" label="Active Campaign">
                        <EditTextField key="text-ac_url" name="ac_api_url" value={customer.ac_api_url} label="Active Campaign API URL" />
                        <EditTextField key="text-ac_key"  name="ac_api_key" value={customer.ac_api_key} label="Active Campaign API KEY" />
                    </Tab>
                    <Tab id="datamill" label="Datamill">
                        <EditSelect value={customer.big_query_source_type} key="select-bq_source" name="big_query_source_type" label="BigQuery Source Type">
                            <option value="google_sheet">Google Sheet</option>
                            <option value="direct_ac_feed">DataMill Sync v1 (Direct AC Feed)</option>
                            <option value="datamill_sync_v2">DataMill Sync v2 (.NET)</option>
                        </EditSelect>
                        <EditTextField value={customer.big_query_table_name} key="text-bq_table" name="big_query_table_name" label="BigQuery Table Name" />
                        <EditBoolean key="bool-dev_active" value={customer.is_active_in_developer_feed??true} name="is_active_in_developer_feed" label="Is active in developer feed"/>
                    </Tab>
                    <Tab id="analytics" label="Google Analytics 4">
                        <EditTextField key="text-ac_ga4" label="Property Id" name="account_ga4" value={customer.account_ga4} />
                    </Tab>
                    <Tab id="podio" label="Podio">
                    <EditSelect  label="Podio Customer" name="podio_id" value={customer.podio_id}>
                    {
                        podioCustomers.map((as, index)=><option key={index} value={as.id}>{as.name}</option>)
                    }
                        </EditSelect>
                    </Tab>
                    <Tab id="adversus" label="Adversus">
                        <EditList value={customer.adversus_id} key="list-adversus" name="adversus_id" label="Adversus project ID's" placeholder=""/>
                    </Tab>
                    <Tab id="slack" label="Slack">
                        <EditTextField value={customer.slack_id} key="slack-id" name="slack_id" label="Slack channel id" placeholder=""/>
                    </Tab>
                    <Tab id="economics" label="Economics">
                        <EditTextField value={customer.economics_mindmill} key="economics-mindmill" name="economics_mindmill" label="Mindmill" placeholder=""/>
                        <EditTextField value={customer.economics_fgt} key="economics-fgt" name="economics_fgt" label="FGT" placeholder=""/>
                    </Tab>
                </TabLayout>
            </GridLayout>
        </EditGroup>
        <EditGroup key="group-5" label="Data">
            <EditCard columns="1">
                <div className="space-y-2">
                <EditList value={customer.email_domains_to_exclude_from_dataset} key="list-domains" name="email_domains_to_exclude_from_dataset" label="Email domains to exclude from dataset" placeholder="domain / email"/>
                <div className="text-sm text-gray-600">
                    <p>Add a domain (example.com) or a full email address (<a href="mailto:mail@example.com">mail@example.com</a>)</p>
                </div>
                </div>
            </EditCard>
        </EditGroup>
        
    </EditColumn>
    <EditColumn span="1">
        <EditGroup key="group-6" label="Status">
            <EditCard>
                <EditTextField  label="Client status" name="client_status" value={customer.client_status} ignoreValue disabled/>
                
            </EditCard>
            <EditFooter create={!id} className="w-full items-stretch flex flex-col space-y-3 mt-6"/>

        </EditGroup>
    </EditColumn>
            </EditLayout>
        </CustomerLayout>

        
}










export {CustomerCreate};

export default CustomerEdit;