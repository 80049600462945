import { useEffect } from "react";
import useData from "../hooks/useData";
import { useState } from "react";
import LabelInputPair from "../components/form/LabelInputPair";
import Select from "../components/form/Select";
import axiosClient from "../axiosClient";
import { sendNotification } from "../components/Notification";
import FadeIn from "../components/FadeIn";

const ActiveCampaignStagesData = () => {
    const transformItems = (items)=>{
        items=items.filter(item=>item.ac_api_key&&item.ac_api_url);

        items = items.map(item=>{
            return {
                name: item.name,
                id: item.id
            };
        });
        items=items.sort((i1, i2)=>i1.name>i2.name?1:-1);

        return items;
    }
    const [customers,,] = useData([], "admin/customers", false,transformItems);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [stages, setStages] = useState([]);
    const [error, setError] = useState(null);

    
    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
            console.log(error);
        }
    }, [error])

    useEffect(()=>{
        if(selectedCustomer){
            axiosClient({
                url:`activecampaign/stages/${selectedCustomer}`
            }).then(response=>{
                setError(null);
                var fields = response.data.$values;
                setStages(fields);
            })
            .catch(setError);

        }
    },[selectedCustomer])

    let mapStages = (stages) => {
        let total = {
            leads:0,
            mql:0,
            sql:0,
        };
        return <>
        {
            stages.map((stage,index)=>{
                total.leads+=stage.leads;
                total.mql+=stage.mql;
                total.sql+=stage.sql;
                return <tr className="bg-white" key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stage.monthYear}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stage.leads}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stage.mql}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stage.sql}</td>
                </tr>
            })
        }
        <tr class="bg-gray-100">
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                Total
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {total.leads}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {total.mql}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {total.sql}
            </td>
        </tr>
        </>
    }
    

    return <>
    <div className="px-6 py-4 mb-6 bg-white rounded-xl shadow">
        <div className="lg:grid grid-cols-3 gap-4">
        <LabelInputPair help="Vælg kunde" name="customer" label="Customer">
                <Select onChange={setSelectedCustomer}>
                    {
                        customers.map((customer, index)=><option key={index} value={customer.id}>{customer.name}</option>)
                    }
                </Select>
            </LabelInputPair>
        </div>
        </div>
        <FadeIn visible={stages?stages[0]:false} className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full ">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Month/Year
                                            </th>
                                            <th scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Leads
                                            </th>
                                            <th scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                MQL
                                            </th>
                                            <th scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                SQL
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {
                                                mapStages(stages)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    
            </FadeIn></>;
}
export default ActiveCampaignStagesData;