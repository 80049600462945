const bytesToString = (bytes)=>{
    if(bytes>1125899906842624){
        return `${parseInt(bytes/1125899906842624)} PB`;
    }
    if(bytes>1099511627776){
        return `${parseInt(bytes/1099511627776)} TB`;
    }
    if(bytes>1073741824){
        return `${parseInt(bytes/1073741824)} GB`;
    }
    if(bytes>1048576){
        return `${parseInt(bytes/1048576)} MB`;
    }
    if(bytes>1024){
        return `${parseInt(bytes/1024)} KB`;
    }
    return `${bytes} B`
}
export default bytesToString;