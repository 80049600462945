import { useEffect, useState } from "react";

const Text = ({onChange, value, name, disabled, secret, id})=>{


    const [text, setText] = useState();

    useEffect(()=>{
        setText(value);
        
    }, [])

    useEffect(()=>{
        if(onChange){
            onChange(text);
        }
    }, [text])


    return <input disabled={disabled} id={id} name={name} value={text} type={secret?"password":"text"} className="text-gray-900 block w-full transition duration-75 rounded-lg shadow-sm focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600 disabled:opacity-70  border-gray-300 data-[value=false]:text-gray-600" data-value={text?true:false} onChange={(e)=>setText(e.target.value)}/>
}
export default Text;