import { useState } from "react";

function useLoading(initialState){
    const [state, setState] = useState({"default": initialState})

    function setLoading(id, value){
        state[id]=value;
        state["default"]=false;
        setState(state);
    }

    function isLoading(){
        return !Object.entries(state).every(e=>!e[1]);
    }

    return [isLoading(), state, setLoading];
}

export default useLoading;