import NamingGeneratorBase, { NamingGeneratorSelect, NamingGeneratorTextField } from "./NamingGeneratorBase";

const getResult = (values)=>{
    return `${values.conversion_type?"MM_"+values.conversion_type:""} | ${values.conversion_name??""} | ${values.language??""}`
}
const getValidation = (values)=>{
    return <>
    {values.conversion_type?
    <span className="text-success-600" >{`MM_${values.conversion_type}`}</span>
:<span className="text-danger-600" >{`{type}`}</span>} | {validate(values, "conversion_name")} | {validate(values, "language")}
    </>
}
function validate(values, field, optional = false){
    if(values[field]){
        return <span className="text-success-600" >{`${values[field]}`}</span>;
    } else {
        return <span className={optional?"text-warning-600":"text-danger-600"} >{`{${field}}`}</span>;
    }
}
const ConversionTracking = ()=>{

    
    return <NamingGeneratorBase title="Sales LI Outreach" getResult={getResult} getValidation={getValidation}>
        <NamingGeneratorSelect name="conversion_type" label="Conversion type" options={[
            "Form",
            "Click",
            "Page visit",
            "Purchase"
        ]}/>
        <NamingGeneratorTextField name="conversion_name" label="Conversion name" placeholder="Contact us"/>
        <NamingGeneratorTextField name="language" label="Language" placeholder="DA"/>
        
    </NamingGeneratorBase>
}

export default ConversionTracking;