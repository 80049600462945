import { EditCard, EditColumn, EditDate, EditFooter, EditGroup, EditSelect, EditTextField, GridLayout } from "../../components/Edit";
import LinkIcon from "../../components/form/Link";
import { ServiceStatusEdit } from "../ServiceEditNew";
import DefaultService from "./DefaultService";

const ActiveCampaign = ({state,children})=>{
    const [service, setService] = state;

    return <div className="grid gap-6 grid-cols-1 lg:grid-cols-3">
                

    <EditColumn span="2">
    {children}
    <EditGroup label="Account">
        <EditCard>
            <EditTextField label="URL" disabled value={`${service?.name}.activehosted.com`}/>
            <a target="_blank" className="hover:bg-primary-600 focus:text-white hover:border-transparent hover:text-white grid items-center group justify-center font-medium tracking-tight rounded-lg border transition-colors   h-9 px-4 text-gray-800 bg-transparent border-gray-300  focus:bg-primary-700 focus:ring-offset-primary-700 focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset  focus:ring-white" href={`https://www.activecampaign.com/partner/change.php?account=${service?.name}.activehosted.com`} >
                <span>Edit billing</span>
                <span className=" justify-self-end absolute text-gray-500 group-hover:text-inherit group-focus:text-inherit"><LinkIcon className="h-5 w-5"/></span>
            </a>
        </EditCard>
    </EditGroup>
    <DefaultService disabled state={state} typeName="ActiveCampaign"/>

    </EditColumn>

    <EditColumn span="1">
    <ServiceStatusEdit disabled serviceStatus={service?.status} serviceEndDate={service?.end_date} serviceStartDate={service?.start_date} messageBoxDisplay={({customerName,endDate})=><div>
                    
                    Please confirm that {customerName} <span className="font-bold">should not get an invoice</span> for ({service?.name})  by the end of {new Date(Date.parse( endDate??new Date())).toLocaleString("en-UK", {year:"numeric",month:"long"})}.
                    </div>} />
            
    </EditColumn>
    
    </div>;
}

export default ActiveCampaign;