import { useEffect, useState } from "react";
import { EditCard } from "../components/Edit";
import Text from "../components/form/Text";
import LabelInputPair from "../components/form/LabelInputPair";
import Select from "../components/form/Select";
import sha256Base64 from "../scripts/sha256Base64";

const UserGenerator = ({})=>{
    const [result, setResult] = useState("");
    const [values, setValues] = useState({})

  

    const copyToClipboard=()=>{
        navigator.clipboard.writeText(result);
    }

    const hashPassword= async ()=>{
        
        return await sha256Base64(`${values.email}${values.password}`);
    }

    const changeValue = (key,value)=>{
        let newValues = values;
        newValues[key]=value;
        setValues(newValues);

        hashPassword().then(value=>{
            let insertStatement = `INSERT INTO datamill_console.Users VALUES(null,'${values.name}', '${values.email}', null, CURRENT_TIMESTAMP(), CURRENT_TIMESTAMP(),CURRENT_TIMESTAMP(), 'AuthUser', '${value}', '${values.claims}', null)`;

            setResult(insertStatement)
        })

        
    }

    return <EditCard>
        <div className="grid grid-cols-3 gap-6">
        <LabelInputPair label="Full name" name="name" >
            <Text onChange={e=>changeValue("name", e)}/>
        </LabelInputPair>
        <LabelInputPair label="Email" name="email" >
            <Text onChange={e=>changeValue("email", e)}/>
        </LabelInputPair>
        <LabelInputPair label="Password" name="password" >
            <Text onChange={e=>changeValue("password", e)}/>
        </LabelInputPair>
        <LabelInputPair label="Claims" name="claims" >
            <Select onChange={e=>changeValue("claims", e)}>
                <option value="phoner">Phoner</option>
                <option value="employee">Employee</option>
            </Select>
        </LabelInputPair>
        </div>

        <div className=" py-2 px-3 text-gray-900 border-2 flex justify-between w-full transition duration-75 rounded-lg shadow-sm border-gray-300 ">
            <span className=" overflow-ellipsis overflow-hidden">{result}</span>
            <button onClick={copyToClipboard}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
                                    </svg>
                                </button>
        </div>
        

    </EditCard>


}

export default UserGenerator;