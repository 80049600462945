import { useEffect, useState } from "react";
import { DefaultServiceLayout } from "./DefaultService";
import { EditCard, EditGroup, EditTextField } from "../../components/Edit";

const Dripify = ({state,children})=>{

    const [customDescription, setCustomDescription] = useState(false);
    const [name, setName] = useState();
    const [service,setService]=state;

    

    const handleChange=(newName)=>{
        if(!customDescription){
            let newService = service??{};

            newService.description = `Dripify - 1 x Abonnement (Advanced) - ${newName}`;
            newService.name = newName;
            setService(newService);

        }
        setName (newName);
    };

    return <DefaultServiceLayout typeName="Dripify" state={state}>
        {children}
        <EditGroup label="Dripify">
            <EditCard>
                <EditTextField required key="edituserid" label="User (Email)" type="email" name="name" placeholder="user@mail.dk" onChange={handleChange} disabled={service?.id} value={service?.name}/>
            </EditCard>
        </EditGroup>
    </DefaultServiceLayout>
}

export default Dripify;