import { useEffect, useState } from "react"
import "./PrivacyPolicyGenerator.css"
import LabelInputPair from "../components/form/LabelInputPair";
import Text from "../components/form/Text";
import { Link, useSearchParams } from "react-router-dom";

const PrivacyPolicyView = () => {
    const [searchParams, ] = useSearchParams();

    return <div className="m-2 ">
        <PrivacyPolicy    companyName={searchParams.get("companyName")}
                          domain={searchParams.get("domain")}
                          address={searchParams.get("address")}
                          postcode={searchParams.get("postcode")}
                          city={searchParams.get("city")}
                          phone={searchParams.get("phone")}
                          email={searchParams.get("email")}
                          cvr={searchParams.get("cvr")}/>
    </div>
}

const PrivacyPolicyGenerator = () => {
    const [companyName, setCompanyName] = useState("Viking Rubber Company A/S");
    const [domain, setDomain] = useState("viking-rubber.com");
    const [address, setAddress] = useState("Johan Rantzaus Vej 6");
    const [postcode, setPostcode] = useState("5600");
    const [city, setCity] = useState("Faaborg");
    const [phone, setPhone] = useState("+45 6261 8098");
    const [email, setEmail] = useState("sales@viking-rubber.dk");
    const [cvr, setCvr] = useState("43929615");

    return <>
    <div className="bg-white px-6 py-4 mb-6 rounded-xl shadow">
        <div className="lg:grid grid-cols-3 gap-4 mb-6 ">
            <LabelInputPair name="companyName" label="Company name">
                <Text onChange={setCompanyName} value={companyName} />
            </LabelInputPair>
            <LabelInputPair name="domain" label="Domain">
                <Text onChange={setDomain} value={domain} />
            </LabelInputPair>
            <LabelInputPair name="address" label="Address">
                <Text onChange={setAddress} value={address} />
            </LabelInputPair>
            <LabelInputPair name="postcode" label="Postcode">
                <Text onChange={setPostcode} value={postcode} />
            </LabelInputPair>
            <LabelInputPair name="city" label="City">
                <Text onChange={setCity} value={city} />
            </LabelInputPair>
            <LabelInputPair name="phone" label="Phone">
                <Text onChange={setPhone} value={phone} />
            </LabelInputPair>
            <LabelInputPair name="email" label="Email">
                <Text onChange={setEmail} value={email} />
            </LabelInputPair>
            <LabelInputPair name="cvr" label="CVR">
                <Text onChange={setCvr} value={cvr} />
            </LabelInputPair>
        </div>
        <div className="lg:grid grid-cols-3 gap-4 mt-12 mb-6">
            <Link className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700" to={`view?companyName=${companyName}&domain=${domain}&address=${address}&postcode=${postcode}&city=${city}&phone=${phone}&email=${email}&cvr=${cvr}`} target="_blank">Download (Word)</Link>
        </div>
    </div>
    <div  className="bg-white px-6 py-4 rounded-xl shadow">
    <PrivacyPolicy companyName={companyName} domain={domain} address={address} postcode={postcode} city={city} phone={phone} email={email} cvr={cvr}/>
    </div>
        </>
}
const PrivacyPolicy = ({ companyName, domain, address, postcode, city, phone, email, cvr }) => {
    return <div className="privacy-policy"><h1>Cookie -og privatlivspolitik</h1>

        <div class="prose ">
            <p>Denne side vil beskrive hvordan {companyName} bruger den data der opsamles ved brug af vores website.</p>

        </div>

        <h2>Hvem er vi</h2>

        <div class="prose ">
            <p>{companyName}</p>
            <p>Vores webstedsadresse er: {domain}.</p>

        </div>

        <h2>Introduktion</h2>

        <div class="prose ">
            <p>Når du besøger vores website bliver der indsamlet oplysninger om dig, som bliver brugt til at tilpasse og forbedre vores indhold og til at skabe mere målrettet annoncering.</p>
            <p>Hvis du ikke ønsker, at der bliver indsamlet oplysninger om dig, bør du slette dine cookies og undlade videre brug af hjemmesiden. I de nedenstående afsnit har vi uddybet, hvad det er for informationer, der indsamles, deres formål og hvilke tredjeparter, der har adgang til dem.</p>

        </div>

        <h2>Cookies</h2>


        <div class="prose ">
            <p>Hjemmesiden anvender "cookies", som er en tekstfil, der gemmes på din computer, mobil el. tilsvarende med formålet at genkende den, huske indstillinger, udføre statistik og målrette annoncer. Cookies kan dog ikke indeholde skadelig kode som fx. virus.</p>
            <p>Det er muligt for dig at slette eller blokere for cookies. Det kan du gøre ved at følge denne vejledning: http://minecookies.org/cookiehandtering</p>
            <p>Hvis du følger vejledningen og sletter eller blokerer cookies, så vil de annoncer du ser kun blive mindre relevante for dig, mens de vil optræde hyppigere. Du kan samtidig også risikere, at hjemmesiden ikke fungerer optimalt, samt at der er indhold, som du ikke kan få adgang til.</p>

        </div>

        <h3>Websitet indeholder cookies fra tredjeparter, der i varierende omfang kan omfatte:</h3>
        <div class="prose ">
            <ul>
                <li>Google LLC</li>
                <li>Active Campaign</li>
                <li>LinkedIn</li>
                <li>Facebook</li>
            </ul>
            <p>Se eventuelt også denne liste over hyppige tredjeparter ved digital annoncering: <a href="http://danskemedier.dk/digital/frq3rdparties/">http://danskemedier.dk/digital/frq3rdparties/</a></p>

        </div>

        <h2>Personoplysninger</h2>

        <h3>Generelt</h3>


        <div class="prose ">
            <p>Personoplysninger er alle former for informationer, som i et eller andet omfang kan henføres til dig. Når du benytter vores website, så indsamler og behandler vi en række af sådanne informationer. Det sker f.eks. ved helt almindelige interaktioner på hjemmesiden som fx at du tilmelder dig vores nyhedsbrev, deltager i en konkurrence/undersøgelse, registrerer dig som ny bruger/abonnent eller ved øvrig brug af services eller køb via vores hjemmeside.</p>
            <p>Vi indsamler og behandler typisk følgende slags oplysninger: Et unikt ID og tekniske oplysninger om din computer, tablet eller mobiltelefon, dit IP-nummer, geografisk placering, samt hvilke sider du klikker på. I det omfang du selv giver eksplicit samtykke og selv indtaster informationerne, behandler vi desuden: Navn, telefonnummer, e-mail, virksomhed, stilling og adresse. Det sker typisk i forbindelse med, at du opretter et login eller ved bestilling af materiale på siden.</p>

        </div>

        <h3>Sikkerhed</h3>
        <div class="prose ">
            <p>Vi behandler dine personoplysninger sikkert og fortroligt i overensstemmelse med gældende lovgivning, herunder persondataforordningen og databeskyttelsesloven.</p>
            <p>Dine oplysninger vil alene blive anvendt til det formål, de er indsamlet til og vil blive slettet, når dette formål er opfyldt eller ikke længere er relevant.</p>
            <p>Vi har truffet tekniske og organisatoriske foranstaltninger mod, at dine oplysninger hændeligt eller ulovligt bliver slettet, offentliggjort, fortabt, forringet eller kommer til uvedkommendes kendskab, misbruges eller i øvrigt behandles i strid med lovgivningen.</p>

        </div>
        <h3>Formål</h3>


        <div class="prose ">
            <p>Oplysningerne bruges til at identificere dig som bruger og vise dig de annoncer, som vil have mest relevans for dig, samt at kunne levere dig de services, du har efterspurgt, som fx at modtage nyhedsbreve. Derudover anvender vi oplysningerne til at optimere og forbedre vores services og indhold.</p>
        </div>
        <h3>Periode for opbevaring</h3>


        <div class="prose ">
            <p>Vi opbevarer oplysninger i det tidsrum, som er tilladt i henhold til lovgivningen, og vi sletter dem, når de ikke længere er nødvendige eller relevante. Perioden afhænger af, hvilken karakter oplysningen er og baggrunden for opbevaring. Det er derfor ikke muligt at give en generel tidsramme for, hvornår informationer slettes.</p>

        </div>

        <h3>Videregivelse af oplysninger</h3>


        <div class="prose ">
            <p>Data om din brug af websitet, hvilke annoncer du modtager og evt. klikker på, geografisk placering, køn og alderssegment m.v. videregives til tredjeparter i det omfang oplysningerne er kendt, og såfremt vi benytter os af tredjeparter. Du kan se hvilke tredjeparter, der er tale om, i afsnittet om cookies ovenfor. Oplysningerne bliver anvendt til målrettet annoncering.</p>
            <p>Vi benytter derudover en række tredjeparter til opbevaring og behandling af data. Disse behandler udelukkende oplysninger på vegne af os og må ikke anvende dem til egne formål.</p>
            <p>Videregivelse af personoplysninger som navn og e-mail m.m. vil kun ske, hvis du selv giver samtykke til det. Vi anvender kun databehandlere i EU eller i lande, der kan give dine oplysninger en tilstrækkelig beskyttelse.</p>

        </div>

        <h3>Indsigt og klager</h3>


        <h2>Udgiver</h2>

        <h3>Websitet ejes og publiceres af:</h3>

        <div class="prose ">
            <p><strong>{companyName}</strong> <br />
                {address} <br />
                {postcode} {city} <br />
                Telefon: {phone} <br />
                Email: {email}</p>

        </div>


    </div>
}

export {PrivacyPolicyView};
export default PrivacyPolicyGenerator;