import {DataTable} from "../components/DataTable";
import { Link, useParams } from "react-router-dom";
import CustomerLayout from "../components/CustomerNavigation";

const ManageCustomerUsers = () => {
    
    return <CustomerLayout active="Users" actionButton={<Link to="create" className="inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset  h-9 px-4 text-white shadow focus:ring-white border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700">
    New user
</Link>}>
    <CustomerUsers/>
    </CustomerLayout>
    

}

const CustomerUsers = ()=>{
    let { id } = useParams();

    return <DataTable path={`customers/${id}/users`} children={[
<td key={1} row={(name)=><>{name}</>} field="name" header="Name" sortable/>
    ]} />


}

export default ManageCustomerUsers;