import {DataTable} from "../components/DataTable"



const Users = ()=>{
    return <DataTable path="users" searchable="name" filter={<></>} children={[
<td key={1} row={(name)=><>{name}</>} field="name" header="Name" sortable/>
    ]} />


}

export default Users;