import { useState, useEffect } from "react";
import axiosClient from "../axiosClient";

const GlobalSearch = () => {

    const [customers, setCustomers] = useState([]);
    const [items, setItems] = useState();
    const [error, setError] = useState();
    const [query, setQuery] = useState();
    const [mouseOver, setMouseOver] = useState(false);

    useEffect(() => {
        axiosClient({
            method: "get",
            url: `/admin/customers`
        })
            .then((response) => {
                setCustomers(response.data?.$values??response.data);
                setError(null);
            })
            .catch(setError);

    }, []);

    // useEffect((query) => {
    //     if(!query||query.trim()===""){
    //         setItems(null);
    //         return;
    //     }

    //     setItems(customers.filter(customer=>customer.name.toLowerCase().contains(query)));

    // }, [query]);
    const searchItems = (value)=>{
        if(value&&value.trim()!=""){
            value=value.toLowerCase();
        setItems(customers.filter(customer=>customer.name.toLowerCase().includes(value)));
        }else{
            setItems(null);
        }

        
    }
    

    return <div className="flex items-center">

        <div className="relative">
            <div>
                <label htmlFor="globalSearchQueryInput" className="sr-only">
                    Global search
                </label>

                <div className="relative group max-w-md">
                    <span className="absolute inset-y-0 left-0 flex items-center justify-center w-10 h-10 text-gray-500 pointer-events-none group-focus-within:text-primary-500">
                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>        </span>

                    <input onBlur={(e)=>{
                        if(!mouseOver){
                            searchItems("")
                        }
                    }} onFocus={(e)=>searchItems("")} onChange={(e) => searchItems(e.target.value)} id="globalSearchQueryInput" placeholder="Search" type="search" autoComplete="off" className="block w-full h-10 pl-10 lg:text-lg bg-gray-400/10 placeholder-gray-500 border-transparent transition duration-75 rounded-lg focus:bg-white focus:placeholder-gray-400 focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600" />
                </div>
            </div>
            {
                (items&&items.length>0) &&
                <div onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=>setMouseOver(false)} className="absolute right-0 rtl:right-auto rtl:left-0 top-auto z-10 mt-2 shadow-xl overflow-hidden rounded-xl w-screen max-w-xs sm:max-w-lg">
                    <div className="overflow-y-scroll overflow-x-hidden max-h-96 bg-white shadow rounded-xl">
                        <ul className="divide-y">
                            <li className="sticky top-0 z-10">
                                <header className="px-6 py-2 bg-gray-50/80 backdrop-blur-xl backdrop-saturate-150">
                                    <p className="text-xs font-bold tracking-wider text-gray-500 uppercase">
                                        customers
                                    </p>
                                </header>
                            </li>
                            {
                                items.map(item=>{
                                    return <li key={item.id}>
                                        <a href={`/admin/customers/${item.id}/edit`} className="relative block px-6 py-4 focus:bg-gray-500/5 hover:bg-gray-500/5 focus:ring-1 focus:ring-gray-300">
                                        <p className="font-medium">{item.name}</p>
                                        <p className="text-sm space-x-2 rtl:space-x-reverse font-medium text-gray-500"></p>
                                        </a>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            }
        </div>

    </div>
}
export default GlobalSearch;