import React from "react"

const DateFormat = (value)=>{
    if(!value){
        return <></>;
    }
    let date = new Date(value);
    if(date.getTime()<=0){
        return <></>;
    } 
    const dateOptions = {
        day: 'numeric',
        month:'long',
        year: 'numeric'
    }
    
    let dateString = date.toLocaleDateString('en-UK', dateOptions);
    
    return <>{dateString}</>
}

export default DateFormat;