import React, { useEffect, useState } from "react"
import { useNavigate,useParams, useLocation  } from "react-router-dom"
//import {   } from "react-router"
import axiosClient from "../axiosClient"
import { sendNotification } from "../components/Notification";

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
const PlatformConnectionCallback = () => {
    const {platform} = useParams();
    const query = useQuery();

    const state = query.get("state");
    const code = query.get("code");
    
    const navigate = useNavigate();

    useEffect(()=>{
        axiosClient({
            method: "get",
            url: `/admin/platformconnection/${platform}/callback?code=${code}&state=${state}`
        })
            .then((response) => {
                navigate("../platform-connection");
            })
            .catch((error)=>{
                console.log(error);
                sendNotification("danger", "Something went wrong");
                navigate("../platform-connection");

            });
    })

    return <svg className="w-6 h-6 mr-1 -ml-2 rtl:ml-1 rtl:-mr-2 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
    <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"></path>
</svg>;
}

const PlatformConnectionLogin = () => {
    const {platform} = useParams();
    const navigate = useNavigate();

    useEffect(()=>{
        axiosClient({
            method: "get",
            url: `/admin/platformconnection/${platform}/signin?rd=https://${window.location.host}/admin/platform-connection/${platform}/callback`
        })
            .then((response) => {
                window.location.assign(response.data.url);
            })
            .catch((error)=>{
                console.log(error);
                sendNotification("danger", "Something went wrong");
                navigate("../platform-connection");

            });
    })

    return <svg className="w-6 h-6 mr-1 -ml-2 rtl:ml-1 rtl:-mr-2 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
    <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"></path>
</svg>;

}

const PlatformConnection = ()=>{

    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axiosClient({
            method: "get",
            url: `/admin/platformconnection?redirect=https://${window.location.host}/admin/platform-connection`
        })
            .then((response) => {
                setItems(response.data.$values);
                setError(null);
            })
            .catch(setError);
        
    }, []);

    return <div>
        {items.map((platform, index)=>{
            return <Platform platform={platform.platform} key={index} icon={platform.user_info?.picture} name={platform.user_info?.name} expires={platform.token?.expires_in} token={platform.token?.access_token} update_url={platform.update_token_url}/>
        })}
    </div>
}


const Platform = ({ platform, icon, name, expires, token, update_url})=>{
    return <div className="bg-white px-6 py-4 mb-6 rounded-xl shadow">



    <div className="py-1 font-bold"><h4>{platform}</h4></div>

    

        

        <div className="p-3">
            <img style={{width: "80px"}} src={icon} className="rounded-full block mb-6" alt={name}/>

            &nbsp; <span>{name}</span>
        </div>

        <ul className="list-none">
            <li className="py-1">Token: <input className="block w-full transition duration-75 rounded-lg shadow-sm focus:border-primary-600 focus:ring-1 focus:ring-inset focus:ring-primary-600 disabled:opacity-70 border-gray-300 max-w-sm" type="text" readOnly="" defaultValue={token}/></li>
            <li className="py-1">Token Expires: {formatTimeStamp(expires)}</li>
        </ul>

    
    <div className="py-6 border-t border-gray-100 mt-6">
        <h5>Refresh Connection</h5>
        <div>
            <a className="px-3 py-2 hover:bg-primary-600 bg-primary-500 text-white mt-3 inline-block rounded-lg transition-colors shadow" href={`https://${window.location.host}/admin/platform-connection/${platform}/signin`} target="_blank"><i className="fab fa-linkedin"></i> Login to {platform}</a>
        </div>
    </div>


</div>
}

function formatTimeStamp(timestamp) {
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;

    timestamp = timestamp - (timestamp%minute);

    var minutes = timestamp%hour;
    var hours = (timestamp%day)-minutes;
    var days = timestamp - hours-minutes;

    minutes=minutes==0?0:minutes/minute;
    hours=hours==0?0:hours/hour;
    days=days==0?0:days/day;

    return `${days} days, ${hours} hours, ${minutes} minutes`

}

export {PlatformConnectionCallback, PlatformConnectionLogin}

export default PlatformConnection;