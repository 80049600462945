import { useEffect, useState } from "react";
import {Tab, TabLayout, GridLayout, EditBoolean, EditCard, EditColumn, EditFooter, EditGroup, EditLayout, EditList, EditSelect, EditTextField, EditCurrency, EditNumber, EditDate, EditTextArea, EditSelectNumberPair} from "../../components/Edit";
import axiosClient from "../../axiosClient";
import { sendNotification } from "../../components/Notification";
import { useNavigate } from "react-router-dom";
import { ServiceStatusEdit } from "../ServiceEditNew";

const DefaultServiceLayout = ({children,state,showCustomFields,typeName,customFieldMeta=[]})=>{
    const [service, setService] = state;
    const [error, setError] = useState();
    let navigate = useNavigate();

        const handleSubmit = (event) =>{

        var value = {
            
        };

        if(!event.value){
            return;
        }
        Object.entries(event.value).forEach(([key, entry])=>{
            if(key.startsWith("customFields.")){
                value.customFields??={};

                value.customFields[key.substring(13)]=entry;

            }
            else if(key==="payment_interval"||key==="discount_type"){
                value[key]=parseInt(entry);
            }
            else{
                value[key]=entry;

            }
        });

        if(value.customFields)  {
            let cfs = {

            }
            Object.entries(value.customFields).forEach(([key,entry])=>{
                cfs[key]=entry?.toString();
            });
            value.customFields=cfs;
        }
        
        axiosClient({
            method: service?.id?"PUT":"POST",
            url: `/admin/services${service?.id?'/'+service?.id:''}`,
            data: value
        })
            .then((response) => {
                setError(null);
                sendNotification("success", "Saved");
                switch(event.action){
                    case "another":
                        
                        
                        setService({});
                        window.location.reload(false);
                        return navigate(`../services/create`)
                        
                    default:
                        setService(response.data);
                        return navigate(`../services/${response.data.id}/edit`)
                }
                
            })
            .catch(setError);

    }

    return <EditLayout method={service?.id?"POST":"PUT"} onSubmit={handleSubmit} footer={<></>} shouldBlockNavigation>
        <EditColumn span="2">
        {children}
        <DefaultService state={state} showCustomFields={showCustomFields} typeName={typeName} customFieldMeta={customFieldMeta}/>
        </EditColumn>
        <EditColumn span="1">
            <ServiceStatusEdit serviceStatus={service?.status} serviceEndDate={service?.end_date} serviceStartDate={service?.start_date} messageBoxDisplay={({customerName,endDate})=><div>
                    
                    Please confirm that {customerName} <span className="font-bold">should not get an invoice</span> for {typeName} ({service?.name})  by the end of {new Date(Date.parse( endDate??new Date())).toLocaleString("en-UK", {year:"numeric",month:"long"})}.
                    </div>} />
       </EditColumn>
    </EditLayout>
}

const DefaultService = ({state, showCustomFields, typeName, customFieldMeta=[]})=>{
    const [service, setService] = state;
    const [id, setId] = useState();
    const [error, setError] = useState(null);
    const [currency,setCurrency] = useState("Dkk");

    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
        }
    }, [error]);
    useEffect(()=>{
        setId(service.id);
    }, [service])
    
    const updateCurrency=(value)=>{

        switch(value?.currency){

            case "usd":
                setCurrency("$");
                break;
                case "eur":
                setCurrency("€");
                break;
                case "gbp":
                setCurrency("£");
                break;
            default:
                setCurrency("DKK");
                break;
        }
    }
    const customField = (customFieldMeta) => {
        if(!customFieldMeta||!customFieldMeta.visible){
            return <></>;
        }
        let value = service?.customFields?.[customFieldMeta.name];
        let name = `customFields.${customFieldMeta.name}`

        if(customFieldMeta.is_readonly){
            if(!id){
                return <></>;
            }
            return <EditTextField ignoreValue value={value} label={customFieldMeta.title} name={name} disabled/>;
        }

        switch(customFieldMeta.type){
            case "INT":
                return <EditNumber value={value} label={customFieldMeta.title} name={name}/>
            case "STRING":
                return <EditTextField value={value} label={customFieldMeta.title} name={name}/>
            case "BOOL":
                return <EditBoolean value={value} label={customFieldMeta.title} name={name}/>
            default:
                return <EditTextField ignoreValue value={value} label={customFieldMeta.title} name={name} disabled/>
        }
    }
    return <> <EditGroup key="group-1" label="General">
    <EditCard columns="1">

        
        <GridLayout columns="1" columnsLg="2">
        <EditCurrency placeholder="0.00" onChange={updateCurrency}  key="text-price"  label="Price" amountName="price" amountValue={service?.price} currencyName="currency" currencyValue={service?.currency}/>
        <EditSelect key="select-billing-interval" value={service?.payment_interval} label="Billing interval" name="payment_interval" defaultValue="1">
            <option value="12">Yearly</option>
            <option value="3">Quarterly</option>
            <option value="1">Monthly</option>
        </EditSelect>
        <EditSelectNumberPair placeholder="0.00" key="select-discount" amountValue={service?.discount} amountName="discount" label="Discount" selectedName="discount_type" defaultSelected="0" selectedValue={service?.value}  >
            <option value="0">%</option>
            <option value="1">{currency}</option>
        </EditSelectNumberPair>
        <EditNumber value={service?.expenditure} name="expenditure" label="Expenditure" icon={currency}/>
        </GridLayout>
        <EditTextArea value={service?.description} name="description" label="Description"/>
        

    </EditCard>
</EditGroup>
{
    (showCustomFields&&customFieldMeta.length>0)&&<EditGroup label="Custom Fields">
    <EditCard columns="1">
        {
            customFieldMeta.map(customField)
        }
    </EditCard>
</EditGroup>
}</>;

}

export { DefaultServiceLayout};
export default DefaultService;