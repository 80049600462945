import { useEffect, useState } from "react";
import axiosClient from "../axiosClient";
import { EditNumber, EditSelect, EditTextField, EditColumn, EditGroup, EditCard, GridLayout, EditLayout, EditDate, EditBoolean, EditTextArea, EditFooter } from "../components/Edit";
import useAuth from "../useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { sendNotification } from "../components/Notification";
import { LabelInputPairRegular } from "../components/form/LabelInputPair";
import Files from "../components/form/Files";
import { EditDataLayout } from "./DataLayout";

const TAX = 3.79;

const TripEdit = ({})=> {
    const { id } = useParams();

   
    return <TripForm id={id}/>
}
const TripCreate = ({})=> {

   
    return <TripForm />
}

const TripForm = ({id})=>{
    const [trip, setTrip] = useState({round_trip:true, own_car:true, tax:TAX});
    const [error, setError] = useState();
    const [odoStart, setOdoStart] = useState(0);
    const [odoEnd, setOdoEnd] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [licensePlate, setLicensePlate] = useState("");
    const [auth,] = useAuth();
    const [selectedUser, setSelectedUser] = useState();
    const [userInfo, setUserInfo] = useState();
    let navigate = useNavigate(); 
    useEffect(()=>{
        if(error){
            sendNotification("danger", "Something went wrong");
        }
    }, [error])
    useEffect(()=>{
        if(id){
            axiosClient({
                method: "GET",
                url:`/admin/trips/${id}`,
            }).then(response=>{
                setError(null);

                setTrip(response.data);
                let licensePlate = response?.data?.license_plate;
                if(licensePlate){
                    setLicensePlate(licensePlate);
                }

                let user = response?.data?.user_id;
                if(user){
                    setSelectedUser(user);
                }

                setOdoStart(response?.data?.odo_start);
                setOdoEnd(response?.data?.odo_end);
            }).catch(setError);
        }
        axiosClient({
            method: "get",
            url: `/admin/customers`
        })
            .then((response) => {
                setError(null);
                
                setCustomers(response.data.sort((a, b)=>a.name>b.name?1:-1).map((customer)=>{
                    return {
                        name: customer.name,
                        id: customer.id
                    }
                }));
                
            })
            .catch(setError);
            axiosClient({
                method: "get",
                url: `/admin/employees`
            })
                .then((response) => {
                    setError(null);
                    
                    setEmployees(response.data.sort((a, b)=>a.name>b.name?1:-1).map((employee)=>{
                        return {
                            name: employee.name,
                            id: employee.id,
                            info: employee.info
                        }
                    }));
                    
                })
                .catch(setError);
        
    }, [id])
    const handleSubmit = (data)=>{
        console.log(data);
        if(id){
            data.value.id=parseInt(id);
        }
        axiosClient({
            method: id?"PUT":"POST",
            url: `/admin/trips`,
            data: data.value
        })
            .then((response) => {
                setError(null);
                sendNotification("success", "Saved");
                switch(data.action){
                    case "another":
                        
                        
                        setTrip({});
                        window.location.reload(false);
                        return navigate(`../trips/create`)
                        
                    default:
                        return navigate(`../trips/${response.data.id}/edit`)
                }
                
            })
            .catch(setError);

            return trip.id;
        }
        const handleDelete=(callback)=>{
            if(!id){
                return;
            }
    
            axiosClient({
                method: "DELETE",
                url: `/admin/trips/${id}`
            })
                .then((response) => {
                    setError(null);
                    setTrip(response.data);
                    callback();
                    
                })
                .catch(setError);

        }
    useEffect(()=>{
        if(!id&&!trip.license_plate){
            setOwnCar(true);
        }
    }, [employees])

    useEffect(()=>{
        if(!id&&!trip.user_id){
            setSelectedUser(auth?.id);
        }
    }, [auth])

    useEffect(()=>{
        if(selectedUser&&employees.length>0){
            let info = employees.find(e=>e.id==selectedUser)?.info;

        console.log([selectedUser,info]);
        setUserInfo(info);
        }
        if(trip.own_car){
            setOwnCar(true);
        }
    },[selectedUser,employees])
    const setOwnCar = (value)=>{
        if(value){
            var license_plate=employees.find(e=>e.id==selectedUser)?.info?.license_plate;
            setLicensePlate(license_plate);
        }
    }
    
    return <EditDataLayout  onDelete={handleDelete} name="Trip" title="Trips" active="trips" create={!id}>
        <EditLayout footer={<></>} create={!id} onSubmit={handleSubmit}>
        <EditColumn span="2">
            
            <EditGroup label="Trip">
                <EditCard columns="1">
                    {id&&<input type="hidden" id="data.id" name="id"/>}
                    <EditSelect search value={trip?.customer_id} label="Kunde" name="customer_id" placeholder="Select customer" >
                        {
                            customers.map((c,index)=><option key={index} value={c.id}>{c.name}</option>)
                        }
                    </EditSelect>
                    <EditDate value={trip?.date} name="date" label="Date"/>
                    
                    <GridLayout  columnsLg="2">
                        <EditTextField value={trip?.address_from??"Hasselager Centervej 21, 8260 Viby J"} name="address_from" label="Adress start" />
                        <EditTextField value={trip?.address_to} name="address_to" label="Address end" />

                        <EditNumber value={trip?.odo_start} onChange={setOdoStart} name="odo_start" label="Odometer start" icon="KM"/>
                        <EditNumber value={trip?.odo_end} onChange={setOdoEnd} name="odo_end" label="Odometer end" icon="KM"/>
                        <EditNumber value={trip?.expense} name="expense" label="Expense" icon="DKK"/>
                        <EditNumber disabled value={odoEnd-odoStart} name="distance" label="Distance" icon="KM"/>
                        <EditNumber disabled value={trip?.tax} name="tax" label="Tax" icon="DKK"/>
                        <EditNumber ignoreValue disabled value={(trip?.tax*(odoEnd-odoStart)).toFixed(2)} name="calculatedPrice" label="Price" icon="KM"/>
                    </GridLayout>
                    <EditBoolean value={trip?.round_trip} name="round_trip" label="Round trip"/>
                    
                    <EditTextArea value={trip?.description} name="description" label="Description" placeholder="Afregning statens takster jf. videoshoot - Hasselager Centervej 21, 8260 Viby J"/>
                    <LabelInputPairRegular label="Files">
                        <Files value={trip?.files} folder="1e8evWGrv86STo-BRhScJHMcDbDCGkNIg" namee="files" id="data.files"/>
                    </LabelInputPairRegular>
                </EditCard>
            </EditGroup>
            
            </EditColumn>
            <EditColumn>
            <EditGroup label="User">
                    <EditCard>
                    <div>
                    <EditSelect search value={selectedUser} label="Medarbejder" name="user_id" onChange={setSelectedUser}>
                        {
                            employees.map((c,index)=><option key={index} value={c.id}>{c.name}</option>)
                        }
                    </EditSelect>
                    {
                        !userInfo&&<span className="text-danger-600 text-sm">Information about user is missing!</span>
                    }
                    </div>
                    <EditTextField key="license" value={licensePlate} name="license_plate" label="License plate" />
                    <EditBoolean value={trip?.own_car} name="own_car" label="Own car" onChange={setOwnCar}/>

                    </EditCard>
            <EditFooter className="w-full items-stretch flex flex-col space-y-3 mt-6"/>

            </EditGroup>
            </EditColumn>
    </EditLayout>
    </EditDataLayout>
}

export {TripEdit, TripCreate};