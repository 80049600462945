import {DataTable} from "../components/DataTable"
import Filter from "../components/Filter";
import MultiSelect from "../components/form/MultiSelect";
import Download from "../components/svg/Download";
import useData from "../hooks/useData";

const Status = (status) => {
    if (!status) {
        return <></>
    }
    const className = 'inline-flex items-center justify-center h-6 xl:px-2 xl:py-0.5 w-6 xl:w-auto overflow-hidden whitespace-nowrap text-sm font-medium tracking-tight rounded-xl ';
    

    switch (status) {
        case 'Faktura':
            return <span className={`${className} text-success-700 bg-success-500/10`}><span className="hidden xl:inline">{status}</span><div className="xl:hidden bg-success-700 h-3 w-3 rounded-full"/></span>
      
        case 'Kreditnota':
            return <span className={`${className} text-danger-700 bg-danger-500/10`}><span className="hidden xl:inline">{status}</span><div className="xl:hidden bg-danger-700 h-3 w-3 rounded-full"/></span>;
    }


}
const DateFormat = (value)=>{
    if(!value){
        return <></>;
    }
    let date = new Date(value);
    if(date.getTime()<=0){
        return <></>;
    } 
    const dateOptions = {
        day: 'numeric',
        month:'long',
        year: 'numeric'
    }
    
    let dateString = date.toLocaleDateString('en-UK', dateOptions);
    
    return <>{dateString}</>
}
const StatusFilter = ({onChange, value})=>{
    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        <option value="Faktura">Faktura</option>
        <option value="Kreditnota">Kreditnota</option>
    </MultiSelect>
}
const AgreementFilter = ({onChange, value})=>{
    return <MultiSelect value={value} onChange={onChange} placeholder="All">
        <option value="Mindmill">Mindmill</option>
        <option value="FGT">FGT</option>
    </MultiSelect>
}
const MonthFilter = ({onChange, value})=>{
     const [months,] = useData([],"admin/economics/months")
    
    return <MultiSelect value={value} onChange={onChange} placeholder="All">
                                             {months.map((month, index)=>{
                                                 return <option key={index} value={month.monthYear}>{month.display}</option>
                                             })}
                                         </MultiSelect>
}
const InvoicesFilter = ({onChange}) => {
    
    return <Filter onChange={onChange} filters={[
        {
            name: "status",
            element: <StatusFilter/>,
            label: "Type",
            defaultValue: []
        },
        {
            name: "monthYear",
            element: <MonthFilter/>,
            label: "Month",
            defaultValue: []
        },
        {
            name: "agreement",
            element: <AgreementFilter/>,
            label: "Konto",
            defaultValue: ["Mindmill"]
        }
    ]}/>
}
const NumberFormat = (price)=>{
    let priceInt = parseInt(price);
    
    return <span className="block w-100 text-right">{(priceInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."))+" DKK"}</span>;


}

const Invoices = ({customer})=>{
    return <DataTable path={`economics/invoices${customer?"?customer="+customer:""}`} readonly searchable="client_name" filter={<InvoicesFilter/>}  >
        <td className="w-16" row={DateFormat} field="date" header="Date" sortable/>
        <td field="client_name" header="Kunde" />
        <td className=" block w-100 text-right" row={NumberFormat} field="netAmount" header="Price" sortable/>
        <td row={Status} field="status" header="Status" />
        <td field="agreement" header="Konto" />
        <td  row={(download)=><a target="_blank" href={download}><Download className="w-6 h-6 text-gray-700" /></a>} field="download" header="Download PDF"/>
    </DataTable>


}

export default Invoices;