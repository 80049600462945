import { useEffect, useState } from "react"
import axiosClient from "../axiosClient"
import { DataTable } from "../components/DataTable"
import Filter from "../components/Filter"
import MultiSelect from "../components/form/MultiSelect"
import PlatformStatus from "../components/PlatformStatus"
import Monitor  from '../components/svg/Monitor'

const StatusFilter = ({onChange, name, value})=>{
    return <MultiSelect onChange={onChange} name={name} value={value} placeholder="Status">
        <option value="Aktiv">Aktiv</option>
        <option value="Pause">Pause</option>
        <option value="Ophørt">Ophørt</option>
    </MultiSelect>
}
const WpStatusFilter = ({onChange, name, value})=>{
    return <MultiSelect onChange={onChange} name={name} value={value}>
        <option value="Active">Aktiv</option>
        <option value="Inactive">Inaktiv</option>
        <option value="Archived">Arkiveret</option>
        <option value="Deleted">Slettet</option>
        <option value="Hidden">Skjult</option>
    </MultiSelect>
}

const SitesFilter = ({onChange})=>{
    return <Filter onChange={onChange} filters={[
        {
            name: "status",
            element: <StatusFilter/>,
            defaultValue: [],
            label: "Status"
        },
        {
            name: "wordpress",
            element:<WpStatusFilter/>,
            defaultValue: [],
            label: "Wordpress Status"
        }
    ]}/>
}

const Status = (props) => {

    
    const colors = {
        'Active': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-success-700 bg-success-500/10`}>Aktiv</span>,
        'Hidden': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-gray-700 bg-gray-500/10`}>Skjult</span>,
        'Inactive': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-gray-700 bg-gray-500/10`}>Inaktiv</span>,
        'Archived': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-primary-700 bg-primary-500/10`}>Arkiveret</span>,
        'Deleted': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-danger-700 bg-danger-500/10`}>Slettet</span>

    }

    let color = colors[props.status];

    if(!color){
        color=colors["Inactive"];
    }
        
    
    return color;
}

const Sites = ({})=>{

    return <DataTable editPath="services" searchable="domain,customer" filter={<SitesFilter/>} path="sites">
        <td sortable field="domain" header="Domain" />
        {/* <td field="customer" header="Client" row={(customer)=><PlatformStatus active={customer}/>} /> */}
        <td field="customer" header="Client" row={(customer)=><>{customer}</>} />
        <td field="forge" header="Forge" row={(forge)=><PlatformStatus active={forge} />} />
        <td field="wordpress" header="Wordpress" row={(wordpress)=><Status status={wordpress}/>} />
        <td row={(domain)=><Monitor className="w-6 h-6 text-gray-700"/>} field="domain" header="Site" url={(domain)=>`https://${domain}/wp-admin`}/>
    </DataTable>
}

export default Sites;