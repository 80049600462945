import Filter from "./Filter"
import MultiSelect from "./form/MultiSelect"

const ServiceTypeFilter = ({onChange, name, value})=>{
    return <MultiSelect onChange={onChange} name={name} value={value} placeholder="Service">
        <option value="ActiveCampaign">ActiveCampaign</option>
        <option value="Hosting">Hosting</option>
        <option value="Framework">Framework</option>
        <option value="Zapier">Zapier</option>
        <option value="Sleeknote">Sleeknote</option>
        <option value="Dripify">Dripify</option>
        <option value="Calendly">Calendly</option>
        <option value="Mailchimp">Mailchimp</option>
        <option value="LeadInfo">LeadInfo</option>
    </MultiSelect>
}
const StatusFilter = ({onChange, name, value})=>{
    return <MultiSelect onChange={onChange} name={name} value={value} placeholder="Status">
        <option value="Aktiv">Aktiv</option>
        <option value="Pause">Pause</option>
        <option value="Ophørt">Ophørt</option>
    </MultiSelect>
}

const ServiceFilter = ({onChange, defaultStatus = ["Aktiv"]})=>{
    return <Filter onChange={onChange} filters={[
        {
            name: "service_type",
            element: <ServiceTypeFilter/>,
            defaultValue: [],
            label: "Service"
        },
        {
            name: "status",
            element: <StatusFilter/>,
            defaultValue: defaultStatus,
            label: "Status"
        }
    ]}/>
}

export default ServiceFilter;